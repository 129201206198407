import axios from '@util/axios'

/**
  name: `${firstName} ${lastName}`,
  email,
  phoneNumber,
  message,
  recaptcha: Google reCAPTCHA
 */
export const requestCallback = async (options) => {
  try {
    const {data} = await axios.post('/api/agency/requestCallback', options);
    return data?.status ?? false 
  } catch(err) {
    return false
  }
}

/**
  name: `${firstName} ${lastName}`,
  email,
  propertyAddress,
  phoneNumber
  recaptcha: Google reCAPTCHA
 */
export const requestValuation = async (options) => {
  try {
    const {data} = await axios.post('/api/agency/requestValuation', options);
    return data?.status ?? false
  } catch(err) {
    return false
  }
}