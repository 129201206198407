import React, { useState, useEffect, useMemo, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import CircularProgress from '@material-ui/core/CircularProgress';

import SelectFields from '@util/SelectFields';
import useFilterAndSort from '@hooks/useFilterAndSort';
import AppContext from '@contexts/AppContext';
import FilterSection from './FilterSection';
import SortSection from './SortSection';
import PropertyCard from '@components/properties/card/PropertyCard';
import InputButton from '@components/forms/InputButton';
import FormInput from '@components/forms/FormInput';
import Select from '@components/forms/Select';
import { getMyWatches } from '@services/product';
import { bidEndTimeSortFn } from '@hooks/usePropertySortFn'

export default function BidderDashboardWatchedProperties({ userData: { myWatchs } }) {
    const { agencyData: { agencyId } } = useContext(AppContext);
    const [watchedProperties, setWatchedProperties] = useState([]);
    const [finishedLoadingProperties, setFinishedLoadingProperties] = useState(false);

    const filterOptions = useMemo(() => ({
        filterValues: {
            town: '',
            minPrice: '-1',
            maxPrice: '-1',
            bedRooms: '0',
            bathRooms: '0'
        },
        filterFns: {
            town: (value, data) => !value || data.town === value,
            minPrice: (value, data) => !value || value === '-1' || data.bidPrice >= parseInt(value),
            maxPrice: (value, data) => !value || value === '-1' || data.bidPrice <= parseInt(value),
            bedRooms: (value, data) => data.bedRooms >= parseInt(value),
            bathRooms: (value, data) => data.bathRooms >= parseInt(value)
        }
    }), []);

    const sortOptions = useMemo(() => ({
        sortFields: [{
            field: 'bidEndTime',
            title: 'Ending Soonest'
        },
        {
            field: 'bidStartPrice',
            title: 'Bid Start Price'
        },
        {
            field: 'bedRooms',
            title: '# of Bedrooms'
        },
        {
            field: 'bathRooms',
            title: '# of Bathrooms'
        }],
        sortFns: { // If no sort func for a particular field, then assume it is just normal sorting.
            bidEndTime: bidEndTimeSortFn,
            bidStartPrice: (dir) => (prop1, prop2) => dir * (prop1.bidStartPrice - prop2.bidStartPrice),
            bedRooms: (dir) => (prop1, prop2) => dir * (prop1.bedRooms - prop2.bedRooms),
            bathRooms: (dir) => (prop1, prop2) => dir * (prop1.bathRooms - prop2.bathRooms)
        },
        initSortBy: {
            field: 'bidEndTime',
            dir: -1
        } // Initialize sort to most recent bid end time (closest to ending)
    }), []);

    const { modifiedProperties, menuMode, setMenuMode, filterValues, filtered, sortBy, sorted, sortFields, handleFilter, clearFilters, handleSort, clearSort } = useFilterAndSort({
        properties: watchedProperties,
        filterOptions,
        sortOptions
    });

    /**
     * Effect:
     * 
     * On mount, load all myWatchs properties.
     * Whenever myWatchs changes (i.e. 
     * - Removing from watchlist
     * - Adding to watchlist
     * ), update watchedProperties and modifiedProperties (if it currently exists as
     * a result of sorting and/or filtering) accordingly. This will in turn update the UI.
     * When we update, we make sure to only make API requests if a new property was added to the watchlist.
     * Otherwise, we simply update the list and that's it. 
     */
    useEffect(() => {
        if (!myWatchs?.length) {
            setWatchedProperties([]);
            setFinishedLoadingProperties(true);
            return
        }
        const fn = async () => {
            const myWatchsProducts = await getMyWatches(agencyId)
            setWatchedProperties(myWatchsProducts);
            setFinishedLoadingProperties(true);
        }
        fn()
    }, []);

    return (
        <>
            <header className="flex flex-col items-center sm:block mt-10 sm:mt-0">
                <h1 className="page-header">My Saved Properties</h1>
            </header>
            <div className="absolute top-8 right-1/2 sm:right-0 transform translate-x-1/2 sm:translate-x-0 h-full z-10">
                <div className={`filter-sort-bar sticky flex flex-row space-x-3 whitespace-no-wrap p-2 rounded-lg bg-white shadow-lg -mt-16 sm:mt-6 z-30`}>
                    <InputButton
                        type="button"
                        submitText="Filters"
                        imageTitle="filter"
                        imagePosition="left"
                        rounded="lg"
                        disabled={menuMode === 'filter'}
                        onClick={(e) => setMenuMode('filter')} />
                    <InputButton
                        type="button"
                        submitText="Sort By"
                        imageTitle="sort"
                        imagePosition="left"
                        rounded="lg"
                        disabled={menuMode === 'sort'}
                        onClick={(e) => setMenuMode('sort')} />
                </div>
            </div>
            {/* <div className={`fixed top-0  right-52 watched-properties-sm:right-51 sm:right-0 min-w-filter-sort md:min-w-0 
                transform translate-x-1/2 sm:translate-x-0 sm:mr-16 flex flex-row space-x-3 mt-24 sm:mt-48
                watched-properties-lg:space-y-0 watched-properties-lg:space-x-3 p-2 rounded-lg bg-white shadow-lg z-30`}> */}
            <CSSTransition
                in={menuMode === 'filter'}
                classNames="filter-section"
                timeout={200}
                unmountOnExit>
                <FilterSection filtered={filtered} filterValues={filterValues} handleFilter={handleFilter} handleClose={(e) => setMenuMode('')} handleClear={(e) => clearFilters()}>
                    {
                        ({ formData, errorMapping, handleChange }) => (
                            <>
                                <FormInput
                                    type="text"
                                    label="Town"
                                    inputName="town"
                                    value={formData.town}
                                    handleChange={handleChange} />
                                <div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-6">
                                    <Select
                                        label="Min Price"
                                        name="minPrice"
                                        options={SelectFields.price}
                                        value={formData.minPrice}
                                        prependOption="£"
                                        initOption={{
                                            name: "Any",
                                            value: "-1"
                                        }}
                                        onChange={handleChange} />
                                    <Select
                                        label="Max Price"
                                        name="maxPrice"
                                        options={SelectFields.price}
                                        value={formData.maxPrice}
                                        prependOption="£"
                                        initOption={{
                                            name: "Any",
                                            value: "-1"
                                        }}
                                        onChange={handleChange} />
                                </div>
                                <div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-6">
                                    <Select
                                        label="Bedrooms"
                                        name="bedRooms"
                                        options={SelectFields.beds}
                                        value={formData.bedRooms}
                                        onChange={handleChange} />
                                    <Select
                                        label="Bathrooms"
                                        name="bathRooms"
                                        options={SelectFields.baths}
                                        value={formData.bathRooms}
                                        onChange={handleChange} />
                                </div>
                            </>
                        )
                    }
                </FilterSection>
            </CSSTransition>
            <CSSTransition
                in={menuMode === 'sort'}
                classNames="filter-section"
                timeout={200}
                unmountOnExit>
                <SortSection sortFields={sortFields} sorted={sorted} sortBy={sortBy} handleSort={handleSort} handleClear={(e) => clearSort()} handleClose={() => setMenuMode('')} />
            </CSSTransition>
            {
                !finishedLoadingProperties ? (
                    <div className="mt-16 flex flex-col items-center flex-grow justify-center">
                        <CircularProgress size="5rem" color="secondary" />
                        <h2 className="font-primary font-bold text-3xl text-center mt-3">Loading watched properties...</h2>
                    </div>
                ) : (
                    !watchedProperties.length
                        ?
                        (
                            <div className="mt-16 flex flex-col items-center">
                                <span className="material-icons text-mega mb-3">visibility_off</span>
                                <h2 className="font-secondary font-black text-lg sm:text-2xl text-primary-agency text-center">You are currently not watching any properties.</h2>
                            </div>
                        )
                        :
                        (
                            <div className="watched-property-grid grid gap-4">
                                {
                                    modifiedProperties ? modifiedProperties.map((property) =>
                                        <PropertyCard
                                            key={property.productId}
                                            {...property}
                                            publishDate={new Date(property.publishDate).getTime()}
                                            bidEndTime={new Date(property.bidEndTime).getTime()} />
                                    ) : watchedProperties.map((property) =>
                                        <PropertyCard
                                            key={property.productId}
                                            {...property}
                                            publishDate={new Date(property.publishDate).getTime()}
                                            bidEndTime={new Date(property.bidEndTime).getTime()} />
                                    )
                                }
                            </div>
                        )
                )
            }
            <CSSTransition
                in={menuMode}
                classNames="backdrop"
                timeout={200}
                unmountOnExit>
                <div
                    className="block sm:hidden fixed top-0 right-0 left-0 bottom-0 z-10 bg-black bg-opacity-50"
                    aria-hidden="true"
                    onClick={(e) => setMenuMode('')} />
            </CSSTransition>

        </>
    )
}