import React, { useState, useEffect, useContext } from 'react';

import axios from '@util/axios';

import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

/**
 * Custom hook containing logic for watching properties. 
 * Contains loading state and whether or not a property is watched based on bidder data.
 * This hook should not be used if the user isn't logged in as a bidder.
 * @param {String} productId Id of the product in question 
 */
export default function useWatchProperty(productId) {
    const { agencyData: { agencyId }, addNotification } = useContext(AppContext);
    const { userType, userData, setUserData } = useContext(AuthContext);

    const [watched, setWatched] = useState(null);
    const [loadingWatch, setLoadingWatch] = useState(false);

    /*
        Effect:
        When a user's watched properties changes (either due to adding/removing to/from watchlist),
        make sure to update whether or not the current property is watched!
    */
    useEffect(() => {
        if (userData && userData.myWatchs && userData.myWatchs.find((val) => val === productId)) {
            setWatched(true);
        }
        else {
            setWatched(false);
        }   
    }, [userData, productId]);

    const handleWatch = async (e) => {
        e.preventDefault();
        setLoadingWatch(true);

        if (userType === 'bidder') {
            try {
                if (watched) {
                    const unwatchRes = await axios.post("/api/agency/product/unwatch", {
                        agencyId,
                        productId
                    });
                    
                    if (!unwatchRes.data.status) {
                        throw new Error(unwatchRes.data.message);
                    }
    
                    setLoadingWatch(false);
                    setUserData((prevUserData) => {
                        let newWatches = [...prevUserData.myWatchs];
    
                        newWatches.splice(newWatches.findIndex(watch => watch === productId), 1);
                        return { ...prevUserData, myWatchs: newWatches };
                    })
    
                }
                else {
                    console.log(productId);
                    const watchRes = await axios.post("/api/agency/product/watch", {
                        agencyId,
                        productId
                    })
    
                    if (!watchRes.data.status) {
                        throw new Error(watchRes.data.message);
                    }
    
                    setLoadingWatch(false);
                    setUserData((prevUserData) => {
                        let newWatches = [...prevUserData.myWatchs];
    
                        newWatches.push(productId);
                        return { ...prevUserData, myWatchs: newWatches };
                    });
                }
            }
            catch (err) {
                if (err instanceof Error) {
                    addNotification("Action could not be completed because of the following error: (Expand)", <p>{err.message}</p>)
                }
                else {
                    addNotification("Action could not be completed because of server error.");
                }
            }
        }

    }

    return { watched, handleWatch, loadingWatch };
}