import { parseISO } from 'date-fns'
import axios from '@util/axios'

export const getProductData = async (agencyId, productId) => {
  const { data } = await axios.post('/api/agency/product/data', {
    agencyId,
    productId,
  })
  return data?.data ?? null
}

export const getProductDataList = async (agencyId, productIdList) => {
  const promiseList = productIdList.map((pId) => getProductData(agencyId, pId))
  const listResult = await Promise.all(promiseList)
  return listResult
}

export const unwatchProduct = async (productId) => {
  try {
    await axios.post('/api/agency/product/unwatch', { productId })
  } catch (e) {
    // console.log(e.message)
  }
}

export const searchProducts = async (searchParams) => {
  const { agencyId, city, minPrice, maxPrice, bedRooms } = searchParams
  const res = await axios.post('/api/agency/product/list/filter', {
    agencyId,
    city,
    minPrice: parseInt(minPrice),
    maxPrice: parseInt(maxPrice),
    bedRooms: parseInt(bedRooms),
  })

  if (res.data.status) {
    return injectLiveState(res.data.data)
  }
  return null
}

export const getProductList = async (agencyId, amount) => {
  try {
    const {data} = await axios.post('/api/agency/product/list', { agencyId, amount })
    if (!data.status) {
      return []
    }
    const list = data?.data ?? []
    return injectLiveState(list)
  } catch (e) {
    return []
  }
}

export const getMyBids = async (agencyId) => {
  try {
    const {data} = await axios.post('/api/agency/product/dataBidProducts', { agencyId });
    const list = data?.data ?? []
    return [null, injectLiveState(list)] 
  } catch(e) {
    return ['loading failed', null]
  }
}

export const getMyWatches = async (agencyId) => {
  try {
    const {data} = await axios.post('/api/agency/product/dataWatchedProducts', { agencyId });
    const list = data?.data ?? []
    return injectLiveState(list)
  } catch(e) {
    return []
  }
}

/**
 * inject live state asa received data from api
 * 0: live
 * 1: pre-market
 * 2: ended
 */
function injectLiveState(searchResult) {
  const now = Date.now()
  return searchResult?.map((item) => {
    const endTimeParsed = item.bidEndTime && parseISO(item.bidEndTime)
    // pre-market
    if (!endTimeParsed) {
      return { ...item, liveState: 1 }
    }
    // ended
    if (now >= endTimeParsed) {
      return { ...item, endTimeParsed, liveState: 2 }
    }
    // if (now < endTimeParsed) // live
    return { ...item, endTimeParsed, liveState: 0 }
  })
}
