import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import InputButton from '@components/forms/InputButton';

export default function SortSection({ sortFields, sortBy, handleClose, handleSort, handleClear, sorted, rootClass }) {
    const sort = (field, dir) => { 
        handleClose();
        handleSort({ field, dir });
    }
    
    return (
        <div className={`fixed top-0 w-full right-0 sm:w-auto sm:right-0 sm:mr-16 mt-16 sm:mt-64 sm:rounded-lg bg-white sm:shadow-lg z-40 ${rootClass ?? ''}`}>
            <div className="sm:rounded-lg sm:rounded-b-none p-4 sm:p-6 sm:bg-primary-agency flex justify-between items-center text-black sm:text-white">
                <h2 className="inline-block sm:pb-0 border-b-4 sm:border-none border-primary-agency font-primary font-semibold text-3xl">Sort By</h2>
                <div
                className="flex items-center p-2 rounded-full hover:bg-table-light-blue sm:hover:bg-black sm:hover:bg-opacity-25 transition-colors duration-200 ease-in-out cursor-pointer"
                onClick={handleClose}>
                    <ClearIcon />
                </div>
            </div>
            <ul className="sm:rounded-b-lg sm:overflow-hidden">
            {
                sortFields.map(({ field, title }) => (
                    <li className={`flex justify-between items-center space-x-12 ${sortBy && sortBy.field === field ? 'bg-mid-gray bg-opacity-50' : 'hover:bg-mid-gray hover:bg-opacity-50'} py-3 px-6 font-primary`}>
                        <span className="cursor-default">{title}</span>
                        <div className="flex items-center space-x-2">
                            <div 
                            className={`flex justify-center items-center p-1 rounded-full 
                            ${!(sortBy && sortBy.field === field && sortBy.dir === 1) ? 'hover:bg-hover-light-primary bg-opacity-25 cursor-pointer' : ''} 
                            transition-colors duration-200 ease-in-out`}
                            onClick={(e) => sort(field, 1)}>
                                <ArrowDropUpIcon className={`${sortBy && sortBy.field === field && sortBy.dir === 1 ? 'text-primary' : ''}`} />
                            </div>
                            <span className="cursor-default">/</span>
                            <div 
                            className={`flex justify-center items-center p-1 rounded-full 
                            ${!(sortBy && sortBy.field === field && sortBy.dir === -1) ? 'hover:bg-hover-light-primary bg-opacity-25 cursor-pointer' : ''} 
                            transition-colors duration-200 ease-in-out`}
                            onClick={(e) => sort(field, -1)}>
                                <ArrowDropDownIcon className={`${sortBy && sortBy.field === field && sortBy.dir === -1 ? 'text-primary' : ''}`} />
                            </div>
                        </div>
                    </li>
                ))
            }
            </ul>
            <InputButton 
            type="button"
            submitText="Clear Sort" 
            imageTitle="clear"
            imagePosition="left" 
            rootClass="mx-auto my-5"
            onClick={handleClear}
            disabled={!sorted} />
        </div>

    )
}