import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export default function Select({ label, name, value, nameValueEq, options, labelClass, rootClass, error, initOption, prependOption, ...rest }) {
    const [selected, setSelected] = useState(false);
    
    return (
        <div className={`w-full text-black ${rootClass ? rootClass : ''}`}>
            {label && 
            <label 
            className={`form-input-label ${labelClass ? labelClass : ''}`}
            htmlFor={name}>{label}</label>}

            <div className={`relative rounded-md bg-white`}>
                <select 
                value={value}
                className={`outline-none appearance-none rounded-md form-input bg-white ${error && 'form-input-error'}`}
                name={name} 
                id={name} 
                onClick={(e) => setSelected(!selected)} 
                onBlur={(e) => setSelected(false)}
                {...rest}>
                {initOption && <option value={initOption.value}>{initOption.name}</option>}
                {
                    options.map((option) => {
                        return <option key={nameValueEq ? option : option.name} value={nameValueEq ? option : option.value}>{prependOption ? prependOption : ''}{nameValueEq ? option : option.name}</option>
                    })
                }
                </select>
                <span className="transform top-1/2 -translate-y-1/2 absolute flex items-center right-0 mr-1 pointer-events-none bg-white">
                {
                    selected 
                    ?
                    (
                        <KeyboardArrowUpIcon />
                    )
                    :
                    (
                        <KeyboardArrowDownIcon />
                    )
                }
                </span>
                
            </div>
            <div className="relative">
                {error && <span className="form-error-text absolute mt-1">{error}</span>}
            </div>

        </div>
    )
}