import React, { useState, useRef, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid'; // v4 is the go-to random uuid

import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

const useBidSocket = () => {
    const { userType } = useContext(AuthContext);
    
    const { addNotification } = useContext(AppContext);
    const [outbid, setOutbid] = useState(false); // Local state for whether the bidder has been outbid. This is used when bidder is on page.
    const liveBidUuidRef = useRef('');
    const outbidRef = useRef(false);

    const generateUuid = () => {
        console.log('generating uuid!');
        let uuid = uuidv4();
        
        liveBidUuidRef.current = uuid;
        return uuid;
    }

    useEffect(() => {
        outbidRef.current = outbid;
    }, [outbid]);

    useEffect(() => {
        liveBidUuidRef.current = '';
    }, [userType]);

    const handleNewPrice = ({ price, productId, bidUuid, currentTime, bidEndTime, extendedCount, reserveStatus }, bidsMaxPrice, generalHandler) => {
        // Checks for out-bid. 
        // console.log(liveBidUuidRef.current)
        // console.log(currentTime);
        // console.log(bidEndTime);
        console.log(liveBidUuidRef.current);

        if (liveBidUuidRef.current) {
            if (bidUuid !== liveBidUuidRef.current) {
                if (userType === 'bidder' && bidsMaxPrice[productId] && !outbidRef.current) { // If bidder is bidding on this item, AND they haven't already been outbid, then they are outbid.
                    setOutbid(true);
                    addNotification("You've been outbid on this property!", 
                    <div className="flex flex-col sm:flex-row items-center sm:items-start justify-center space-y-5 sm:space-y-0 sm:space-x-12 sm:space-x-20">
                        <div className="pb-5 sm:pb-0 sm:pr-12 border-b-4 sm:border-b-0 sm:border-r-4 border-white">
                            <h1 className="font-primary font-bold text-2xl sm:text-3xl">Your Bid</h1>
                            <p className="font-primary font-semibold text-lg sm:text-2xl">£${bidsMaxPrice[productId]}</p>
                        </div>
                        <div>
                            <h1 className="font-primary font-bold text-2xl sm:text-3xl">Their Bid</h1>
                            <p className="font-primary font-semibold text-lg sm:text-2xl">£${price}</p>
                        </div>
                    </div>);
                }
                else {
                    addNotification(`New price received for this property: £ ${price}`);
                }
            }
            else {
                addNotification("Bid successful. You are now the highest bidder!");
            }
        }
        else {
            addNotification(`New price received for this property: £ ${price}`);
        }

        if (extendedCount) {
            addNotification('Bid has been extended to 5 minutes because of a recent bid.');
        }




        if (generalHandler) {
            generalHandler();
        }
    };

    return { handleNewPrice, generateUuid, outbid, setOutbid };
}


export default useBidSocket;