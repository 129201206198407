import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    overrides: {
        MuiSvgIcon: {
            // fontSizeLarge: {
            //     fontSize: "3rem"
            // },
            // root: {
            //     transition: "transform 0.25s ease-in-out"
            // },
            colorError: {
                color: "#FF5A60"
            },
            colorDisabled: {
                color: "#A8BEDD"
            },
            colorSecondary: {
                color: "white"
            }
        },
        MuiTabs: {
            indicator: {
                backgroundColor: "var(--color-primary-agency)"
            }
        },
        MuiTab: {
            root: {
                outline: "none"
            }
        },
        MuiStepper: {
            root: {
                backgroundColor: "#FFFFFF",
            }
        },
        MuiStepConnector: {
            vertical: {
                padding: "8px 0"
            }
        },
        MuiStepLabel: {
            label: {
                fontFamily: "Inter, serif",
                fontSize: "1.25rem",
            },
            active: {
                fontWeight: "bold"
            }
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: "white"
            },
            colorSecondary: {
                color: "var(--color-primary-agency)"
            }
        },
        MuiInputBase: {
            root: {
                fontFamily: "Source Serif Pro, sans-serif"
            }
        },
        MuiAccordion: {
            root: {
                backgroundColor: "var(--color-primary-agency)"
            }
        },
        MuiAccordionDetails: {
            root: {
                backgroundColor: "white"
            }
        },
        MuiSlider: {
            root: {
                width: "50%"
            },
            colorPrimary: {
                color: "#406BA6"
            }
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: "#406BA6",
                fontSize: "0.75rem"
            }
        }
    }
});

export default theme;