import { useState, useEffect, useCallback } from 'react';

/**
 * 
 * @param {Array} properties - Array of properties
 * @var modifiedProperties - Array of properties that were modified by filter / sort
 * @var menuMode - Represents which menu is currently showing (i.e. '' for none, 'filter', and 'sort') (only one menu showing at a time)
 * @var filters - Represents which filters are being applied
 * @var sortBy - Represents which sort is being applied (only supports sorting by one column) 
 */
export default function useFilterAndSort({ properties, filterOptions, sortOptions }) {
    const [modifiedProperties, setModifiedProperties] = useState(null);
    const [menuMode, setMenuMode] = useState('');
    const [filterFns, _setFilterFns] = useState(filterOptions?.filterFns);
    const [filterValues, setFilterValues] = useState(filterOptions?.filterValues);
    const [filtered, setFiltered] = useState(false);
    const [sorted, setSorted] = useState(!!sortOptions.initSortBy); // Shorthand for getting truthy/falsy value
    const [sortBy, setSortBy] = useState(sortOptions.initSortBy); 
    const [sortFns, _setSortFns] = useState(sortOptions.sortFns);
    const [sortFields, _setSortFields] = useState(sortOptions.sortFields);

    const filter = (properties, filterValues) => {
        return properties.filter((property) => (
            !Object.keys(filterValues).map((prop) => filterFns[prop](filterValues[prop], property)).some((bool) => !bool)
        ));
    }

    const sort = (properties, { field, dir }) => {
        return [...properties].sort(sortFns[field] ? sortFns[field](dir) : undefined);
    }

    const handleFilter = useCallback((filterValues) => {
        setMenuMode('');
        if (sorted || properties.length) {
            setFiltered(true);
            setFilterValues(filterValues);
        }
        else {
            return ;
        }

        if (sorted) {
            setModifiedProperties(filter(sort(properties, { ...sortBy }), filterValues));
        }
        else if (properties.length) {
            setModifiedProperties(filter(properties, filterValues));
        }

    }, [properties, modifiedProperties, filterFns, filtered, sorted]);

    const clearFilters = useCallback(() => {
        if (sorted) {
            setModifiedProperties(sort(properties, { ...sortBy }));
        }
        else {
            setModifiedProperties(null);
        }
        
        setFilterValues(filterOptions.filterValues); // Set to default filter values
        setFiltered(false);
        setMenuMode('');
    }, [properties, sortBy, sorted]);

    const handleSort = useCallback(({ field, dir }) => { // Field is the name of the field to sort. Dir is sort direction (-1 for descending, 1 for ascending)
        setMenuMode('');
        if (filtered || properties.length) {
            setSortBy({ field, dir });
            setSorted(true);
        }
        else {
            return ;
        }

        if (filtered) {
            setModifiedProperties(filter(sort(properties, { field, dir }), filterValues)); 
        }
        else if (properties.length) {
            setModifiedProperties(sort(properties, { field, dir }));
        }

    }, [properties, modifiedProperties, filterValues, filtered]);

    const clearSort = useCallback(() => {
        if (filtered) {
            setModifiedProperties(properties.filter((property) => (
                !Object.keys(filterValues).map((prop) => filterFns[prop](filterValues[prop], property)).some((bool) => !bool)
            )));
        }
        else {
            setModifiedProperties(null);
        }

        setSortBy(null);
        setSorted(false);
        setMenuMode('');
    }, [properties, filterFns, filterValues]);

    // Handles initial sort. 
    /**
     * sortOptions - Required to get initSortBy
     * properties - Required b/c properties is not retrieved until AFTER the first mount
     */
    useEffect(() => {
        if (sortOptions && properties.length) {
            setModifiedProperties(sort(properties, sortOptions.initSortBy));
        }
        else {
            setModifiedProperties(null);
        }
    }, [sortOptions, properties]);

    return { modifiedProperties, menuMode, setMenuMode, filterFns, filterValues, filtered, sortBy, sorted, sortFields, handleFilter, clearFilters, handleSort, clearSort };
}