import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import ValuationModal from '@components/ValuationModal';
import CallbackModal from '@components/CallbackModal';
import Footer from '@components/Footer';
import { API_DOMAIN_ASSETS } from '@util/site-constants';

export default function SellingPage() {
    const [valuationOpen, setValuationOpen] = useState(false);
    const [callbackOpen, setCallbackOpen] = useState(false);

    return (
        <>
            <div className="relative 870:pb-[52.04%] 870:h-0">
                <header className="selling-c-hero">
                    <h1 className="font-bold text-3xl sm:text-5xl 870:text-white">
                        Sell with confidence
                        <br />
                        and for free
                    </h1>
                    <p className="max-w-[550px] font-semibold text-xl 870:text-white mt-4">We make it simple, safe, and free to sell your home at auction and move forward.</p>
                    <div className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5 mt-8">
                        <button className="px-8 py-3 bg-secondary-agency text-white font-semibold rounded-full" onClick={() => setCallbackOpen(true)}>Sell my property</button>
                        <button onClick={() => setValuationOpen(true)} className="px-8 py-3 bg-gray-300 870:bg-white text-gray-900 font-bold rounded-full">Get a valuation</button>
                    </div>
                    <CallbackModal open={callbackOpen} handleModalClose={() => setCallbackOpen(false)} />
                    <ValuationModal open={valuationOpen} handleModalClose={() => setValuationOpen(false)} />
                </header>
            </div>

            <main className="my-20">
                <section className="mx-4">
                    <header className="text-center">
                        <h2 className="font-bold text-3xl">See your <span className="text-primary-agency">exclusive</span> benefits</h2>
                        <p className="mt-4 font-semibold">It couldn't be easier to sell property at auction.</p>
                    </header>
                    <ul className="buying-c-grid max-w-lg-container mx-auto">
                        <li className="buying-c-grid-item">
                            <svg className="transform -translate-x-2" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                viewBox="0 0 480.096 480.096" fill="var(--color-primary-agency)">
                                <path d="M370.032,178.808h-107.08L292.744,0l-182.68,258.808h108.704l-29.504,221.288L370.032,178.808z M140.944,242.808
                                    l124.512-176.4l-21.4,128.4h97.72L215.352,405.52l21.688-162.712H140.944z"/>
                            </svg>
                            <h3 className="buying-c-grid-item__heading">Sell faster</h3>
                            <p className="buying-c-grid-item__description">Speed up your sale with fixed 56-day completion timescales, compared to 100+ days for a Private Treaty sale.</p>
                        </li>
                        <li className="buying-c-grid-item">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="50" height="50" fill="var(--color-primary-agency)">
                                <path d="M57,8H52V6a4,4,0,0,0-8,0V8H36V6a4,4,0,0,0-8,0V8H20V6a4,4,0,0,0-8,0V8H7a5,5,0,0,0-5,5V53a5,5,0,0,0,5,5H35a1,1,0,0,0,0-2H7a3.009,3.009,0,0,1-3-3V22H60V39a1,1,0,0,0,2,0V13A5,5,0,0,0,57,8ZM46,6a2,2,0,0,1,4,0v6a2,2,0,0,1-4,0ZM30,6a2,2,0,0,1,4,0v6a2,2,0,0,1-4,0ZM14,6a2,2,0,0,1,4,0v6a2,2,0,0,1-4,0ZM60,20H4V13a3.009,3.009,0,0,1,3-3h5v2a4,4,0,0,0,8,0V10h8v2a4,4,0,0,0,8,0V10h8v2a4,4,0,0,0,8,0V10h5a3.009,3.009,0,0,1,3,3Z" />
                                <path d="M30,29a2,2,0,0,0-2-2H24a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2Zm-6,3V29h4v3Z" />
                                <path d="M18,29a2,2,0,0,0-2-2H12a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2Zm-6,3V29h4v3Z" />
                                <path d="M52,34a2,2,0,0,0,2-2V29a2,2,0,0,0-2-2H48a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2Zm-4-5h4v3H48Z" />
                                <path d="M30,38a2,2,0,0,0-2-2H24a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2Zm-6,3V38h4v3Z" />
                                <path d="M18,38a2,2,0,0,0-2-2H12a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2Zm-6,3V38h4v3Z" />
                                <path d="M28,45H24a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V47A2,2,0,0,0,28,45Zm-4,5V47h4v3Z" />
                                <path d="M36,34h4a2,2,0,0,0,2-2V29a2,2,0,0,0-2-2H36a2,2,0,0,0-2,2v3A2,2,0,0,0,36,34Zm0-5h4v3H36Z" />
                                <path d="M34,41a2,2,0,0,0,2,2,1,1,0,0,0,0-2V38h4a1,1,0,0,0,0-2H36a2,2,0,0,0-2,2Z" />
                                <path d="M16,45H12a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V47A2,2,0,0,0,16,45Zm-4,5V47h4v3Z" />
                                <path d="M49,36A13,13,0,1,0,62,49,13.015,13.015,0,0,0,49,36Zm0,24A11,11,0,1,1,60,49,11.013,11.013,0,0,1,49,60Z" />
                                <path d="M54.778,44.808,47,52.586,43.465,49.05a1,1,0,0,0-1.414,1.414l4.242,4.243a1,1,0,0,0,1.414,0l8.485-8.485a1,1,0,0,0-1.414-1.414Z" />
                            </svg>
                            <h3 className="buying-c-grid-item__heading">Move on your schedule</h3>
                            <p className="buying-c-grid-item__description">Begin your auction on any day with flexible durations. With the 56-day, you'll know your moving date from the outset.</p>
                        </li>
                        <li className="buying-c-grid-item">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                viewBox="0 0 490.667 490.667" fill="var(--color-primary-agency)">
                                <path d="M138.667,192H96c-5.888,0-10.667,4.779-10.667,10.667V288c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.667-10.667
                                    v-74.667h32c5.888,0,10.667-4.779,10.667-10.667S144.555,192,138.667,192z"/>
                                <path d="M117.333,234.667H96c-5.888,0-10.667,4.779-10.667,10.667S90.112,256,96,256h21.333c5.888,0,10.667-4.779,10.667-10.667
                                    S123.221,234.667,117.333,234.667z"/>
                                <path d="M245.333,0C110.059,0,0,110.059,0,245.333s110.059,245.333,245.333,245.333s245.333-110.059,245.333-245.333
                                    S380.608,0,245.333,0z M245.333,469.333c-123.52,0-224-100.48-224-224s100.48-224,224-224s224,100.48,224,224
                                    S368.853,469.333,245.333,469.333z"/>
                                <path d="M386.752,131.989C352.085,88.789,300.544,64,245.333,64s-106.752,24.789-141.419,67.989
                                    c-3.691,4.587-2.965,11.307,1.643,14.997c4.587,3.691,11.307,2.965,14.976-1.643c30.613-38.144,76.096-60.011,124.8-60.011
                                    s94.187,21.867,124.779,60.011c2.112,2.624,5.205,3.989,8.32,3.989c2.368,0,4.715-0.768,6.677-2.347
                                    C389.717,143.296,390.443,136.576,386.752,131.989z"/>
                                <path d="M376.405,354.923c-4.224-4.032-11.008-3.861-15.061,0.405c-30.613,32.235-71.808,50.005-116.011,50.005
                                    s-85.397-17.771-115.989-50.005c-4.032-4.309-10.816-4.437-15.061-0.405c-4.309,4.053-4.459,10.816-0.405,15.083
                                    c34.667,36.544,81.344,56.661,131.456,56.661s96.789-20.117,131.477-56.661C380.864,365.739,380.693,358.976,376.405,354.923z"/>
                                <path d="M206.805,255.723c15.701-2.027,27.861-15.488,27.861-31.723c0-17.643-14.357-32-32-32h-21.333
                                    c-5.888,0-10.667,4.779-10.667,10.667v42.581c0,0.043,0,0.107,0,0.149V288c0,5.888,4.779,10.667,10.667,10.667
                                    S192,293.888,192,288v-16.917l24.448,24.469c2.091,2.069,4.821,3.115,7.552,3.115c2.731,0,5.461-1.045,7.531-3.136
                                    c4.16-4.16,4.16-10.923,0-15.083L206.805,255.723z M192,234.667v-21.333h10.667c5.867,0,10.667,4.779,10.667,10.667
                                    s-4.8,10.667-10.667,10.667H192z"/>
                                <path d="M309.333,277.333h-32v-64h32c5.888,0,10.667-4.779,10.667-10.667S315.221,192,309.333,192h-42.667
                                    c-5.888,0-10.667,4.779-10.667,10.667V288c0,5.888,4.779,10.667,10.667,10.667h42.667c5.888,0,10.667-4.779,10.667-10.667
                                    S315.221,277.333,309.333,277.333z"/>
                                <path d="M288,234.667h-21.333c-5.888,0-10.667,4.779-10.667,10.667S260.779,256,266.667,256H288
                                    c5.888,0,10.667-4.779,10.667-10.667S293.888,234.667,288,234.667z"/>
                                <path d="M394.667,277.333h-32v-64h32c5.888,0,10.667-4.779,10.667-10.667S400.555,192,394.667,192H352
                                    c-5.888,0-10.667,4.779-10.667,10.667V288c0,5.888,4.779,10.667,10.667,10.667h42.667c5.888,0,10.667-4.779,10.667-10.667
                                    S400.555,277.333,394.667,277.333z"/>
                                <path d="M373.333,234.667H352c-5.888,0-10.667,4.779-10.667,10.667S346.112,256,352,256h21.333
                                    c5.888,0,10.667-4.779,10.667-10.667S379.221,234.667,373.333,234.667z"/>
                            </svg>
                            <h3 className="buying-c-grid-item__heading">No Selling Fee</h3>
                            <p className="buying-c-grid-item__description">Save on fees by selling for absolutely free. This means you get to keep the full selling price.</p>
                        </li>
                        <li className="buying-c-grid-item">
                            <svg width="50" height="50" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="var(--color-primary-agency)">
                                <path d="m416 326c5.519531 0 10-4.480469 10-10s-4.480469-10-10-10-10 4.480469-10 10 4.480469 10 10 10zm0 0" />
                                <path d="m502 492h-16v-276c0-5.523438-4.476562-10-10-10h-60c-5.523438 0-10 4.476562-10 10v60c0 5.523438 4.476562 10 10 10s10-4.476562 10-10v-50h40v266h-40v-136c0-5.523438-4.476562-10-10-10s-10 4.476562-10 10v136h-40v-196c0-5.523438-4.476562-10-10-10h-60c-5.523438 0-10 4.476562-10 10v196h-40v-136c0-5.523438-4.476562-10-10-10h-60c-5.523438 0-10 4.476562-10 10v136h-40v-96c0-5.523438-4.476562-10-10-10h-60c-5.523438 0-10 4.476562-10 10v96h-26v-482c0-5.523438-4.476562-10-10-10s-10 4.476562-10 10v492c0 5.523438 4.476562 10 10 10h492c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10zm-396 0h-40v-86h40zm120 0h-40v-126h40zm120 0h-40v-186h40zm0 0" />
                                <path d="m93.925781 315.785156c-5.402343 1.148438-8.851562 6.453125-7.707031 11.859375 1.140625 5.390625 6.441406 8.855469 11.855469 7.707031 129.671875-27.480468 238.992187-105.714843 307.96875-210.996093l16.589843 11.617187c6.082032 4.253906 14.539063.625 15.628907-6.726562l9.839843-66.351563c.554688-3.746093-1.050781-7.484375-4.15625-9.65625-3.101562-2.171875-7.164062-2.40625-10.496093-.601562l-58.988281 31.9375c-6.527344 3.53125-7.0625 12.726562-.976563 16.988281l16.164063 11.316406c-67.789063 103.601563-175.046876 177.335938-295.722657 202.90625zm331.457031-236.039062-4.53125 30.5625-22.636718-15.851563zm0 0" />
                            </svg>
                            <h3 className="buying-c-grid-item__heading">Competitive Bidding</h3>
                            <p className="buying-c-grid-item__description">Achieve the best price with our extensive marketing, combined with competitive and transparent bidding.</p>
                        </li>
                        <li className="buying-c-grid-item">
                            <svg width="50" height="50" viewBox="0 -24 494.00008 494" xmlns="http://www.w3.org/2000/svg" fill="var(--color-primary-agency)">
                                <path d="m441.90625 0h-319.4375c-27.722656 0-50.277344 22.34375-50.277344 49.71875l-2.058594 281.28125h-62.339843c-4.417969 0-7.792969 3.90625-7.792969 8.324219v38.710937c0 36.238282 29 65.875 64 67.5v.464844h269v-.523438c2 .082032 3.210938.523438 4.585938.523438h.191406c37.328125-.21875 67.417968-30.644531 67.222656-67.972656v-246.027344h81.207031c4.417969 0 7.792969-4 7.792969-8.414062v-71.027344c.050781-28.867188-23.226562-52.351563-52.09375-52.558594zm-374.40625 430h-.054688c-28.457031 0-51.445312-23.519531-51.445312-51.972656v-31.027344h254v30.894531c-.0625 18.007813 7.050781 35.296875 19.765625 48.046875 1.351563 1.359375 2.765625 2.058594 4.210937 4.058594zm321.5-51.980469c0 28.460938-22.765625 51.980469-51.234375 51.980469h-.164063c-28.609374-.207031-51.667968-23.492188-51.601562-52.101562v-38.574219c.023438-4.480469-3.519531-8.167969-8-8.324219h-191.867188l2.058594-281.226562c0-18.605469 15.375-33.773438 34.277344-33.773438h281.667969c-9.117188 10-15.136719 22.652344-15.136719 36.773438zm89-325.320312v63.300781h-73v-63.242188c0-20.023437 16.65625-36.757812 36.671875-36.757812h.222656c20.066407.199219 36.199219 16.570312 36.105469 36.640625zm0 0" />
                                <path d="m187.855469 196.914062 41.246093 28.199219c2.691407 1.855469 6.25 1.855469 8.941407 0l41.121093-28.191406c14.285157-9.746094 24.835938-30.121094 24.835938-47.398437v-57.054688c.085938-3.015625-1.578125-5.8125-4.265625-7.183594l-62.800781-30.890625c-2.203125-1.09375-4.789063-1.09375-6.992188 0l-62.683594 30.804688c-2.6875 1.371093-4.347656 4.167969-4.257812 7.183593v57.140626c0 17.277343 10.574219 37.652343 24.855469 47.390624zm-8.855469-99.539062 54.5-26.890625 54.5 26.964844v52.074219c0 12.050781-7.898438 27.378906-17.863281 34.171874l-36.730469 25.121094-36.585938-25.117187c-9.964843-6.800781-17.820312-22.125-17.820312-34.175781zm0 0" />
                                <path d="m219.96875 161.601562c1.492188 1.523438 3.53125 2.386719 5.664062 2.398438h.019532c2.125-.007812 4.160156-.859375 5.65625-2.367188l31.824218-31.835937c3.128907-3.125 3.128907-8.195313.003907-11.320313-3.121094-3.128906-8.191407-3.128906-11.316407-.003906l-26.140624 26.136719-10.449219-10.550781c-3.113281-3.140625-8.179688-3.164063-11.320313-.050782-3.140625 3.113282-3.160156 8.179688-.050781 11.320313zm0 0" />
                                <path d="m333 248h-192c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h192c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm0 0" />
                                <path d="m333 285h-192c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h192c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm0 0" />
                            </svg>
                            <h3 className="buying-c-grid-item__heading">Transaction Security</h3>
                            <p className="buying-c-grid-item__description">
                                With the buyer paying a reservation fee and signing an agreement, 95% of transactions complete.
                                Unlike Private Treaty averaging 70% break down, leaving buyers and sellers with costs and frustation.
                            </p>
                        </li>
                        <li className="buying-c-grid-item">
                            <svg width="50" height="50" viewBox="-41 0 374 374.10622" xmlns="http://www.w3.org/2000/svg" fill="var(--color-primary-agency)">
                                <path d="m287.664062 75.746094-140.605468-74.921875c-2.085938-1.113281-4.585938-1.097657-6.65625.039062l-136.714844 74.921875c-2.242188 1.230469-3.6328125 3.582032-3.6328125 6.140625v97.867188c.0820315 80.449219 47.7460935 153.230469 121.4570315 185.460937l18.976562 8.269532c1.773438.773437 3.792969.777343 5.570313.007812l21.382812-9.234375c75.078125-31.53125 123.921875-105.015625 123.929688-186.445313v-95.925781c0-2.585937-1.425782-4.964843-3.707032-6.179687zm-10.292968 102.105468c-.015625 75.816407-45.503906 144.226563-115.410156 173.566407l-.046876.023437-18.621093 8.039063-16.179688-7.058594c-68.613281-30-112.984375-97.742187-113.0625-172.628906v-93.722657l129.761719-71.117187 133.558594 71.167969zm0 0" />
                                <path d="m92.167969 175.253906c-2.511719-2.941406-6.929688-3.289062-9.871094-.777344-2.941406 2.507813-3.289063 6.929688-.777344 9.867188l36.976563 43.300781c2.46875 2.890625 6.792968 3.285157 9.738281.886719l86.117187-70.0625c3-2.4375 3.453126-6.847656 1.011719-9.847656-2.4375-2.996094-6.847656-3.453125-9.847656-1.011719l-80.8125 65.742187zm0 0" />
                            </svg>
                            <h3 className="buying-c-grid-item__heading">Reserve Price</h3>
                            <p className="buying-c-grid-item__description">Set an undisclosed reserve price to ensure you always achieve a purchase price that you are happy for your property to sell for.</p>
                        </li>
                    </ul>
                </section>
                <section id="how" className="pt-28">
                    <header className="text-center mx-4">
                        <h2 className="font-bold text-3xl">How we make selling <span className="text-primary-agency">easy</span> for you</h2>
                        <p className="mt-4 font-semibold">We're here to guide you every step of the way.</p>
                    </header>
                    <div className="mx-4">
                        <div className="selling-c-steps max-w-lg-container mx-auto">
                            <article className="flex flex-col items-center bg-primary-gray p-8 pt-4 rounded-md">
                                <div className="flex flex-col items-center h-[220px] text-center">
                                    <svg className="transform -translate-y-3" width="100" height="100" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="var(--color-primary-agency)">
                                        <path d="m476 80h-440a28.031 28.031 0 0 0 -28 28v296a28.031 28.031 0 0 0 28 28h144.9l-12.49 37.47a8 8 0 0 0 7.59 10.53h160a8 8 0 0 0 7.59-10.53l-12.49-37.47h144.9a28.031 28.031 0 0 0 28-28v-296a28.031 28.031 0 0 0 -28-28zm-288.9 384 10.67-32h116.46l10.67 32zm300.9-60a12.01 12.01 0 0 1 -12 12h-440a12.01 12.01 0 0 1 -12-12v-20h464zm0-36h-464v-260a12.01 12.01 0 0 1 12-12h440a12.01 12.01 0 0 1 12 12z" />
                                        <path d="m324.24 153.22-64-40a7.964 7.964 0 0 0 -8.48 0l-64 40a7.987 7.987 0 0 0 -3.76 6.78v88a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-88a7.987 7.987 0 0 0 -3.76-6.78zm-60.24 86.78h-16v-32h16zm48 0h-32v-40a8 8 0 0 0 -8-8h-32a8 8 0 0 0 -8 8v40h-32v-75.57l56-35 56 35z" />
                                        <path d="m338.343 229.657a8 8 0 0 0 11.314 0l24-24a8 8 0 0 0 0-11.314l-24-24a8 8 0 0 0 -11.314 11.314l18.344 18.343-18.344 18.343a8 8 0 0 0 0 11.314z" />
                                        <path d="m162.343 229.657a8 8 0 0 0 11.314-11.314l-18.344-18.343 18.344-18.343a8 8 0 0 0 -11.314-11.314l-24 24a8 8 0 0 0 0 11.314z" />
                                        <path d="m360 264h-208a8 8 0 0 0 -8 8v24a8 8 0 0 0 8 8h208a8 8 0 0 0 8-8v-24a8 8 0 0 0 -8-8zm-8 24h-192v-8h192z" />
                                        <path d="m280 328a8 8 0 0 0 0-16h-48a8 8 0 0 0 0 16z" />
                                        <path d="m344 336h-176a8 8 0 0 0 0 16h176a8 8 0 0 0 0-16z" />
                                    </svg>
                                    <header className="-mt-1 text-center">
                                        <h3 className="font-bold text-2xl">We get you <span className="text-primary-agency">ready to sell</span></h3>
                                    </header>
                                    <p className="mt-auto font-semibold">We'll put your home on the market to maximise interest.</p>
                                </div>
                                <ul className="mt-5 space-y-4 pt-5 border-t border-t-primary font-semibold">
                                    <li className="flex space-x-3">
                                        <svg className="flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" fill="var(--color-primary-agency)">
                                            <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
                                                c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
                                                C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                                        </svg>
                                        <span>Property details, photos, floor plans gathered by us</span>
                                    </li>
                                    <li className="flex space-x-3">
                                        <svg className="flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" fill="var(--color-primary-agency)">
                                            <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
                                                c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
                                                C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                                        </svg>
                                        <span>Get your auction started when you are ready</span>
                                    </li>
                                    <li className="flex space-x-3">
                                        <svg className="flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" fill="var(--color-primary-agency)">
                                            <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
                                            c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
                                            C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                                        </svg>
                                        <span>A detailed and accurate valuation from local property experts.</span>
                                    </li>
                                </ul>
                            </article>
                            <article className="flex flex-col items-center bg-primary-gray p-8 pt-4 rounded-md">
                                <div className="flex flex-col items-center h-[220px] text-center">
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="80" height="80"
                                        viewBox="0 0 490.667 490.667" fill="var(--color-primary-agency)">
                                        <path d="M245.333,0C110.059,0,0,110.059,0,245.333s110.059,245.333,245.333,245.333s245.333-110.059,245.333-245.333
                                            S380.608,0,245.333,0z M245.333,469.333c-123.52,0-224-100.48-224-224s100.48-224,224-224s224,100.48,224,224
                                            S368.853,469.333,245.333,469.333z"/>
                                        <path d="M245.333,106.667c-5.888,0-10.667,4.779-10.667,10.667v256c0,5.888,4.779,10.667,10.667,10.667S256,379.221,256,373.333
                                            v-256C256,111.445,251.221,106.667,245.333,106.667z"/>
                                        <path d="M338.219,195.115l-85.333-85.333c-4.16-4.16-10.923-4.16-15.083,0l-85.333,85.333c-4.16,4.16-4.16,10.923,0,15.083
                                            c4.16,4.16,10.923,4.16,15.083,0l77.781-77.781l77.781,77.803c2.091,2.069,4.821,3.115,7.552,3.115
                                            c2.731,0,5.461-1.045,7.552-3.136C342.379,206.037,342.379,199.275,338.219,195.115z"/>
                                    </svg>
                                    <header className="mt-4 text-center">
                                        <h3 className="font-bold text-2xl">Get the <span className="text-primary-agency">best</span> possible price</h3>
                                    </header>
                                    <p className="mt-auto font-semibold">We'll discuss and agree pricing and auction duration with you.</p>
                                </div>
                                <ul className="mt-5 space-y-4 pt-5 border-t border-t-primary font-semibold">
                                    <li className="flex space-x-3">
                                        <svg className="flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" fill="var(--color-primary-agency)">
                                            <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
                                            c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
                                            C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                                        </svg>
                                        <span>No selling fees</span>
                                    </li>
                                    <li className="flex space-x-3">
                                        <svg className="flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" fill="var(--color-primary-agency)">
                                            <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
                                            c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
                                            C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                                        </svg>
                                        <span>Transparent and competitive bidding</span>
                                    </li>
                                    <li className="flex space-x-3">
                                        <svg className="flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" fill="var(--color-primary-agency)">
                                            <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
                                            c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
                                            C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                                        </svg>
                                        <span>Your advert gets seen by millions of active buyers on major property platforms</span>
                                    </li>
                                </ul>
                            </article>
                            <article className="flex flex-col items-center bg-primary-gray p-8 pt-4 rounded-md">
                                <div className="flex flex-col items-center h-[220px] text-center">
                                    <svg width="80" height="80" viewBox="0 -28 512.001 512" xmlns="http://www.w3.org/2000/svg" fill="var(--color-primary-agency)">
                                        <path d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0" />
                                    </svg>
                                    <header className="mt-4 text-center">
                                        <h3 className="font-bold text-2xl">We manage the sale to <span className="text-primary-agency">completion</span></h3>
                                    </header>
                                    <p className="mt-auto font-semibold">Our auction team contacts you upon the end of the auction.</p>
                                </div>
                                <ul className="mt-5 space-y-4 pt-5 border-t border-t-primary font-semibold">
                                    <li className="flex space-x-3">
                                        <svg className="flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" fill="var(--color-primary-agency)">
                                            <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
                                            c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
                                            C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                                        </svg>
                                        <span>We're always available and ready to keep you updated</span>
                                    </li>
                                    <li className="flex space-x-3">
                                        <svg className="flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" fill="var(--color-primary-agency)">
                                            <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
                                            c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
                                            C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                                        </svg>
                                        <span>We'll  be there to chase up solicitors and deal with any changes</span>
                                    </li>
                                    <li className="flex space-x-3">
                                        <svg className="flex-none" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" fill="var(--color-primary-agency)">
                                            <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
                                            c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
                                            C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                                        </svg>
                                        <span>A team of auction experts there when you need us most - all the way through to moving day.</span>
                                    </li>
                                </ul>
                            </article>
                        </div>
                    </div>
                </section>
                <section className="max-w-lg-container mx-auto px-4 mt-20">
                    <header className="text-center">
                        <h2 className="text-2xl md:text-4xl font-bold">Go-to resource for a successful sale</h2>
                        <p className="max-w-[1000px] mx-auto text-gray-600 font-semibold text-lg mt-8">
                            We strive to offer a transparent service to our sellers and buyers to ensure they are provided with enough
                            information to make an informed choice before proceeding.
                        </p>
                    </header>
                    <div className="flex justify-center mt-8">
                        <div>
                            <div className="flex flex-col sm:flex-row items-center space-y-8 sm:space-x-8 sm:space-y-0 text-center sm:text-left py-12 px-10 border-2 border-gray-300 rounded-lg">
                                <svg
                                    className="w-[100px] h-[100px] sm:w-[150px] sm:h-[150px]"
                                    viewBox="-79 -16 533 533.33257"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="var(--color-primary-agency)">
                                    <path
                                        d="m315.808594 33.949219h-80.355469c-9.105469-20.632813-29.535156-33.949219-52.089844-33.949219-22.558593 0-42.988281 13.316406-52.09375 33.949219h-80.359375c-30.3125.035156-54.875 24.601562-54.910156 54.914062v356.222657c.035156 30.3125 24.597656 54.878906 54.910156 54.914062h264.898438c30.3125-.035156 54.878906-24.601562 54.914062-54.914062v-356.222657c-.035156-30.3125-24.601562-54.878906-54.914062-54.914062zm-175.496094 25c5.867188 0 10.945312-4.082031 12.207031-9.820313 3.070313-13.976562 16.042969-24.128906 30.84375-24.128906s27.769531 10.152344 30.839844 24.128906c1.257813 5.738282 6.335937 9.820313 12.210937 9.820313h24.785157v10.96875c-.019531 16.511719-13.398438 29.890625-29.910157 29.910156h-75.855468c-16.515625-.019531-29.894532-13.398437-29.914063-29.910156v-10.96875zm205.410156 386.136719c-.015625 16.515624-13.398437 29.898437-29.914062 29.914062h-264.898438c-16.511718-.015625-29.890625-13.398438-29.910156-29.914062v-356.222657c.019531-16.515625 13.398438-29.894531 29.910156-29.914062h39.609375v10.96875c.035157 30.3125 24.601563 54.875 54.914063 54.910156h75.855468c30.3125-.035156 54.875-24.597656 54.910157-54.910156v-10.96875h39.609375c16.515625.019531 29.894531 13.398437 29.914062 29.914062zm0 0" /><path d="m245.226562 230.957031-82.0625 80.125-41.671874-40.675781c-4.902344-4.796875-12.753907-4.738281-17.59375.121094-4.835938 4.863281-4.855469 12.714844-.039063 17.597656l.167969.164062 50.398437 49.210938c4.859375 4.742188 12.613281 4.742188 17.46875 0l90.792969-88.648438c4.910156-4.828124 4.988281-12.714843.183594-17.640624-4.8125-4.925782-12.695313-5.035157-17.644532-.246094zm0 0" />
                                </svg>
                                <div className="space-y-5">
                                    <h3 className="text-xl font-bold">
                                        Get acquainted with the process
                                    </h3>
                                    <p>As you begin selling, learn what to expect with our Sellers Guide.</p>
                                    <a href={`${API_DOMAIN_ASSETS}assets/Auction Seller Guide.pdf`} target="_blank" rel="noopener noreferrer" className="inline-block px-4 py-2 rounded-full bg-secondary-agency text-white font-semibold">Selling Guide</a>
                                </div>
                            </div>
                            <p className="mt-6 font-bold self-start text-center sm:text-left">
                                Have Questions? Find out more through our <Link className="text-primary underline" to="/faq">FAQs</Link> or <Link className="text-primary underline" to="/contact">Contact us</Link> - we're happy to help!
                            </p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}