import React, { useState, useContext } from 'react';

import AppContext from '@contexts/AppContext';

import InputButton from '@components/forms/InputButton';
import DashboardChangePassword from '@components/dashboard/DashboardChangePassword';

export default function BidderDashboardSettings() {
    const { bidderData: { bidderToken, bidderSecret, email }, addNotification } = useContext(AppContext);
    const [showChangePassword, setShowChangePassword] = useState(false);

    return (
        <main>
            <div className="flex justify-center sm:justify-start">
                <h1 className="page-header mb-5">Settings</h1>
            </div>
            <p className="font-secondary font-semibold text-primary text-lg text-center sm:text-left mb-8">Here you can view and modify account settings. Currently you’re only able to change your password.</p>
            <h2 className="font-primary font-semibold text-primary text-center sm:text-left text-2xl mb-3">Email</h2>
            <p className="font-secondary text-center sm:text-left text-lg">{email}</p>
            <h2 className="font-primary font-semibold text-center sm:text-left text-primary text-2xl my-3">Password</h2>
            {
                !showChangePassword 
                ?
                (
                    <InputButton 
                    type="button"
                    submitText="Change Password" 
                    imageTitle="pencil"
                    imagePosition="left" 
                    onClick={(e) => setShowChangePassword(true)}
                    rootClass="mx-auto sm:mx-0" />
                )
                :
                (
                    <div className="flex justify-center sm:justify-start">
                        <DashboardChangePassword 
                        token={bidderToken} 
                        secret={bidderSecret}
                        userType="bidder"
                        handleCancel={(e) => setShowChangePassword(false)}
                        addNotification={addNotification} />
                    </div>

                )
            }

        </main>
    )
}