import React from 'react';
import { useHistory, useLocation, Switch, Route } from 'react-router-dom';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import AgentDashboardManageMain from './AgentDashboardManageMain';
import AgentDashboardPropertyForm from './manage-forms/AgentDashboardPropertyForm';

export default function AgentDashboardManageProperties() {
    const history = useHistory();
    const location = useLocation();

    return (
        <div>
            {
                location.pathname.startsWith("/agent/dashboard/manage/") &&
                (
                    <div className="inline-flex items-center mt-8 ml-5 cursor-pointer back-button">
                        <div className="transition-transform duration-200 ease-in-out back-button__icon">
                            <KeyboardBackspaceIcon className="mr-3 transform" fontSize="large" />
                        </div>
                        <span 
                        className="transition-colors duration-200 ease-in-out font-primary font-bold text-3xl back-button__text"
                        onClick={(e) => history.push("/agent/dashboard/manage")}>Go Back</span>
                    </div>
                )

            }
            <Switch>
                <Route path="/agent/dashboard/manage" exact component={AgentDashboardManageMain} />
                <Route path="/agent/dashboard/manage/add" exact render={(props) => <AgentDashboardPropertyForm mode="add" {...props} />} />
                <Route path="/agent/dashboard/manage/edit/:productId" exact render={(props) => <AgentDashboardPropertyForm mode="edit" {...props} />} />
            </Switch>
        </div>
    )
}