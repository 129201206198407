import ReactGA from 'react-ga';

import { GOOGLE_ANALYTICS_TRACK_ID } from '@util/site-constants'

export function init() {
  ReactGA.initialize(GOOGLE_ANALYTICS_TRACK_ID);
}

export function pageview(pathname, search = '') {
  ReactGA.pageview(pathname + search);
}
