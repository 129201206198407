import React, { useMemo } from 'react';

import PropertyPageTimer from '@components/timers/PropertyPageTimer';

export default function PropertyPageStatusSection({ productStatus, publishDate, bidEndTime, isSuccessfulBidder, isExtended, handleBidEnd, serverMS }) {
    const displayStatus = useMemo(() => {
        let displayStatus;

        switch (productStatus) {
            case 'Pre-Market':
                displayStatus = (
                    <p className="font-bold text-3xl">Bidding opens soon</p>
                );
                break;
            case 'Scheduled Pre-Market':
                displayStatus = (
                    <>
                        <p className="self-start">Bidding opens on</p>
                        <time className="font-bold text-2xl mt-4">{publishDate}</time>
                    </>
                );
                break;
            case 'Live':
                displayStatus = (
                    <>
                        <header className="mb-3 lg:mb-0">
                            <h2 className="font-semibold text-xl">Bidding closes in</h2>
                        </header>
                        <PropertyPageTimer startTime={serverMS} endTime={bidEndTime} handleEnd={handleBidEnd} rootClass="flex-grow" />
                        {
                            !!isExtended && <p className="bg-bid-yellow font-semibold text-lg text-black text-center py-1">Entered Extended Time</p>
                        }
                    </>
                );
                break;
            case 'Sold':
                displayStatus = (
                    <>
                        <p className="font-bold text-3xl">Property Sold</p>
                        {
                            isSuccessfulBidder && (
                                <p className="font-bold text-xl mt-4 text-center">Congratulations! 
                                You are the winning 
                                bidder. Our agent 
                                will be in contact.</p>
                            )
                        }
                    </>
                );
                break;
            case 'Ended':
                displayStatus = <p className="font-bold text-3xl">Bid Ended</p>
                break;
            default:
                break;
        }

        return displayStatus;
    }, [productStatus, publishDate, bidEndTime, isExtended, serverMS, isSuccessfulBidder]);

    return (
        <section className={`p-6 lg:w-4/10 property-page-1300:w-3/10 bg-primary-agency-dark flex flex-col ${productStatus !== 'Live' ? 'justify-center items-center' : ''}`}>
            {displayStatus}
        </section>
    );
}