import React, { useContext, useMemo } from 'react';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import AppContext from '@contexts/AppContext';


export default function Notification() {
    const { notifications, setNotificationExpand, notificationExpand } = useContext(AppContext);
    
    const canExpand = useMemo(() => (!!notifications.length && notifications[0].innerDescription), [notifications]);

    return (
        <div className={`transform fixed top-0 flex justify-center sm:max-w-6/10 -translate-x-1/2 left-1/2 z-50 mt-2 ${!notifications.length && 'pointer-events-none'}`}>
            {
                <div className={`transform translate-y-8 ${!notifications.length && '-translate-y-80'} transition-transform duration-700 ease-in-out bg-primary-agency rounded-xl py-3 px-6 text-white min-w-mobile max-w-mobile sm:max-w-none shadow-xl`}>
                    <div 
                    className={`flex transition-all duration-700 ease-in-out ${canExpand && 'cursor-pointer'} ${notificationExpand && 'mb-3'}`}
                    onClick={(e) => canExpand && setNotificationExpand(!notificationExpand)}>
                        <NotificationsActiveIcon className="text-white self-start" />
                        <div className="ml-5 mr-3 font-primary font-semibold">{notifications.length ? notifications[0].headerText : ''}</div>
                        {
                            canExpand && (
                                notificationExpand
                                ? 
                                (
                                    <KeyboardArrowUpIcon className="text-white ml-auto" />
                                )
                                :
                                (
                                    <KeyboardArrowDownIcon className="text-white ml-auto" />
                                )
                            )
                        }
                    </div>
                    <div className={`border-white h-0 overflow-hidden ${notificationExpand && 'h-full border-t-2 p-2'}`}>
                        {notifications.length ? notifications[0].innerDescription : ''}
                    </div>
                </div>
            }
        </div>
    )
}