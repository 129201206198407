import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import AgentDashboardPropertyMoreInfo from './AgentDashboardPropertyMoreInfo';
import AgentDashboardPropertyDelete from './AgentDashboardPropertyDelete';


function AgentDashboardTableActionBar({ propertyData, handleDelete }) {
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const propertyDataEditTransform = useMemo(() => {
        return {
            ...propertyData,
            assetImages: propertyData.assetImages.map((img) => { 
                if (img.title) {
                    let nameSplit = img.title.split('.');
                    
                    return { name: img.title, data: img.path, type: nameSplit[nameSplit.length - 1] }
                }
                return undefined
            }),
            assetDocuments: propertyData.assetDocuments.map((doc) => {
                if (doc.title) {
                    let nameSplit = doc.title.split('.');
                
                    return { name: doc.title, data: doc.path, type: nameSplit[nameSplit.length - 1] }  
                }
                return undefined
            })
        }
    }, [propertyData]);

    const propertyDataMoreInfoTransform = useMemo(() => {
        return {
            ...propertyData,
            publishDate: propertyData.publishDate ? format(parseISO(propertyData.publishDate), 'dd MMM, yyyy HH:mm:ss') : null,
            bidEndTime: propertyData.bidEndTime ? format(parseISO(propertyData.bidEndTime), 'dd MMM, yyyy HH:mm:ss') : null
        }
    }, [propertyData]);

    return (
        <>
            <div className="flex space-x-3 items-center">

                <div 
                className={`relative tooltip__container rounded-lg  p-1 
                ${propertyData.productStatus === 'Live' || propertyData.productStatus === 'Withdrawn' || propertyData.bidEnded ? 'text-dark-gray' : 'text-primary hover:bg-table-light-blue cursor-pointer'}`}
                >
                    <Link 
                    className={propertyData.productStatus === 'Live' || propertyData.productStatus === 'Withdrawn' || propertyData.bidEnded ? 'pointer-events-none' : ''}
                    to={{
                        pathname: `/agent/dashboard/manage/edit/${propertyDataEditTransform.productId}`,
                        state: {
                            propertyData: propertyDataEditTransform
                        }
                    }}>
                        <span className={`${propertyData.productStatus === 'Withdrawn' || propertyData.productStatus === 'Live' || propertyData.bidEnded ? '' : 'tooltip__tooltip'}`}>
                            {propertyData.productStatus !== 'Live' && propertyData.productStatus !== 'Withdrawn' && !propertyData.bidEnded && 'Edit'}
                        </span>
                        <CreateOutlinedIcon />
                    </Link>
                </div>


                <div 
                className="relative tooltip__container cursor-pointer rounded-lg hover:bg-table-light-blue p-1 text-primary"
                onClick={(e) => setInfoModalOpen(true)}>
                    <span className="tooltip__tooltip text-center">More information</span>
                    <AddCircleOutlineOutlinedIcon />
                </div>
                {
                    <div className={`relative tooltip__container rounded-lg p-1
                    ${propertyData.productStatus === 'Draft' ? 'text-primary hover:bg-table-light-blue cursor-pointer' : 'text-dark-gray'}`}
                    onClick={(e) => propertyData.productStatus === 'Draft' && setDeleteModalOpen(true)}>
                        <span className={`tooltip__tooltip ${propertyData.productStatus !== 'Draft' ? 'translate-x-0 right-0' : ''}`}>{propertyData.productStatus === 'Draft' ? 'Delete' : 'Only drafts can be deleted'}</span>
                        <DeleteIcon /> 
                    </div>
                }

            </div>
            <AgentDashboardPropertyMoreInfo open={infoModalOpen} handleClose={() => setInfoModalOpen(false)} propertyData={propertyDataMoreInfoTransform} />
            <AgentDashboardPropertyDelete 
            open={deleteModalOpen} 
            handleClose={() => setDeleteModalOpen(false)} propertyId={propertyData.productId}
            handleDelete={handleDelete} />
        </>
    )
}

export default React.memo(AgentDashboardTableActionBar);