import React from 'react';
import { Link } from 'react-router-dom';

import AgentDashboardPropertyTable from './AgentDashboardPropertyTable';
import InputButton from '@components/forms/InputButton';

export default function AgentDashboardManageMain() {
    return (
        <div>
            <div className="text-center agent-dashboard-manage-sm:text-left">
                <h1 className="dashboard-header border-b-4 border-primary-agency inline-block mb-5">Manage Properties</h1>
            </div>
            <h2 className="font-secondary font-black text-primary-agency text-2xl text-center agent-dashboard-manage-sm:text-left">Here you can create, edit, and delete properties.</h2>
            <div className="flex flex-col items-center agent-dashboard-manage-sm:flex-row agent-dashboard-manage-sm:space-x-5 mt-8">
                <h2 className="font-primary font-bold text-4xl text-center agent-dashboard-manage-sm:text-left">
                    Your Properties
                </h2>

                <Link to="/agent/dashboard/manage/add">
                    <InputButton 
                    submitText="Add a new property"
                    imageTitle="add"
                    imagePosition="left"
                    inline
                    rounded="lg"
                    type="button" />
                </Link>
            </div>
            <AgentDashboardPropertyTable />
        </div>
    )
}