import React from 'react';
import InputButton from './InputButton';

export default function FileUpload({ label, inputId, error, accept, handleChange, ...inputButtonProps }) {
    return (
        <div>
            <div className={error ? 'border-red-500 border-2 rounded-lg p-2' : ''}>
                {label && <label className="form-input-label block relative text-base text-center" htmlFor={inputId}>{label}</label>}
                <div className="relative " >
                    <input
                        id={inputId}
                        aria-labelledby={inputId}
                        className="absolute invisible top-0 left-0 w-full"
                        type="file"
                        accept={accept}
                        onChange={handleChange} />
                    <InputButton
                        type="button"
                        onClick={(e) => {
                            document.querySelector(`#${inputId}`).click();
                        }}
                        submitText={"Choose an Image"}
                        {...inputButtonProps} />
                </div>
            </div>
            <div className="relative">
                {error && <span className="form-error-text absolute w-full text-center mt-1 text-sm">{error}</span>}
            </div>
        </div>
    )
}