import React, { useEffect, useContext } from 'react';

import ValidationTypes from '@util/ValidationTypes';

import BidderRegistrationContext from '@contexts/BidderRegistrationContext';

import useForm from '@hooks/useForm';
import useFileUpload from '@hooks/useFileUpload';

import InputButton from '@components/forms/InputButton';
import FileUpload from '@components/forms/FileUpload';

export default function BidderDashboardCompleteID({ setActiveStep }) {
    const { completeIDData, setCompleteIDData } = useContext(BidderRegistrationContext);
    const registerFormRules = {
        passportScan: [ValidationTypes.requiredString],
        IDScan: [ValidationTypes.requiredString],
        terms: [{
            test: (value) => value,
            errorMsg: 'You must accept the terms and conditions'
        }]
    };

    const { formData, setFormData, handleChange, errorMapping, handleSubmit } = useForm(completeIDData ? {
        passportScan: completeIDData.imgData ? (completeIDData.imgData.passportScan ? completeIDData.imgData.passportScan.data : '') : '',
        IDScan: completeIDData.imgData ? (completeIDData.imgData.IDScan ? completeIDData.imgData.IDScan.data : '') : '',
        terms: completeIDData.terms
    } : {
        passportScan: '',
        IDScan: '',
        terms: false
    }, registerFormRules);

    const { imgData, imgErrs, getFileData } = useFileUpload(setFormData, ["jpeg", "JPEG", "jpg", "JPG", "png", "PNG", "gif", "GIF", "pdf", "PDF"], completeIDData && completeIDData.imgData ? completeIDData.imgData : null,
        completeIDData && completeIDData.imgErrs ? completeIDData.imgErrs : null);


    const handleNewFile = (e) => {
        if (e.target.files[0]) {
            if (e.target.id === 'idPass') {
                getFileData("passportScan", e.target.files[0]);
            }
            else {
                getFileData("IDScan", e.target.files[0]);
            }
        }
    }

    useEffect(() => {
        setCompleteIDData({ imgData, imgErrs, terms: formData.terms });
    }, [imgData, imgErrs, formData.terms]);


    return (
        <div className="flex flex-col items-center">

            <p className="font-secondary font-bold text-primary-agency text-center mb-5 px-20">Please provide a scan(image) of an Utility bill (issued within three months) and a Passport to confirm your identity.
                Documents must be less than 1MB. Allowable image formats: jpeg, jpg, png, gif or pdf </p>
            <form
                onSubmit={(e) => handleSubmit(e, () => setActiveStep(2))}
                className="w-full max-w-container">
                <div className="rounded-lg pb-12 bg-white shadow-lg">
                    <section>

                        <div className="flex flex-col md:flex-row items-center md:items-start space-y-5 md:space-y-0 md:space-x-20 justify-around mt-5">
                            <div className="flex flex-col items-center max-w-sm">
                                <FileUpload
                                    label="Add Utility Bill Document"
                                    inputId="idImg"
                                    error={imgErrs.IDScan || errorMapping.IDScan}
                                    accept=".jpeg,.JPEG,.jpg,.JPG,.png,.PNG,.gif,.GIF,.pdf,.PDF"
                                    handleChange={handleNewFile} />
                                <span className="inline-block mt-14 font-primary font-semibold">Selected document: </span>
                                <span className="mt-1 block font-primary font-bold text-center">{imgData.IDScan ? imgData.IDScan.name : 'Pending'}</span>
                                <img
                                    className="h-56 object-contain shadow-lg"
                                    src={formData.IDScan}
                                    alt="" />
                            </div>
                            <div className="flex flex-col items-center max-w-sm">
                                <FileUpload
                                    label="Add Passport Document"
                                    inputId="idPass"
                                    error={imgErrs.passportScan || errorMapping.passportScan}
                                    accept=".jpeg,.JPEG,.jpg,.JPG,.png,.PNG,.gif,.GIF,.pdf,.PDF"
                                    handleChange={handleNewFile} />
                                <span className="inline-block mt-14 font-primary font-semibold">Selected document: </span>

                                <span className="mt-1 block font-primary font-bold text-center">{imgData.passportScan ? imgData.passportScan.name : 'Pending'}</span>

                                <img
                                    className="h-56 object-contain shadow-lg"
                                    src={formData.passportScan}
                                    alt="" />
                            </div>
                        </div>
                    </section>
                    <div className="flex justify-center items-center mt-10">
                        <input
                            id="termsAccepted"
                            className="form-input w-auto inline-block"
                            type="checkbox"
                            name="terms"
                            checked={formData.terms}
                            onChange={handleChange}
                        />
                        <label htmlFor="termsAccepted" className="ml-2 form-input-label text-base sm:text-lg mb-0 leading-none inline-block">I have read and agree to the&nbsp;
                            <a className="font-primary font-black text-primary-agency underline" href="#">Terms</a> and&nbsp;
                            <a className="font-primary font-black text-primary-agency underline" href="#">Conditions</a>.</label>
                    </div>
                    <div className="relative flex justify-center">
                        {errorMapping.terms && <p className="form-error-text mt-2 text-center absolute">{errorMapping.terms}</p>}
                    </div>
                </div>
                <div className="flex flex-col-reverse sm:flex-row items-center sm:items-start justify-center mt-8">
                    <InputButton
                        type="button"
                        submitText="Back (Complete Profile)"
                        inputStyle="inverse"
                        onClick={(e) => setActiveStep(0)}
                        rootClass="mt-5 sm:mt-0" />
                    <InputButton
                        type="submit"
                        submitText="Next"
                        rootClass="ml-0 sm:ml-5" />
                </div>
            </form>
        </div>
    )
}