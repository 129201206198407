import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import ValidationTypes from '@util/ValidationTypes';

import useForm from '@hooks/useForm';

import FormInput from '@components/forms/FormInput';
import ModalLayout from '@components/ModalLayout';
import GoogleReCAPTCHA from '@components/forms/GoogleReCAPTCH';
import { requestValuation } from '@services/agency';

export default function ValuationModal({ open, handleModalClose }) {
    const [formError, setFormError] = useState(null);
    const [formSuccess, setFormSuccess] = useState(null);
    const formRules = {
        firstName: [ValidationTypes.requiredString],
        lastName: [ValidationTypes.requiredString],
        email: [ValidationTypes.requiredString],
        propertyAddress: [ValidationTypes.requiredString],
        phoneNumber: [ValidationTypes.requiredString],
        recaptcha: [ValidationTypes.requiredString]
    };

    const { formData, handleChange, errorMapping, handleSubmit, reset } = useForm({
        firstName: '',
        lastName: '',
        propertyAddress: '',
        phoneNumber: '',
        email: '',
        recaptcha: null
    }, formRules);

    useEffect(() => {
        if (!open) {
            setFormError(null)
            setFormSuccess(null)
            reset()
        }
    }, [open])

    const handleSendForm = async () => {
        const { firstName, lastName, email, propertyAddress, phoneNumber, recaptcha } = formData;

        const res = await requestValuation({
            name: `${firstName} ${lastName}`,
            email,
            propertyAddress,
            phoneNumber,
            'g-recaptcha-response': recaptcha
        });

        if (res) {
            setFormSuccess(true);
        } else {
            setFormError('Request for valuation failed. Please contact us or try again.');
        }
    };

    return (
        <ModalLayout
        open={open} 
        handleClose={handleModalClose}
        modalBodyClass="p-16 m-4 max-w-[900px] max-h-[90vh] overflow-y-auto">
            <header className="space-y-6">
                <svg 
                className="mx-auto"
                width="75"
                height="75"
                version="1.1" 
                id="Capa_1" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 476.912 476.912"
                fill="var(--color-primary-agency)">
                    <path d="M461.776,209.408L249.568,4.52c-6.182-6.026-16.042-6.026-22.224,0L15.144,209.4c-3.124,3.015-4.888,7.17-4.888,11.512
                        c0,8.837,7.164,16,16,16h28.2v224c0,8.837,7.163,16,16,16h112c8.837,0,16-7.163,16-16v-128h80v128c0,8.837,7.163,16,16,16h112
                        c8.837,0,16-7.163,16-16v-224h28.2c4.338,0,8.489-1.761,11.504-4.88C468.301,225.678,468.129,215.549,461.776,209.408z
                        M422.456,220.912c-8.837,0-16,7.163-16,16v224h-112v-128c0-8.837-7.163-16-16-16h-80c-8.837,0-16,7.163-16,16v128h-112v-224
                        c0-8.837-7.163-16-16-16h-28.2l212.2-204.88l212.28,204.88H422.456z"/>
                        </svg>
                <h2 className="text-center text-2xl font-bold">Your Property Valuation</h2>
                <p className="text-sm font-semibold">If you would like to sell your property at auction, please complete the form below and a member of our team will be in touch.</p>
            </header>
            <form className="space-y-8 mt-8" onSubmit={(e) => handleSubmit(e, handleSendForm)}>
                <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                    <FormInput 
                    type="text"
                    placeholder="First Name"
                    inputName="firstName"
                    value={formData.firstName}
                    error={errorMapping.firstName}
                    handleChange={handleChange} 
                    rootClass="flex-1" />      
                    <FormInput 
                    type="text"
                    placeholder="Last Name"
                    inputName="lastName"
                    value={formData.lastName}
                    error={errorMapping.lastName}
                    handleChange={handleChange}
                    rootClass="flex-1" /> 
                </div>
                <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                    <FormInput 
                    type="text"
                    placeholder="Telephone"
                    inputName="phoneNumber"
                    value={formData.phoneNumber}
                    error={errorMapping.phoneNumber}
                    handleChange={handleChange} />
                    <FormInput 
                    type="text"
                    placeholder="Email"
                    inputName="email"
                    value={formData.email}
                    error={errorMapping.email}
                    handleChange={handleChange} />
                </div>
                <div className="relative">
                    <textarea 
                    className={`form-input ${errorMapping.propertyAddress ? 'border-2 border-red-500 hover:border-red-500 focus:border-red-500' : ''}`}
                    name="propertyAddress"
                    placeholder="Property Address"
                    value={formData.propertyAddress}
                    onChange={handleChange}>
                    </textarea>
                    {
                        errorMapping.propertyAddress && (
                            <p className="absolute mt-1 text-red-500 text-sm font-semibold">{errorMapping.propertyAddress}</p>
                        )
                    }
                </div>
                <GoogleReCAPTCHA inputName="recaptcha" value={formData.recaptcha} handleChange={handleChange} error={errorMapping.recaptcha} />
                <button className="w-full py-3 text-center bg-secondary-agency text-white rounded-full">Send</button>
                {
                    formError && (
                        <p className="text-center text-red-500">{formError}</p>
                    )
                }
                {
                    formSuccess && (
                        <p className="text-center text-xl font-bold">Request for valuation sent!</p>
                    )
                }
                <p className="text-sm text-center font-semibold">By filling in this form you agree to our <Link to="/">privacy policy.</Link></p>
            </form>
        </ModalLayout>
    )
}