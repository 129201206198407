import React, { useState, useMemo } from 'react';
import SwipeableViews from 'react-swipeable-views';

import placeholder from '@static/placeholder_property.png';

import { IMG_BASE_URL } from '@util/site-constants';
import { transformStatus } from '@util/product';

import InlineTimer from '@components/timers/InlineTimer';

export default function PropertyImageSlider({ images, status, bidEndTime, currImageIdx, setCurrImageIdx }) {
    const hasEnded = useMemo(() => status !== 'Live' && transformStatus(status) !== 'Scheduled Pre-Market', [status]);
    const memoizedTime = useMemo(() => Date.now(), []);

    const [bidEnd, setBidEnd] = useState(false);

    const handleBidEnd = () => {
        setBidEnd(true);
    }
    const dotColor = useMemo(() => {
        let res;

        switch (status) {
            case 'PreMarket':
            case 'ScheduledPreMarket':
                res = 'bg-yellow-500';
                break;
            case 'Live':
                res = 'bg-green-400';
                break;
            default:
                res = 'bg-gray-500';
                break;
        }

        return res;
    }, [status]); 

    return (
        <div className="relative rounded-md rounded-b-none overflow-hidden h-0 pb-16/9">
            <SwipeableViews 
            index={currImageIdx} 
            onChangeIndex={(idx) => {
                if (currImageIdx !== idx) {
                    setCurrImageIdx(idx);
                }
            }}
            containerStyle={{
                height: "100%",
                position: "absolute",
                width: "100%"
            }}>
            {
                images.length ? (
                    images.map((image, idx) => {
                        return (
                        <img 
                        key={idx}
                        loading="lazy"
                        className="w-full object-cover h-full"
                        src={`${IMG_BASE_URL}/${image.path}`} 
                        alt=""/>
                        );
                    })
                )
                : (
                    <img 
                    src={placeholder} 
                    loading="lazy"
                    className="w-full object-cover h-full"
                    alt="Placeholder" />
                )

            }
            </SwipeableViews>
            {
                !!images.length && (
                    <div className="absolute bottom-0 right-0 mr-3 mb-3 flex justify-center px-3 py-1 bg-black rounded-lg bg-opacity-60 space-x-3 text-white text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 471.04 471.04" fill="currentColor">
                                <path d="M414.72,112.64h-49.152l-27.136-40.96c-10.24-15.36-28.16-24.576-46.592-24.576H179.2
                                    c-18.432,0-36.352,9.216-46.592,24.576l-27.136,40.96H56.32C25.088,112.64,0,137.728,0,168.96v198.656
                                    c0,31.232,25.088,56.32,56.32,56.32h358.4c31.232,0,56.32-25.088,56.32-56.32V168.96C471.04,137.728,445.952,112.64,414.72,112.64
                                    z M235.52,377.856c-70.144,0-126.976-56.832-126.976-126.976c0-70.144,56.832-126.464,126.976-126.464
                                    s126.976,56.832,126.976,126.976C362.496,321.024,305.664,377.856,235.52,377.856z M407.552,192c-0.512,0-1.024,0-2.048,0h-20.48
                                    c-9.216-0.512-16.384-8.192-15.872-17.408c0.512-8.704,7.168-15.36,15.872-15.872h20.48c9.216-0.512,16.896,6.656,17.408,15.872
                                    C423.424,183.808,416.768,191.488,407.552,192z"/>
                                <path d="M235.52,180.736c-38.912,0-70.656,31.744-70.656,70.656s31.744,70.144,70.656,70.144s70.656-31.744,70.656-70.656
                                    C306.176,211.968,274.432,180.736,235.52,180.736z"/>
                            </svg>
                        <span>{currImageIdx + 1} / {images.length}</span>
                    </div>
                )
            }
            <section className="absolute top-0 left-0 mt-3 ml-3 p-3 py-1 rounded-lg bg-black bg-opacity-60 flex items-center space-x-3 text-white text-sm">
                <div className={`w-3 h-3 rounded-full ${dotColor}`}></div>
                <p>{transformStatus(status)}</p>
            </section>
            {
                bidEndTime && status === 'Live' && !hasEnded && !bidEnd && (
                     <section className="absolute bottom-0 left-0 mb-3 ml-3 p-3 py-1 rounded-lg bg-primary-agency text-white">
                        <InlineTimer 
                        rootClass="text-sm tabular-nums"
                        startTime={memoizedTime} 
                        endTime={bidEndTime} 
                        handleEnd={handleBidEnd}
                        customFormat={({ days, hours, minutes, seconds }) => (`
                            ${days}d ${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}
                        `)} /> 
                    </section>
                )
            }
        </div>
    )
}