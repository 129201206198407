import React from 'react';

import BaseTimer from './BaseTimer';

export default function InlineTimer({ rootClass, customFormat, ...baseProps }) {
    return (
        <BaseTimer {...baseProps}>
        {
            (timeLeft) => (
                <span className={`block ${rootClass ? rootClass : 'text-lg'}`}>{customFormat ? customFormat(timeLeft) : `${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`}</span>
            )
        }
        </BaseTimer>
    )
};