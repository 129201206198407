import React, { useState, useContext } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';

import ValidationTypes from '@util/ValidationTypes';

import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

import useForm from '@hooks/useForm';
import useAPI from '@hooks/useAPI';

import InputButton from '../forms/InputButton';
import FormInput from '../forms/FormInput';

export default function DashboardChangePassword({ handleCancel, rootClass }) {
    const { addNotification } = useContext(AppContext);
    const { userType } = useContext(AuthContext);
    const { makeRequest, loading, err } = useAPI({
        method: 'post',
        endpoint: `/api/${userType}/updatePassword`,
        handleSuccess: () => {
            handleCancel();
            addNotification('Password updated!');
        }
    });
    const [showPasswordHelper, setShowPasswordHelper] = useState(false);

    const validationRules = {
        password: userType === 'agent' ? null : [ValidationTypes.requiredString],
        newPassword: [ValidationTypes.requiredString, {
            test: (value) => value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
            errorMsg: "Invalid password format"
        }, {
            test: (value, formData) => value !== formData.password,
            errorMsg: "New password can't be the same as the one above" 
        }],
        newPassword2: [ValidationTypes.requiredString, {
            test: (value, formData) => value === formData.newPassword,
            errorMsg: 'Passwords do not match'
        }]
    }

    const { formData, handleChange, errorMapping, handleSubmit } = useForm({
        password: '',
        newPassword: '',
        newPassword2: ''
    }, validationRules);

    const handleSubmitSuccess = async () => {
        const { password, newPassword } = formData;

        await makeRequest({
            password,
            newPassword
        });
    }
    return (
        <form className={rootClass} onSubmit={(e) => handleSubmit(e, handleSubmitSuccess)}>
            {
                userType === 'bidder' && (
                    <FormInput 
                    type="password"
                    label="Current Password"
                    inputName="password"
                    value={formData.password}
                    error={errorMapping.password}
                    handleChange={handleChange} />
                )
            }
            <div className={`relative ${userType === 'bidder' ? 'mt-8' : ''}`}>
                <FormInput 
                type="password"
                label="New password"
                inputName="newPassword"
                value={formData.newPassword}
                error={errorMapping.newPassword} 
                handleChange={handleChange} />
                {
                    showPasswordHelper 
                    ?
                    (
                        <CancelIcon className="absolute top-0 right-0 mr-3 cursor-pointer" onClick={(e) => setShowPasswordHelper(false) }/>
                    )
                    :
                    (
                        <HelpIcon className="absolute top-0 right-0 mr-3 cursor-pointer" onClick={(e) => setShowPasswordHelper(true) }/>
                    )
                }
                <p className={`helper-text z-20 bottom-full right-0 mr-3 w-64 ${showPasswordHelper ? 'opacity-100' : ''} pointer-events-none`}>
                    Password must be at least 8 characters and must contain the following:
                    <ul className="mt-3 list-disc ml-3">
                        <li>At least 1 uppercase letter</li>
                        <li>At least 1 lowercase letter</li>
                        <li>At least 1 digit</li>
                    </ul>
                </p>
            </div>
            <FormInput 
            type="password"
            label="Repeat new password"
            inputName="newPassword2"
            value={formData.newPassword2}
            error={errorMapping.newPassword2} 
            handleChange={handleChange} 
            rootClass="mt-8" />
            {
                err && (
                    <p className="text-center my-2 text-air-red">{err.type === 'client' ? 'Error:' : 'Server error: Status Code'} {err.context}</p>
                )
            }
            <div className="flex justify-center space-x-5 mt-8">
                <InputButton 
                type="submit" 
                submitText="Save"
                imageTitle="pencil"
                imagePosition="left"
                loading={loading} />
                <InputButton 
                type="button" 
                submitText="Cancel"
                imageTitle="clear"
                imagePosition="left"
                inputStyle="inverse"
                disabled={loading}
                onClick={handleCancel} />
            </div>
        </form>
    )
}