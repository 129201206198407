import React, { useState, useContext } from 'react';

import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

import useForm from '@hooks/useForm';
import useAPI from '@hooks/useAPI';

import InputButton from '@components/forms/InputButton';

export default function BidderDashboardHomePreferencesForm({ preferences, setIsEditing }) {
    const { addNotification } = useContext(AppContext);
    const { setUserData } = useContext(AuthContext);

    const [editedPreferences, setEditedPreferences] = useState(false);

    const { formData, handleChange, handleSubmit } = useForm({
        ...preferences,
    });

    const { makeRequest, loading, err } = useAPI({
        method: 'post',
        endpoint: '/api/bidder/preferencesUpdate',
        handleSuccess: () => {
            addNotification('Your preferences are updated!');
            setUserData((userData) => ({ ...userData, ...formData, newsletter: formData.newsletter === 'yes' }));
        }
    });


    /**
     * Checks to see if currently active input has been edited. If so, enable the submit button!
     */
    const customHandleChange = (e) => {
        handleChange(null, () => {
            let newFormData = { ...formData, [e.target.name]: (e.target.type === "checkbox" ? e.target.checked : e.target.value) };
            
            if (preferences[e.target.name] !== e.target.value) {
                setEditedPreferences(true);
            }
            else {
                setEditedPreferences(false);
            }

            return newFormData;
        });
    }

    const handleSubmitSuccess = async () => {
        if (editedPreferences) {
            await makeRequest({
                ...formData,
                newsletter: formData.newsletter === 'yes'
            });

            setIsEditing(false);
        }
    };

    return (
        <form onSubmit={(e) => handleSubmit(e, handleSubmitSuccess)}>
            <div className="mt-5 space-y-8">
                <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 sm:space-x-3">
                    <p className="font-semibold text-sm">I would like to receive email updates on available auction properties.</p>
                    <div className="flex items-center sm:items-start space-x-3">
                        <div className="flex items-center">
                            <input 
                            id="newsYes"
                            type="radio"
                            name="newsletter"
                            value="yes"
                            checked={formData.newsletter === 'yes'}
                            onChange={customHandleChange} />
                            <label className="ml-2" htmlFor="newsYes">Yes</label>
                        </div>
                        <div className="flex items-center">
                            <input 
                            id="newsNo"
                            type="radio"
                            name="newsletter"
                            value="no"
                            checked={formData.newsletter === 'no'}
                            onChange={customHandleChange} />
                            <label className="ml-2" htmlFor="newsNo">No</label>
                        </div>
                    </div>
                </div>
            </div>
            {
                err && (
                    <p className="text-center my-2 text-air-red">{err.type === 'client' ? 'Error:' : 'Server error: Status Code'} {err.context}</p>
                )
            }
            <InputButton 
            submitText="Update My Preferences"
            rootClass="mx-auto mt-10"
            loading={loading}
            disabled={!editedPreferences} />
        </form>
    )
}