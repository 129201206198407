import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import AppContext from '@contexts/AppContext';
import { API_DOMAIN_ASSETS } from '@util/site-constants';


export default function Footer() {
    const { agencyData } = useContext(AppContext);

    return (
        <div className="bg-primary-gray">
            <footer className="mx-auto max-w-lg-container py-10 px-8 xxl:px-0">
                <div>
                    <h2 className="text-center md:text-left text-xl font-bold">Explore Auctions</h2>
                    <div className="flex flex-col items-center md:flex-row md:justify-between space-y-5 md:space-y-0 mt-8 font-semibold">
                        <div className="flex flex-col items-center md:items-start space-y-4 w-32">
                            <Link to="/buying">Buying</Link>
                            <Link to="/selling">Selling</Link>
                            <a href={`${API_DOMAIN_ASSETS}assets/Auction Buyer Guide.pdf`} target="_blank" rel="noopener noreferrer">Buying Guide</a>
                            <a href={`${API_DOMAIN_ASSETS}assets/Auction Seller Guide.pdf`} target="_blank" rel="noopener noreferrer">Selling Guide</a>
                        </div>
                        <div className=" flex flex-col items-center md:items-start space-y-4 w-32">
                            <Link to="/search?city=&minPrice=-1&maxPrice=-1&bedRooms=-1">Properties</Link>
                            <Link to="/faq">FAQs</Link>
                            <Link to="/contact">Contact Us</Link>
                            <a href={agencyData.externalAddress} target="_blank">Other services</a>

                        </div>
                        <div className="flex flex-col items-center md:items-start space-y-4 w-32">

                            <Link to="/">Privacy</Link>
                            <Link to="/">Cookie policy</Link>
                            <Link to="/">Terms of use</Link>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between items-center mt-12">
                        <div>
                            <span className="material-icons text-lg align-middle">copyright</span>
                            <p className="font-bold inline-block align-middle">2022 321sold Limited. All rights reserved.</p>
                        </div>
                        <div className="flex-1 space-y-4 flex flex-col items-end">
                            <p className="text-center md:text-left font-bold">Powered by <span className="sr-only">{agencyData.name}</span></p>
                            <img src={`${API_DOMAIN_ASSETS}assets/321sold-PoweredByLogo.png`} alt="321sold Estate Agents Solution Provider" />
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}