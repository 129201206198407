import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'

import AppContext from '@contexts/AppContext'
import Select from '@components/forms/Select'
import PropertyCard from '@components/properties/card/PropertyCard'
import CircularProgress from '@material-ui/core/CircularProgress'
import Footer from '@components/Footer'
import { searchProducts } from '@services/product'
import SelectFields from '@util/SelectFields'
import useForm from '@hooks/useForm'
import usePropertySortFn, {sortOptions} from '@hooks/usePropertySortFn'
import FormInput from '@components/forms/FormInput'
import {initOptions, formValidationRules} from './config'

export default function SearchResultsPage() {
    const history = useHistory()
    const location = useLocation()
    const searchParams = qs.parse(location.search.slice(1))
    const {
        agencyData: { agencyId },
    } = useContext(AppContext)
    const [searchResults, setSearchResults] = useState(null)
    const [modifiedResults, setModifiedResults] = useState(null)
    const [sortName, setSortName] = useState('Ending Soonest')
    const [searchErr, setSearchErr] = useState(null)
    const [loading, setLoading] = useState(false)

    const sortFn = usePropertySortFn(sortName)

    /* 
          Effect:
          Whenever the sort field changes, or the search results change from a re-search, re-sort the properties.
      */
    useEffect(() => {
        if (sortFn && searchResults) {
            setModifiedResults([...searchResults].sort(sortFn))
        }
    }, [sortFn, searchResults])

    const { formData, handleChange, errorMapping, handleSubmit } = useForm(
        searchParams,
        formValidationRules
    )

    const searchProperties = async () => {
        if (loading) {
            return
        }
        setLoading(true)
        try {
            const res = await searchProducts({agencyId, ...formData})
            setSearchResults(res)
            history.replace({ search: qs.stringify(formData, {addQueryPrefix: true}) })
        } catch(err) {
            setSearchErr(err)
        } finally {
            setLoading(false)
        }
    }

    // retrieve new properties on mount
    useEffect(() => {
        searchProperties()
    }, [])

    return (
        <>
            <div>
                <div className="bg-primary-gray pt-12 pb-20">
                    <header className="mx-4">
                        <h1 className="font-bold text-4xl text-center">Search Results</h1>
                        <form
                        noValidate
                        autoComplete="off"
                        className="mt-12 flex justify-center"
                        onSubmit={(e) => handleSubmit(e, searchProperties)}
                        >
                        <div className="flex flex-col lg:flex-row w-full lg:w-auto space-y-3 lg:space-x-3 lg:space-y-0">
                            <FormInput
                            type="text"
                            placeholder="Enter an area"
                            inputName="city"
                            value={formData.city}
                            error={errorMapping.city}
                            handleChange={handleChange}
                            rootClass="lg:w-[350px]"
                            />
                            <div className="flex flex-col md:flex-row space-y-3 md:space-x-3 md:space-y-0">
                            <section className="flex flex-col sm:flex-row justify-between items-center space-y-5 sm:space-x-3 sm:space-y-0">
                                <Select
                                name="minPrice"
                                options={SelectFields.price}
                                error={errorMapping.minPrice}
                                value={formData.minPrice}
                                prependOption="£"
                                initOption={initOptions.minPrice}
                                onChange={handleChange}
                                rootClass="min-w-[150px]"
                                />

                                <Select
                                name="maxPrice"
                                options={SelectFields.price}
                                error={errorMapping.maxPrice}
                                value={formData.maxPrice}
                                prependOption="£"
                                initOption={initOptions.maxPrice}
                                onChange={handleChange}
                                rootClass="min-w-[150px]"
                                />

                                <Select
                                name="bedRooms"
                                options={initOptions.bedRooms}
                                error={errorMapping.bedRooms}
                                value={formData.bedRooms}
                                onChange={handleChange}
                                rootClass="min-w-[150px]"
                                />
                            </section>
                            <button className="self-center sm:self-end md:self-stretch px-8 py-2 md:py-0 bg-secondary-agency text-white rounded-md">
                                Search
                            </button>
                            </div>
                        </div>
                        </form>
                    </header>
                </div>
                <main className="mb-20 max-w-lg-container mx-auto">
                    {searchResults ? (
                        loading ? (
                            <div className="flex flex-col items-center justify-center mt-48">
                                <CircularProgress
                                    className="text-primary"
                                    size="4rem"
                                    color="inherit"
                                />
                                <h2 className="mt-8 font-bold text-xl text-center sm:text-4xl">
                                    Loading properties...
                                </h2>
                            </div>
                        ) : (
                            <>
                                <section className="flex flex-col lg:flex-row justify-between p-4 sm:p-8 space-y-5 lg:space-y-0">
                                    <div>
                                        <h2 className="font-bold text-3xl">
                                            Properties matching your search
                                        </h2>
                                        <p className="mt-4">{searchResults.length} properties</p>
                                    </div>
                                    <Select
                                        label="Sort By:"
                                        options={sortOptions.sortFields.map(({ name }) => name)}
                                        nameValueEq
                                        value={sortName}
                                        onChange={(e) => setSortName(e.target.value)}
                                        rootClass="max-w-[220px]"
                                    />
                                </section>
                                <div className="property-grid grid gap-4 mx-4 sm:mx-4">
                                    {modifiedResults
                                        ? modifiedResults.map((property) => (
                                            <PropertyCard
                                                key={property.productId}
                                                {...property}
                                                publishDate={new Date(property.publishDate).getTime()}
                                                bidEndTime={new Date(property.bidEndTime).getTime()}
                                            />
                                        ))
                                        : searchResults.map((property) => (
                                            <PropertyCard
                                                key={property.productId}
                                                {...property}
                                                publishDate={new Date(property.publishDate).getTime()}
                                                bidEndTime={new Date(property.bidEndTime).getTime()}
                                            />
                                        ))}
                                </div>
                            </>
                        )
                    ) : (
                        <div className="flex flex-col items-center justify-center mt-48">
                            <CircularProgress
                                className="text-primary"
                                size="4rem"
                                color="inherit"
                            />
                            <h2 className="mt-8 font-bold text-xl text-center sm:text-4xl">
                                Loading properties...
                            </h2>
                        </div>
                    )}
                    {searchErr && (
                        <div className="absolute inset-0 flex justify-center items-center">
                            <p className="p-3 rounded-lg border-2 border-error-red text-error-red">
                                {searchErr}
                            </p>
                        </div>
                    )}
                </main>
            </div>
            <Footer />
        </>
    )
}
