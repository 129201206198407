import { useState } from 'react';
import useFormValidator from './useFormValidator';

export default function useForm(initValues, rules, autoValidate) {
    const [formData, setFormData] = useState(initValues);
    const { errorMapping, setErrorMapping, validate, clear } = useFormValidator(Object.fromEntries(Object.keys(initValues).map(name => [name, ''])), rules);

    const handleChange = (e, customChange, postChangeHandler) => {
        let newFormData;
        if (customChange) {
            newFormData = customChange();
        }
        else {
            newFormData = { ...formData, [e.target.name]: (e.target.type === "checkbox" ? e.target.checked : e.target.value) };
        }

        if (autoValidate) {
            validate(newFormData);
        }

        setFormData(newFormData);

        if (postChangeHandler) {
            postChangeHandler(newFormData);
        }
    };

    const reset = () => {
        setFormData(initValues)
        clear()
    }
    
    const handleSubmit = (e, successHandler, preSubmitHandler) => {
        e && e.preventDefault();

        if (preSubmitHandler) {
            preSubmitHandler();
        }

        if (rules) { // No rules means no validation!
            if (validate(formData)) {
                if (successHandler) {
                    successHandler();
                }
                return true;
            }
            else {
                return false;
            }
        }
        else {
            if (successHandler) {
                successHandler();
            }

            return true;
        }
        

    };

    return { formData, setFormData, handleChange, errorMapping, setErrorMapping, handleSubmit, reset };
}