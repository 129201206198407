import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PaymentIcon from '@material-ui/icons/Payment';

import InputButton from '@components/forms/InputButton';
import ModalLayout from '@components/ModalLayout';
import CardSetupForm from '@components/stripe/CardSetupForm';

export default function PropertyPageBidForm({ bidLoading, isBidder, stripePromise, isVerified, isFullyRegistered, bidderStatus, isCardComplete, currentBidPrice, numBids, handleBid, loading, hasBid, isOutbid, postBidError }) {
    const history = useHistory();

    const [bidPrice, setBidPrice] = useState(currentBidPrice);
    const [bidIncrement, setBidIncrement] = useState(1000);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [cardSetupModalOpen, setCardSetupModalOpen] = useState(false);

    const cannotBid = (hasBid && !isOutbid);

    const handleSubmit = (e) => { 
        e.preventDefault();

        if (!cannotBid) {
            setConfirmModalOpen(true);
        }
    };

    useEffect(() => {
        setBidPrice(currentBidPrice);
    }, [currentBidPrice]);

    // Content to be shown in error overlay.
    const errorOverlayContent = useMemo(() => {
        let content;

        if (!isBidder) {
            content = (
                <Link className="py-3 px-5 text-black bg-gray-300 rounded-lg font-medium" to="/bidder/login">Login/Register to Bid</Link>
            );
        }
        else if (!isVerified) {
            content = (
                <p className="py-3 px-5 text-black bg-gray-300 rounded-lg font-medium">Your email is not verified. You must verify your email and continue with the registration process in order to bid.</p>
            );
        }
        else if (bidderStatus === 'signedUp') {
            content = (
                <div className="mx-3 py-3 px-5 text-black bg-gray-300 rounded-lg font-medium">
                    <p className="mb-5">You have logged in but need to fully complete registration via your dashboard.</p>
                    <InputButton 
                    submitText="Complete Registration"
                    type="button"
                    inputStyle="inverse"
                    rootClass="mx-auto"
                    onClick={(e) => history.push('/bidder/dashboard/register')} />
                </div>
            )
        }
        else if (!isFullyRegistered) {
            content = (
                <div className="mx-3 py-3 px-5 text-black bg-gray-300 rounded-lg font-medium">
                    <p>You have completed registration but not have not admin-approved yet. Wait for admin approval to be able to bid.</p>
                </div>
            )
        }
        else if (!isCardComplete) {
            content = (
                <div className="mx-3 py-3 px-5 bg-gray-300 rounded-lg font-medium">
                    <p className="mb-5">You need to add a card to your account in order to bid on properties.</p>
                    <InputButton 
                    submitText="Setup Card"
                    type="button" 
                    inputStyle="inverse"
                    rootClass="mx-auto"
                    onClick={(e) => setCardSetupModalOpen(true)}/>
                </div>
            )
        }
        return content;
    }, [isBidder, isVerified, isCardComplete]);

    const bidDisabled = cannotBid || bidPrice === currentBidPrice || bidLoading; // If the user cannot bid because they have not been outbid, OR because the price hasn't increased enough.

    const [confirmBidCheckboxChecked, setConfirmBidCheckboxChecked] = useState(false)

    const handleConfirmCheckbox = (e) => {
        setConfirmBidCheckboxChecked(e.target.checked)
    }

    const handleConfirm = () => {
        if (!bidLoading) {
            handleBid({ bidPrice }); 
            setConfirmModalOpen(false);
        }
    }

    const handleCancel = () => {
        setConfirmModalOpen(false)
    }

    return (
        <section className="relative p-3">
            {
                errorOverlayContent && (
                    <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-75 z-20">
                        {errorOverlayContent}
                    </div>
                )
            }
            <h2 className="text-center font-medium text-xl">Enter Bid</h2>
            <form onSubmit={(e) => handleSubmit(e)}>
                <div>
                    <div className="relative">
                        <div className="flex items-center space-x-3">
                            <button 
                            type="button"
                            className={`flex justify-center items-center rounded-full p-1 shadow-lg ${cannotBid || currentBidPrice === bidPrice || bidPrice - bidIncrement < currentBidPrice ? 'bg-dark-gray pointer-events-none' : 'bg-primary-agency'}`}
                            onClick={(e) => (!cannotBid || currentBidPrice !== bidPrice || bidPrice - bidIncrement > currentBidPrice) && setBidPrice((bidPrice) => bidPrice - bidIncrement)}
                            disabled={cannotBid}>
                                <RemoveIcon className={`text-white ${cannotBid || currentBidPrice === bidPrice || bidPrice - bidIncrement < currentBidPrice ? 'text-gray-600' : ''}`} />
                            </button>
                            <input 
                            id="bidPrice"
                            name="bidPrice"
                            type="text"
                            readOnly
                            aria-readonly={true}
                            className={`form-input min-w-0 text-center text-3xl hover:border-dark-gray ${cannotBid || postBidError ? 'form-input-error' : ''}`}
                            placeholder="Enter a price"
                            value={new Intl.NumberFormat('en-GB').format(bidPrice)}
                            disabled={cannotBid} />
                            <button 
                            type="button"
                            className={`flex justify-center items-center rounded-full p-1 shadow-lg
                            ${cannotBid ? 'bg-dark-gray pointer-events-none' : 'bg-primary-agency'}`}
                            onClick={(e) => !cannotBid && setBidPrice((bidPrice) => bidPrice + bidIncrement)}
                            disabled={cannotBid}>
                                <AddIcon className={`text-white ${cannotBid && 'text-gray-600'}`} />
                            </button>
                        </div>
                        <div className="mt-5">
                            <h3 className="text-2xl font-semibold font-primary text-center mb-3">Bid Increment Amount</h3>
                            <div className="flex justify-center space-x-3 flex-wrap">
                                <InputButton 
                                submitText="£1,000" 
                                type="button"
                                inputStyle={bidIncrement !== 1000 ? 'inverse-primary' : 'primary'}
                                nonClickable={bidIncrement === 1000} 
                                onClick={(e) => setBidIncrement(1000)}
                                disabled={cannotBid}
                                rootClass="text-sm" />
                                <InputButton 
                                submitText="£2,000" 
                                type="button"
                                inputStyle={bidIncrement !== 2000 ? 'inverse-primary' : 'primary'}
                                nonClickable={bidIncrement === 2000}
                                onClick={(e) => setBidIncrement(2000)}
                                disabled={cannotBid} />
                                <InputButton 
                                submitText="£5,000" 
                                type="button"
                                inputStyle={bidIncrement !== 5000 ? 'inverse-primary' : 'primary'}
                                nonClickable={bidIncrement === 5000}
                                onClick={(e) => setBidIncrement(5000)} 
                                disabled={cannotBid} 
                                rootClass="mt-3 property-page-bid-sm:mt-0 sm:mt-3 property-page-bid:mt-0" />
                            </div>
                        </div>
                        {/* <div className="relative">
                            {((hasBid && !isOutbid) || postBidError || currentBidPrice === bidPrice) && <span className="form-error-text absolute w-full mt-4 text-center">
                                {postBidError || (hasBid && !isOutbid && "You can't bid again until you've been outbid") || (currentBidPrice === bidPrice && !loading && "Your bid must be higher than the current highest bid")}
                                </span>}
                        </div> */}
                    </div>
                    <button
                    className={`mx-auto py-3 mt-16 w-full font-bold text-3xl ${bidDisabled ? 'text-gray-500 bg-gray-300 pointer-events-none' : 'text-white bg-primary-agency-dark'}`} 
                    disabled={bidDisabled}>Place Bid</button>
                </div>
            </form>
            <ModalLayout
            open={confirmModalOpen}
            withHeader
            icon={<CheckCircleIcon />}
            title="Confirm Bid"
            handleClose={() => setConfirmModalOpen(false)}
            modalBodyClass="w-4/5 sm:w-1/3">
                <div className="p-6">
                    <p className="font-primary">Please confirm your bid of <strong>£{bidPrice}</strong>. <br /><br /><span className="text-primary font-semibold">Once you bid, understand that you cannot revert it and your card will be automatically charged if you win.</span></p>
                    <div className="flex items-center mt-3">
                        <input id="bidConfirmCheckbox" type="checkbox" className="inline-block w-5 h-5" checked={confirmBidCheckboxChecked} onChange={handleConfirmCheckbox} />
                        <label className="form-input-label ml-3 mb-0 text-black text-base" htmlFor="bidConfirmCheckbox">I have read and agreed to the Auction Terms and Conditions</label>
                    </div>
                    <div className="flex justify-center space-x-5 mt-6">
                        <InputButton type="button" submitText="Confirm" disabled={!confirmBidCheckboxChecked} onClick={handleConfirm} />
                        <InputButton type="button" submitText="Cancel" inputStyle="inverse" onClick={handleCancel} />
                    </div>
                </div>
            </ModalLayout>
            <Elements stripe={stripePromise}>
                <ModalLayout
                open={cardSetupModalOpen}
                withHeader
                icon={<PaymentIcon />}
                title="Card Setup"
                handleClose={() => setCardSetupModalOpen(false)}>
                    <div className="rounded-lg mx-2 bg-white outline-none max-w-card-form">
                        <CardSetupForm formClass="px-5 pb-5 w-full" handleSuccess={() => setCardSetupModalOpen(false)} />
                    </div>
                </ModalLayout>
            </Elements>
        </section>
    )
}