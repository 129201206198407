import React, { useContext, useState } from 'react';
import LockIcon from '@material-ui/icons/Lock';

import { bidder } from '@api/api';

import AppContext from '@contexts/AppContext';

import InputButton from '@components/forms/InputButton';

export default function BidderEmailNotVerified({ history }) {
    const { addNotification, agencyData: { agencyId } } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(null);
    
    const handleResendLink = (e) => { // TODO Still need to handle resend link (API call)!
        if (history.location.state?.email && !loading) {
            setLoading(true);
            bidder.post({
                endpoint: 'resendVerifyEmail',
                data: {
                    email: history.location.state.email,
                    agencyId
                },
                handleSuccess: (data) => { console.log(data); addNotification('Email verification link resent!') },
                handleFail: (err) => setErr(err),
                handleComplete: () => setLoading(false)
            });
        }
    }
    return (
        <main>
            <div className="fixed register-bg bg-center bg-fixed top-0 left-0 right-0 bottom-0 -z-1" />
            <div className="flex justify-center pt-24">
                <div className="w-1/3 min-w-mobile rounded-lg bg-white bg-opacity-90">
                    <div className="rounded-header flex items-center">
                        <LockIcon fontSize="large" />
                        <h1 className="ml-5">Account Not Verified</h1>
                    </div>
                    <div className="p-6">
                        <p className="font-secondary text-lg mb-6">You may not login until your account is verified via email. Please check your email/spam folder for account verification.</p>
                        <p className="font-secondary font-semibold mb-5">Don't see the email? Click the button below to resend the verification link.</p>
                        {
                            err && (<p className="form-error-text my-3">{err}</p>)
                        }
                        <InputButton 
                        submitText="Resend Verification Link"
                        rounded="lg" 
                        rootClass="mx-auto" 
                        loading={loading}
                        onClick={handleResendLink} />
                    </div>

                </div>
            </div>
        </main>
    )
}