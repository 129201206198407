import React, { createContext, useState } from 'react';

const BidderRegistrationContext = createContext();

export const BidderRegistrationContextProvider = ({ children }) => {
    const [completeProfileData, setCompleteProfileData] = useState(null);
    const [completeIDData, setCompleteIDData] = useState(null);

    return (
        <BidderRegistrationContext.Provider value={{
            completeProfileData,
            setCompleteProfileData,
            completeIDData,
            setCompleteIDData
        }}>
            { children }
        </BidderRegistrationContext.Provider>
    )
}
export default BidderRegistrationContext;
