import React from 'react';

import BaseTimer from './BaseTimer';

export default function PropertyPageTimer({ rootClass, ...baseProps }) {
    return (
        <BaseTimer {...baseProps}>
        {
            (timeLeft) => (
                <time className={`flex justify-center items-center space-x-3 sm:space-x-5 text-white text-semibold px-8 ${rootClass ?? ''}`}>
                    <div className="flex flex-col items-center">
                        <span className="text-lg sm:text-4xl">{timeLeft.days}</span>
                        <span>Days</span>
                    </div>
                    <div>:</div>
                    <div className="flex flex-col items-center">
                        <span className="text-lg sm:text-4xl">{timeLeft.hours}</span>
                        <span>Hours</span>
                    </div>
                    <div>:</div>
                    <div className="flex flex-col items-center">
                        <span className="text-lg sm:text-4xl">{timeLeft.minutes}</span>
                        <span>Minutes</span>
                    </div>
                    <div>:</div>
                    <div className="flex flex-col items-center">
                        <span className="text-lg sm:text-4xl">{timeLeft.seconds}</span>
                        <span>Seconds</span>
                    </div>
                </time>
            )
        }
        </BaseTimer>
    )
}