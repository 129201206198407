import { useState } from 'react';

/*
    Hook for validating forms. Provides method for validation given
    names of inputs on form as well as rules for such inputs

    Params
    @formNames - List of names of inputs for the form
    @rules - Object of name to a "rule" (i.e. an object of predicate function as well as error for that rule.)
*/

export default function useFormValidator(formNames, rules) {
    const [errorMapping, setErrorMapping] = useState(formNames);
    

    const validate = (formData) => {
        let hasError = false;
        let newErrorMapping = {...formNames};

        Object.keys(newErrorMapping).forEach((name) => {
            let inputRules = rules[name];

            if (inputRules) {
                for (let rule of inputRules) {
                    if (!rule.test(formData[name], formData)) {
                        newErrorMapping[name] = rule.errorMsg;
                        hasError = true;
                        break;
                    }
                }
            }

        })

        setErrorMapping(newErrorMapping);
        return !hasError;
    };

    const clear = () => {
        setErrorMapping(formNames)
    }

    return { errorMapping, setErrorMapping, validate, clear };
}