import React, { useState } from 'react';

import BidderDashboardHomeSolicitorForm from './BidderDashboardHomeSolicitorForm';
import InputButton from '@components/forms/InputButton';

export default function BidderDashboardHomeSolicitorSection({ solicitorInfo }) {
    const [isEditing, setIsEditing] = useState(false);

    const { solicitorName, solicitorEmail, solicitorFirm, solicitorPhoneNumber } = solicitorInfo;

    return (
        <>
            <header className="flex flex-col sm-480:flex-row items-start space-y-3 sm-480:space-y-0 sm-480:items-center justify-between">
                <h2 className="font-semibold text-2xl">Solicitor Details</h2>
                <InputButton 
                submitText={isEditing ? 'Cancel Edit' : 'Edit Details'}
                onClick={() => setIsEditing((isEditing) => !isEditing)} />
            </header>
            {
                isEditing ? (
                    <BidderDashboardHomeSolicitorForm solicitorInfo={solicitorInfo} setIsEditing={setIsEditing} />
                ) : (
                    <dl className="mt-5 space-y-5">
                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-5">
                            <dt className="sm:w-1/3 sm:flex-none text-primary-agency font-semibold text-lg">Solicitor Name</dt>
                            <dd>{solicitorName || 'N/A'}</dd>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-5">
                            <dt className="sm:w-1/3 sm:flex-none text-primary-agency font-semibold text-lg">Solicitor Email</dt>
                            <dd>{solicitorEmail || 'N/A'}</dd>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-5">
                            <dt className="sm:w-1/3 sm:flex-none text-primary-agency font-semibold text-lg">Solicitor Firm</dt>
                            <dd>{solicitorFirm || 'N/A'}</dd>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-5">
                            <dt className="sm:w-1/3 sm:flex-none text-primary-agency font-semibold text-lg">Solicitor Phone Number</dt>
                            <dd>{solicitorPhoneNumber || 'N/A'}</dd>
                        </div>
                    </dl>
                )
            }
        </>
    )
}