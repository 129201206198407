import axios from 'axios';
import { API_DOMAIN } from './site-constants';

const axiosInstance = axios.create({
    baseURL: API_DOMAIN
});

// Ensures that with every request to the API, we're sending credentials.
axiosInstance.defaults.withCredentials = true;

export default axiosInstance;