import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

import BidderDashboardHomeSolicitorSection from './BidderDashboardHomeSolicitorSection';
import BidderDashboardHomePreferencesSection from './BidderDashboardHomePreferencesSection';
import BidderDashboardHomePasswordSection from './BidderDashboardHomePasswordSection';

export default function BidderDashboardHome() {
    const { addNotification } = useContext(AppContext);
    const { userData } = useContext(AuthContext);
    const {
        bidderToken,
        bidderSecret,
        bidderStatus,
        firstName,
        middleNames,
        lastName,
        email,
        houseNumber,
        street,
        town,
        region,
        postCode,
        country,
        mobilePhoneNumber,
        landlinePhoneNumber,
        solicitorFirm,
        solicitorEmail,
        solicitorName,
        solicitorPhoneNumber,
        newsletter,
        isCardComplete
    } = userData ?? {};

    const bidderInstructionContent = useMemo(() => {
        let content;

        switch (bidderStatus) {
            case 'Pending':
                content = (
                    <p>Your account is pending approval - won't be long now.</p>
                );
                break;
            case 'signedUp':
                content = (
                    <p>
                        Please complete your registration to start bidding!
                        &nbsp;<Link to="/bidder/dashboard/register" className="font-bold hover:underline">Click here to complete!</Link>
                    </p>
                );
                break;
            case 'Approved':
                if (!isCardComplete) {
                    content = (
                        <p>
                            Please add a card to start bidding!
                            &nbsp;<Link to="/bidder/dashboard/card-setup" className="font-bold hover:underline">Click here to add a card!</Link>
                        </p>
                    );
                }
                break;
            default:
                content = null;
                break;
        }

        return content;
    }, [bidderStatus, isCardComplete]);

    const bidderStatusContent = useMemo(() => {
        switch (bidderStatus) {
            case 'Pending':
                return 'Account Pending';
            case 'signedUp':
                return 'Registration Incomplete';
            case 'Approved':
                return 'Account Approved';
            default:
                break;
        }
    }, [bidderStatus]);

    return (
        <main>
            <header className="flex flex-col lg:flex-row justify-between items-center space-y-3 lg:space-y-0 lg:space-x-3 mb-5">
                <h1 className="font-primary-agency font-bold break-words text-center sm:text-left text-3xl lg:text-4xl xl:text-4xl">Welcome to your Dashboard</h1>
                <p className="p-3 rounded-lg bg-primary-agency text-white font-bold">Your Status: <span className="font-medium">{bidderStatusContent}</span></p>
            </header>
            {
                bidderInstructionContent && (
                    <section className="flex flex-col justify-center max-w-3xl w-fit p-3 rounded-lg bg-primary-agency text-white">
                        {bidderInstructionContent}
                    </section>
                )
            }
            <div className="flex flex-col md-900:flex-row mt-3 space-y-3 md-900:space-y-0 md-900:space-x-3">
                <section className="w-full p-5 pt-3 border-2 border-primary-agency">
                    <header>
                        <h2 className="font-semibold text-2xl">My Profile</h2>
                    </header>
                    <p>(Please contact support if you wish to update details)</p>
                    <dl className="mt-5 space-y-3">
                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-5">
                            <dt className="w-full sm:w-1/3 text-primary-agency font-semibold text-lg">Full Name</dt>
                            <dd>{firstName} {middleNames} {lastName}</dd>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-5">
                            <dt className="w-full sm:w-1/3 text-primary-agency font-semibold text-lg">Email</dt>
                            <dd>{email}</dd>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-5">
                            <dt className="w-full sm:w-1/3 text-primary-agency font-semibold text-lg">Address</dt>
                            {
                                houseNumber && street ? <dd>{houseNumber} {street}</dd> : <dd>N/A (Complete registration)</dd>
                            }

                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-5">
                            <dt className="w-full sm:w-1/3 text-primary-agency font-semibold text-lg">Town</dt>
                            <dd>{town || 'N/A (Complete registration)'}</dd>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-5">
                            <dt className="w-full sm:w-1/3 text-primary-agency font-semibold text-lg">Region</dt>
                            <dd>{region || 'N/A (Complete registration)'}</dd>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-5">
                            <dt className="w-full sm:w-1/3 text-primary-agency font-semibold text-lg">Postcode</dt>
                            <dd>{postCode || 'N/A (Complete registration)'}</dd>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-5">
                            <dt className="w-full sm:w-1/3 text-primary-agency font-semibold text-lg">Country</dt>
                            <dd>{country || 'N/A (Complete registration)'}</dd>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-5">
                            <dt className="w-full sm:w-1/3 text-primary-agency font-semibold text-lg">Mobile Number</dt>
                            <dd>{mobilePhoneNumber}</dd>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-5">
                            <dt className="w-full sm:w-1/3 text-primary-agency font-semibold text-lg">Landline Number</dt>
                            <dd>{landlinePhoneNumber || 'N/A'}</dd>
                        </div>
                    </dl>
                </section>
                <section className="w-full space-y-5">
                    <section className="w-full p-3 border-2 border-primary-agency">
                        <BidderDashboardHomeSolicitorSection
                            solicitorInfo={{
                                solicitorName,
                                solicitorEmail,
                                solicitorFirm,
                                solicitorPhoneNumber
                            }} />
                    </section>
                    <section className="w-full p-3 border-2 border-primary-agency">
                        <BidderDashboardHomePreferencesSection preferences={{ newsletter: newsletter ? 'yes' : 'no' }} />
                    </section>
                    <section className="w-full p-3 border-2 border-primary-agency">
                        <BidderDashboardHomePasswordSection
                            bidderToken={bidderToken}
                            bidderSecret={bidderSecret}
                            addNotification={addNotification} />
                    </section>
                </section>

            </div>
        </main>
    )
}