import React from 'react';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

export default function ErrorPage({ location }) {
    const { errorContext } = location.state;

    return (
        <main className="fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-6xl text-air-red">
            <ReportProblemIcon color="inherit" fontSize="inherit" />
            <h1 className="font-semibold text-2xl text-black">Site error: {errorContext}</h1>
        </main>
    )
};