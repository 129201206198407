import React from 'react';

export default function CheckboxFormInput({ label, inputName, value, error, errorClass, handleChange, rootClass }) {
    return (
        <div className={`relative ${rootClass ?? ''}`}>
            <input 
            id={inputName}
            type="checkbox"
            name={inputName}
            checked={value}
            onChange={handleChange}
            className="w-5 h-5" />
            <label className="form-input-label ml-3 mb-0" htmlFor={inputName}>{label}</label>
            {
                error && (
                    <p className={`absolute w-full top-full form-error-text ${errorClass ?? ''}`}>{error}</p>
                )
            }
        </div>
    )
};