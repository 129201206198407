import React, { useState } from 'react';

import BidderDashboardCompleteProfile from './BidderDashboardCompleteProfile';
import BidderDashboardCompleteID from './BidderDashboardCompleteID';
import BidderDashboardRequestApproval from './BidderDashboardRequestApproval';
import FormStepper from '@components/forms/FormStepper';

export default function BidderDashboardCompleteRegistration() {
    const [activeStep, setActiveStep] = useState(0);

    return (
        <div>
            <h1 className="dashboard-header mb-1 text-center">{activeStep === 2 ? 'Almost there...' : 'Complete Registration'}</h1>
            {
                activeStep !== 2 && (
                    <h2 className="font-secondary font-bold text-primary-agency text-sm text-center mb-5">Complete the following steps in order to complete registration. 
                    Upon approval, you can access all features of this site.</h2>
                )
            }
                <div className="hidden sm:block">
                    <FormStepper 
                    steps={[
                        'Complete Profile',
                        'Upload Identification',
                        'Request Approval'
                    ]}
                    activeStep={activeStep}
                    alternativeLabel
                    />
                </div>
                <div className="flex justify-center sm:hidden">
                    <FormStepper 
                    steps={[
                        'Complete Profile',
                        'Upload Identification',
                        'Request Approval'
                    ]}
                    activeStep={activeStep}
                    orientation="vertical"
                    />
                </div>
                {
                    activeStep === 0
                    ?
                    (
                        <BidderDashboardCompleteProfile setActiveStep={setActiveStep} />
                    )
                    :
                    (
                        activeStep === 1
                        ?
                        (
                            <BidderDashboardCompleteID setActiveStep={setActiveStep} />
                        )
                        :
                        (
                            activeStep === 2 && 
                            (
                                <BidderDashboardRequestApproval setActiveStep={setActiveStep} />
                            )
                        )
                    )
                }
        </div>
    )
}