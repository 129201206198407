import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';

import {signUpBidder} from '@services/bidder'
import ValidationTypes from '@util/ValidationTypes';
import GoogleReCAPTCHA from '@components/forms/GoogleReCAPTCH';

import AppContext from '@contexts/AppContext';

import useForm from '@hooks/useForm';

import InputButton from '@components/forms/InputButton';
import FormInput from '@components/forms/FormInput';
import CheckboxFormInput from '@components/forms/CheckboxFormInput';

export default function BidderRegister() {
    const history = useHistory();
    const { agencyData: { agencyId } } = useContext(AppContext);

    const registerFormRules = {
        firstName: [ValidationTypes.requiredString],
        lastName: [ValidationTypes.requiredString],
        email: [ValidationTypes.requiredString, ValidationTypes.validEmail],
        password: [ValidationTypes.requiredString, {
            test: (value) => value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
            errorMsg: "Invalid password format"
        }],
        password2: [ValidationTypes.requiredString, {
            test: (value, formData) => value === formData.password,
            errorMsg: "Passwords do not match"
        }],
        mobilePhoneNumber: [ValidationTypes.requiredString],
        terms: [{
            test: (value) => value,
            errorMsg: 'You must accept terms and conditions to continue'
        }],
        newsletter: [ValidationTypes.requiredString],
        recaptcha: [ValidationTypes.requiredString],
    };

    const { formData, handleChange, handleSubmit, errorMapping } = useForm({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        password2: '',
        mobilePhoneNumber: '',
        landlinePhoneNumber: '',    
        terms: false,
        newsletter: '',
        recaptcha: null
    }, registerFormRules);

    const [loading, setLoading] = useState('');
    const [registerError, setRegisterError] = useState('');
    const [showPasswordHelper, setShowPasswordHelper] = useState(false);

    const submitSuccessHandler = async () => {
        setLoading(true);
        const {recaptcha, ...rest} = formData
        const res = await signUpBidder({
            ...rest,
            newsletter: formData.newsletter === 'yes',
            agencyId,
            bidderType: "individual",
            'g-recaptcha-response': recaptcha
        })
        setLoading(false);
        if (res?.status) {
            history.push('/bidder/verify-email', { email: formData.email });
        } else {
            setRegisterError(res?.message);
        }
    }

    return (
        <div>
            <div className="fixed register-bg bg-center bg-fixed top-0 left-0 right-0 bottom-0 -z-1"></div>
            <div className="w-full flex justify-center pt-24 pb-10">
                <form
                onSubmit={(e) => handleSubmit(e, submitSuccessHandler)}
                className="w-5/6 sm:w-3/4 lg:w-1/2 min-w-mobile pb-5 sm:pb-10 rounded-lg bg-white bg-opacity-90">
                    <div className="rounded-lg rounded-b-none bg-primary-agency text-center p-5 sm:px-10">
                        <h1 className="font-primary font-semibold text-3xl text-white">Create a Bidder Account</h1>
                        <p className="mt-2 text-secondary text-sm font-secondary">
                            Create an account to be given access to more property information as well as the ability
                            to watch for and bid on properties.
                        </p>
                    </div>
                    <div className="px-5 sm:px-10 mt-5">
                        <div className="block md:flex md:space-x-5">
                            <FormInput
                            type="text"
                            label="First Name"
                            placeholder="Enter your first name"
                            inputName="firstName"
                            value={formData.firstName}
                            error={errorMapping.firstName}
                            handleChange={handleChange} />
                            <FormInput
                            type="text"
                            label="Last Name"
                            placeholder="Enter your last name"
                            inputName="lastName"
                            value={formData.lastName}
                            error={errorMapping.lastName}
                            handleChange={handleChange}
                            rootClass="mt-8 md:mt-0" />
                        </div>
                        <FormInput 
                        type="text"
                        label="Email"
                        inputName="email"
                        placeholder="Enter a valid email address"
                        value={formData.email}
                        error={errorMapping.email} 
                        handleChange={handleChange}
                        rootClass="mt-8" />
                        <div className="block xl:flex xl:space-x-5 mt-8">
                            <FormInput 
                            type="tel"
                            label="Mobile Phone"
                            placeholder="Enter your mobile phone number"
                            inputName="mobilePhoneNumber"
                            value={formData.mobilePhoneNumber}
                            error={errorMapping.mobilePhoneNumber} 
                            handleChange={handleChange} />
                            <FormInput 
                            type="tel"
                            label="Landline Phone (Optional)"
                            placeholder="Enter your landline phone number"
                            inputName="landlinePhoneNumber"
                            value={formData.landlinePhoneNumber}
                            error={errorMapping.landlinePhoneNumber} 
                            handleChange={handleChange} 
                            rootClass="mt-8 xl:mt-0" />
                        </div>
                        <div className="block md:flex md:space-x-5 mt-8">
                            <div className="relative w-full">
                                <FormInput 
                                type="password"
                                label="Password"
                                placeholder="Enter a password"
                                inputName="password"
                                value={formData.password}
                                error={errorMapping.password} 
                                handleChange={handleChange} />
                                {
                                    showPasswordHelper 
                                    ?
                                    (
                                        <CancelIcon className="absolute top-0 right-0 mr-3 cursor-pointer" onClick={(e) => setShowPasswordHelper(false) }/>
                                    )
                                    :
                                    (
                                        <HelpIcon className="absolute top-0 right-0 mr-3 cursor-pointer" onClick={(e) => setShowPasswordHelper(true) }/>
                                    )
                                }
                                <div className={`helper-text z-40 bottom-full right-0 mr-3 w-64 ${showPasswordHelper ? 'opacity-100' : ''} pointer-events-none`}>
                                    Password must be at least 8 characters and must contain the following:
                                    <ul className="mt-3 list-disc ml-3">
                                        <li>At least 1 uppercase letter</li>
                                        <li>At least 1 lowercase letter</li>
                                        <li>At least 1 digit</li>
                                    </ul>
                                </div>
                            </div>
                            <FormInput 
                            type="password"
                            label="Repeat Password"
                            placeholder="Repeat your password"
                            inputName="password2"
                            value={formData.password2}
                            error={errorMapping.password2} 
                            handleChange={handleChange} 
                            rootClass="mt-8 md:mt-0" />
                        </div>
                        <GoogleReCAPTCHA value={formData.recaptcha} inputName="recaptcha" handleChange={handleChange} error={errorMapping.recaptcha} rootClass="mt-8" />
                        <p className="font-primary font-semibold text-lg text-center mb-1 mt-8">I would like to receive email updates on available auction properties.</p>
                        <div className="relative flex justify-center">
                            <div>
                                <input 
                                className="form-input flex-grow-0 w-auto"
                                id="newsYes"
                                type="radio"
                                name="newsletter"
                                value="yes"
                                checked={formData.newsletter === 'yes'}
                                onChange={handleChange} />
                                <label className="form-input-label ml-2" htmlFor="newsYes">Yes</label>
                            </div>
                            <div className="ml-5">
                                <input 
                                className="form-input flex-grow-0 w-auto"
                                id="newsNo"
                                type="radio"
                                name="newsletter"
                                value="no"
                                checked={formData.newsletter === 'no'}
                                onChange={handleChange} />
                                <label className="form-input-label ml-2" htmlFor="newsNo">No</label>
                            </div>
                            {
                                errorMapping.newsletter && (
                                    <p className="absolute top-full w-full form-error-text text-center">{errorMapping.newsletter}</p>
                                )
                            }
                        </div>
                        <CheckboxFormInput 
                        label={<>I accept the <a href="#">Terms</a> and <a href="#">Conditions</a>.</>}
                        inputName="terms"
                        value={formData.terms}
                        error={errorMapping.terms}
                        handleChange={handleChange}
                        rootClass="flex justify-center items-center mt-8"
                        errorClass="text-center"
                        />
                        {
                            registerError && 
                            (
                                <div className="mt-8 text-center">
                                    <span className="font-primary font-black text-red-500 text-lg">{registerError}</span>
                                </div>
                            )
                        }
                        <div className="flex justify-center mt-10">
                            <InputButton 
                            type="submit"
                            submitText="Register"
                            loading={loading} />
                        </div>
                    </div>
                </form>
            </div>
            
        </div>
    )

}