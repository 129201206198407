import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import { format, parse, parseISO } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

import axios from '@util/axios';
import SelectFields from '@util/SelectFields';

import useForm from '@hooks/useForm';

import AgentDashboardTableActionBar from './AgentDashboardTableActionBar';
import FormInput from '@components/forms/FormInput';
import InputButton from '@components/forms/InputButton';
import Select from '@components/forms/Select';

function MultiSelectFilter({ column: { filterValue, setFilter, id } }) {
    const [options, setOptions] = useState(filterValue ? Object.fromEntries(filterValue.map((option) => [option, true])) : {});

    console.log(options);

    let header = id === 'productStatus' ? 'Status' : 
                (id === 'assetClass' ? 'Class' : 'Type');

    let filterOptions = id === 'productStatus' ? ['Draft', 'Pre-Market', 'Scheduled', 'Scheduled Pre-Market', 'Live'] :
                        (id === 'assetClass' ? ['Residential', 'Commercial'] : ['Terraced', 'Semi-Detached', 'Detached', 'Bungalow', 'Flat', 'Land', 'Other']);
    
    const handleSubmit = (e) => {
        e.preventDefault();

        if (Object.values(options).some((option) => option)) {
            setFilter(Object.entries(options).filter((option) => option[1]).map((option) => option[0]));
        }
        else {
            setFilter(null);
        }
    }

    const handleChange = (e, option) => {
        let checked = e.target.checked;

        setOptions((options) => {
            return {...options, [option]: checked};
        })
    }

    return (
        <div className="absolute top-0 left-full mt-5 ml-3 bg-white rounded-lg shadow-lg z-10">
            <div className="relative flex justify-between items-center rounded-header py-3 px-6 pr-4">
                <h3 className="text-xl">Filter {header}</h3>
                <div 
                className="flex items-center tooltip__container rounded-lg p-1 hover:bg-hover-light-primary text-white cursor-pointer"
                onClick={(e) => setFilter(filterValue !== undefined ? filterValue : null)}
                >
                    <span className="tooltip__tooltip-right text-base mt-2">Exit without saving</span>
                    <ClearIcon />
                </div>
            </div>
            <form className="p-3 px-6 w-72 bg-white rounded-lg" onSubmit={handleSubmit}>
            {
                filterOptions.map((option) => (
                    <div key={option} className="flex items-center mt-3">
                        <input
                        id={option}
                        type="checkbox"
                        checked={options[option]}
                        onChange={(e) => handleChange(e, option)} />
                        <label className="form-input-label ml-3 mb-0 text-black text-base" htmlFor={option}>{option}</label>
                    </div>)
                )
            }
            <div className="flex justify-center mt-5">
                <InputButton 
                type="button"
                submitText="Turn Off" 
                inputStyle="inverse"
                disabled={!filterValue}
                onClick={(e) => setFilter(null)} />
                <InputButton 
                type="submit"
                submitText="Done" 
                disabled={!Object.keys(options).length}
                rootClass="ml-5" />
            </div>
            </form>

        </div>
    )
}

function DateRangeFilter({ column: { filterValue, setFilter, id } }) {
    let header = id === 'createdDate' ? 'Created Date' : 'Publish Date';

    const validationRules = {
        min: [{
            test: (value, formData) => !value || !formData.max || value.getTime() <= formData.max.getTime(),
            errorMsg: "Min date must come before or be the same as max date"
        }]
    };

    const { formData, errorMapping, handleChange, handleSubmit } = useForm({
        min: filterValue ? filterValue[0] : null,
        max: filterValue ? filterValue[1] : null
    }, validationRules, true);

    const handleSubmitSuccess = () => {
        if (formData.min || formData.max) {
            setFilter([formData.min, formData.max]);
        }
        else {
            setFilter(null);
        }
    };

    return (
        <div className="absolute top-0 right-0 mt-5 mr-10 bg-white rounded-lg shadow-lg">
            <div className="relative flex justify-between items-center rounded-header py-3 px-4">
                <h3 className="text-xl">Filter {header}</h3>
                <div 
                className="flex items-center tooltip__container rounded-lg p-1 hover:bg-hover-light-primary text-white cursor-pointer"
                onClick={(e) => setFilter(filterValue !== undefined ? filterValue : null)}
                >
                    <span className="tooltip__tooltip-right text-base mt-2">Exit without saving</span>
                    <ClearIcon />
                </div>
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <form className="px-6 py-3 w-80" onSubmit={(e) => handleSubmit(e, handleSubmitSuccess)}>
                    <p className="font-secondary font-bold text-primary text-sm mb-2">You can omit min or max values for single value filtering.</p>
                    <div className="relative">
                        <div>
                            <label className="form-input-label text-black" htmlFor="minDate">Min Date</label>
                            <KeyboardDateTimePicker 
                            className="w-full font-primary"
                            id="minDate"
                            autoOk
                            variant="inline" 
                            format="dd/MM/yyyy HH:mm:ss"
                            ampm={false}
                            value={formData.min}
                            onChange={(date) => handleChange(null, () => { return {...formData, min: date} })} />
                        </div>
                        <div className="mt-3">
                            <label className="form-input-label text-black" htmlFor="maxDate">Max Date</label>
                            <KeyboardDateTimePicker 
                            className="w-full font-primary"
                            id="maxDate"
                            autoOk
                            variant="inline" 
                            format="dd/MM/yyyy HH:mm:ss"
                            ampm={false}
                            value={formData.max}
                            onChange={(date) => handleChange(null, () => { return {...formData, max: date} })}
                            />   
                        </div>
                    {
                        errorMapping.min && <span className="inline-block mt-3 form-error-text text-sm text-center leading-tight">{errorMapping.min}</span>
                    }
                    </div>
                   
                    <div className="flex justify-center mt-6">
                        <InputButton 
                        type="button"
                        submitText="Turn Off" 
                        inputStyle="inverse"
                        disabled={!filterValue}
                        onClick={(e) => setFilter(null)} />
                        <InputButton 
                        type="submit"
                        submitText="Done"
                        disabled={errorMapping.min || (!formData.min && !formData.max)}
                        rootClass="ml-5" />
                    </div>
                </form>
                
            </MuiPickersUtilsProvider>
        </div>
    )
}

function PriceFilter({ column: { filterValue, setFilter, id } }) {
    const validationRules = {
        min: [{
            test: (value, formData) => parseInt(value) <= parseInt(formData.max),
            errorMsg: "Min price must be less than or equal to max price"
        }]
    };

    const { formData, errorMapping, handleChange, handleSubmit } = useForm({
        min: filterValue ? filterValue[0] : '50000',
        max: filterValue ? filterValue[1] : '10000000'
    }, validationRules, true);

    const handleReset = (e) => {
        setFilter(null);
    }

    const handleSubmitSuccess = () => {
        setFilter([parseInt(formData.min), parseInt(formData.max)]);
    }

    return (
        <div className="absolute top-0 right-0 mt-5 mr-10 bg-white rounded-lg shadow-lg">
            <div className="relative flex justify-between items-center rounded-header py-3 px-6 pr-4">
                <h3 className="text-xl">Filter {id === 'bidStartPrice' ? 'Bid Start Price' : 'Reserve Price'}</h3>
                <div 
                className="flex items-center tooltip__container rounded-lg p-1 hover:bg-hover-light-primary text-white cursor-pointer"
                onClick={(e) => setFilter(filterValue !== undefined ? filterValue : null)}
                >
                    <span className="tooltip__tooltip-right text-base mt-2">Exit without saving</span>
                    <ClearIcon />
                </div>
            </div>
            <form className="p-6 w-80" onSubmit={(e) => handleSubmit(e, handleSubmitSuccess)}>
                <p className="font-secondary font-bold text-primary text-sm mb-2">You can omit min or max values for single value filtering.</p>
                <div className="relative">
                    <Select 
                    label="Min Price"
                    name="min"
                    value={formData.min}
                    options={SelectFields.price}
                    prependOption={'£'}
                    onChange={handleChange} />
                    <Select 
                    label="Max Price"
                    name="max"
                    value={formData.max}
                    options={SelectFields.price}
                    prependOption={'£'}
                    onChange={handleChange}
                    rootClass="mt-5" />
                    {
                        errorMapping.min && <span className="inline-block mt-3 form-error-text text-sm text-center">{errorMapping.min}</span>
                    }
                </div>
                <div className="flex justify-center mt-6">
                    <InputButton 
                    type="button"
                    submitText="Turn Off" 
                    inputStyle="inverse"
                    disabled={!filterValue}
                    onClick={handleReset} />
                    <InputButton 
                    type="submit"
                    submitText="Done"
                    rootClass="ml-5"
                    disabled={errorMapping.min} />
                </div>
            </form>
        </div>
    )
}
function SliderFilter({ column: { filterValue, setFilter, id } }) {
    let columnInfo = useMemo(() => {
        return {
            numDocs: {
                name: '# of Documents',
                range: [1, 10]
            },
            numImgs: {
                name: '# of Images',
                range: [1, 10]
            },
            numBids: {
                name: '# of Bids',
                range: [0, 100]
            },

        };
    }, []);

    const validationRules = {
        min: [{
            test: (value) => !value || (parseInt(value) >= columnInfo[id].range[0] && parseInt(value) <= columnInfo[id].range[1]),
            errorMsg: "rangeError"
        },
        {
            test: (value, formData) => !value || formData.max === "" || parseInt(value) <= parseInt(formData.max),
            errorMsg: "Min value must be less than or equal to max value"
        }],
        max: [{
            test: (value) => !value || (parseInt(value) >= columnInfo[id].range[0] && parseInt(value) <= columnInfo[id].range[1]),
            errorMsg: "rangeError"
        }]
    };

    const { formData, setFormData, errorMapping, handleChange, handleSubmit } = useForm({
        min: filterValue ? filterValue[0] : columnInfo[id].range[0],
        max: filterValue ? filterValue[1] : columnInfo[id].range[1]
    }, validationRules, true);

    const handleReset = (e) => {
        setFilter(null);
    }

    const handleSubmitSuccess = () => {
        setFilter([parseInt(formData.min), parseInt(formData.max)]);
    }

    return (
        <div className="absolute top-0 right-0 mt-5 mr-10 bg-white rounded-lg shadow-lg">
            <div className="relative flex justify-between items-center rounded-header py-3 px-6 pr-4">
                <h3 className="text-xl">Filter {columnInfo[id].name}</h3>
                <div 
                className="flex items-center tooltip__container rounded-lg p-1 hover:bg-hover-light-primary text-white cursor-pointer"
                onClick={(e) => setFilter(filterValue !== undefined ? filterValue : null)}
                >
                    <span className="tooltip__tooltip-right text-base mt-2">Exit without saving</span>
                    <ClearIcon />
                </div>
            </div>
            <form className="px-6 py-3 w-80" onSubmit={(e) => handleSubmit(e, handleSubmitSuccess)}>
                <p className="font-secondary font-bold text-primary text-sm mb-2">You can omit min or max values for single value filtering.</p>
                <div className="relative">
                    <div className="flex justify-center items-center">
                        <FormInput 
                        type="number"
                        label="Min"
                        inputName="min" 
                        value={formData.min}
                        handleChange={handleChange} />
                        <Slider 
                        aria-labelledby="number-slider"
                        min={columnInfo[id].range[0]}
                        max={columnInfo[id].range[1]} 
                        ValueLabelComponent={({value, children, open}) => <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>{children}</Tooltip>}
                        value={[formData.min, formData.max]}
                        valueLabelDisplay="auto"
                        onChange={(e, value) => setFormData({ min: value[0], max: value[1] })}
                        className=" mt-8 mx-5"
                        style={{flexShrink: "0.4"}}
                        />
                        <FormInput 
                        type="number"
                        label="Max"
                        inputName="max" 
                        value={formData.max}
                        handleChange={handleChange} />
                    </div>
                    {
                        errorMapping.max || errorMapping.min === 'rangeError'
                        ?
                        (
                            <span className="form-error-text absolute mt-2 leading-tight text-center">Min and max values must be within {columnInfo[id].range[0]} and {columnInfo[id].range[1]}</span>
                        ) 
                        :
                        (
                        errorMapping.min && (
                            <span className="form-error-text absolute mt-2 leading-tight text-center">{errorMapping.min}</span>
                        )
                        )
                    }   
                </div>
                <div className="flex justify-center mt-12">
                    <InputButton 
                    type="button"
                    submitText="Turn Off" 
                    inputStyle="inverse"
                    disabled={!filterValue}
                    onClick={handleReset} />
                    <InputButton 
                    type="submit"
                    submitText="Done"
                    rootClass="ml-5"
                    disabled={errorMapping.min || errorMapping.max} />
                </div>
            </form>
        </div>
    )
}
/**
 * @param sortStatus - ASC sort: 1, no sort: 0, DESC sort: -1
 */
const SortButtons = React.memo(({ sortStatus, handleClick }) => {
    return (
        <div 
        className="inline-flex flex-col items-center justify-center cursor-pointer -mt-4"
        onClick={handleClick}>
            <ArrowDropUpIcon color={(!sortStatus || sortStatus === -1) ? 'disabled' : 'inherit'} />
            <ArrowDropDownIcon color={(!sortStatus || sortStatus === 1) ? 'disabled': 'inherit'} className="-m-4" />
        </div>
    )
}, (prevProps, nextProps) => prevProps.sortStatus === nextProps.sortStatus);

export default function AgentDashboardPropertyTable() {
    const [propertyData, setPropertyData] = useState([]);
    const [filteredColumnId, setFilteredColumnId] = useState('');


    useEffect(() => {
        axios.post("/api/agent/listProducts", null)
        .then((res) => {
            if (res.data.status) {
                console.log(res.data.data);

                setPropertyData(res.data.data.map((property) => {
                    return {
                        ...property, 
                        createdDate: format(parseISO(property.createdDate), 'dd MMM, yyyy'),
                        }
                }));
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }, []);

    const handleDelete = (propertyId) => {
        let newPropertyData = [...propertyData];
        let deleteIdx = newPropertyData.findIndex((property) => property.productId === propertyId);

        newPropertyData.splice(deleteIdx, 1);
        setPropertyData(newPropertyData);
    }

    const tableColumns = useMemo(() => [
        {
            Header: 'Title & Address',
            accessor: 'title',
            disableFilters: true
        },
        {
            Header: 'Status',
            accessor: 'productStatus',
            Filter: MultiSelectFilter,
            filter: "multi"
        },
        {
            Header: 'Create Date',
            accessor: 'createdDate',
            Filter: DateRangeFilter,
            filter: "range",
            sortType: 'date'
        },
        {
            Header: 'Publish Date',
            accessor: 'publishDate',
            Filter: DateRangeFilter,
            filter: 'range',
            sortType: 'date'
        },
        {
            Header: '# of Docs',
            accessor: 'numDocs',
            Filter: SliderFilter,
            filter: 'between'
        },
        {
            Header: '# of Images',
            accessor: 'numImgs',
            Filter: SliderFilter,
            filter: 'between'
        },
        {
            Header: "# of Bids",
            accessor: "numBids",
            Filter: SliderFilter,
            filter: 'between'
        },
        {
            Header: 'Start Price',
            accessor: 'bidStartPrice',
            Filter: PriceFilter,
            filter: 'between'
        },
        {
            Header: 'Reserve Price',
            accessor: 'reservePrice',
            Filter: PriceFilter,
            filter: 'between'
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            disableFilters: true,
            disableSortBy: true
        }
    ], []);

    const tableData = useMemo(() => {
        if (!propertyData.length) {
            return new Array(10).fill(0).map((val, idx) => {
                let placeholder = (
                    <div className="h-6 bg-dark-gray rounded-lg animate-pulse"></div>
                );
                
                return {
                    title: placeholder,
                    productStatus: placeholder,
                    createdDate: placeholder,
                    publishDate: placeholder,
                    numDocs: placeholder,
                    numImgs: placeholder,
                    numBids: placeholder,
                    bidStartPrice: placeholder,
                    reservePrice: placeholder,
                    actions: placeholder
                }
            })
        }
        else {
            return propertyData.map(
                (property) => { 
                    let { title, address, productStatus, createdDate, publishDate, assetDocuments, assetImages, bidHistory, bidStartPrice, reservePrice } = property;
                    let newPublishDate = publishDate ? format(parseISO(publishDate), 'dd MMM, yyyy') : 'N/A';
                    return {
                        title: <span><strong>{title}</strong><span className="block mt-1">{address}</span></span>, 
                        productStatus: productStatus === 'PreMarket' ? 'Pre-Market' : (productStatus === 'ScheduledPreMarket' ? 'Scheduled Pre-Market' : productStatus), 
                        createdDate, 
                        publishDate: newPublishDate, 
                        numDocs: assetDocuments.length,
                        numImgs: assetImages.length,
                        numBids: bidHistory.length,
                        bidStartPrice: new Intl.NumberFormat('en-GB').format(bidStartPrice),
                        reservePrice: reservePrice === -1 ? 'N/A' : new Intl.NumberFormat('en-GB').format(reservePrice),
                        actions: <AgentDashboardTableActionBar propertyData={property} handleDelete={handleDelete} />  
                    } 
                });
        }}, [propertyData]);

    const sortTypes = useMemo(() => ({
        date: (rowA, rowB, columnId, desc) => {
            if (rowA.values[columnId] === 'N/A') {
                return -1;
            }

            if (rowB.values[columnId] === 'N/A') {
                return 1;
            }

            let dateA = parse(rowA.values[columnId], 'dd MMM, yyyy', new Date()).getTime();
            let dateB = parse(rowB.values[columnId], 'dd MMM, yyyy', new Date()).getTime();


            return dateA < dateB ? -1 : 1;
        }
    }))

    const filterTypes = useMemo(() => ({
        multi: (rows, id, filterValue) => {
            setFilteredColumnId('');
            
            return !filterValue ? rows : rows.filter((row) => {
                const rowValue = row.values[id];

                return filterValue.some((option) => option === rowValue);
            })
        },
        range: (rows, id, filterValue) => {
            console.log(filterValue);
            setFilteredColumnId('');

            if (!filterValue) {
                return rows;
            }
            
            if (filterValue[0] && filterValue[1]) { // Min and max - range.
                let minDate = filterValue[0].getTime();
                let maxDate = filterValue[1].getTime();

                return rows.filter((row) => {
                    let rowValue = parse(row.values[id], 'dd MMM, yyyy', new Date()).getTime();

                    return rowValue >= minDate && rowValue <= maxDate;
                })
            }
            else if (filterValue[0]) { // Just min
                let minDate = filterValue[0].getTime();

                return rows.filter((row) => {
                    let rowValue = parse(row.values[id], 'dd MMM, yyyy', new Date()).getTime();
                    console.log(rowValue);
                    return rowValue >= minDate;
                })
            }
            else { // Just max
                let maxDate = filterValue[1].getTime();

                return rows.filter((row) => {
                    let rowValue = parse(row.values[id], 'dd MMM, yyyy', new Date()).getTime();

                    return rowValue <= maxDate;
                })
            }
        },

        between: (rows, id, filterValue) => {
            setFilteredColumnId('');

            if (!filterValue) {
                return rows;
            }

            return rows.filter((row) => row.values[id] >= filterValue[0] && row.values[id] <= filterValue[1]);
        }
    }), []);

    const propertyTable = useTable({ columns: tableColumns, data: tableData, disableMultiSort: true, filterTypes, sortTypes}, useFilters, useSortBy);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = propertyTable;

    const handleSort = (column) => {
        let isSortedDesc = column.isSortedDesc;

        if (isSortedDesc !== undefined) {
            if (isSortedDesc) {
                column.clearSortBy();
            }
            else {
                column.toggleSortBy(true);
            }
        }
        else {
            column.toggleSortBy(false);
        }
    }



    return (
        <table 
        className="bg-white rounded-lg shadow-lg mt-3"
        {...getTableProps()}>
            <thead className="border-b-2 border-table-light-blue">
            {
                headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                        headerGroup.headers.map((column) => (
                            <th
                            className={`relative border-r-2 border-table-light-blue p-3  pr-1 font-primary text-primary text-base text-left ${column.id === 'title' && 'w-1/6'}`}
                            {...column.getHeaderProps()}>
                                <div className="flex justify-between items-center">
                                    {column.render('Header')}
                                    <div className="flex items-center">
                                        {column.canSort && <SortButtons sortStatus={column.isSortedDesc ? -1 : column.isSortedDesc === undefined ? 0 : 1} handleClick={(e) => handleSort(column)} />}
                                        <div className={column.filterValue !== undefined && 'table-filter__container'}>
                                            {column.canFilter && <FilterListIcon color="inherit" className={`ml-1 cursor-pointer ${column.filterValue && 'table-filter-icon'}`} onClick={(e) =>{ filteredColumnId === column.id ? setFilteredColumnId('') : setFilteredColumnId(column.id) }} />}
                                        </div>
                                    </div>
                                    {column.canFilter && filteredColumnId === column.id && column.render('Filter')}
                                </div>

                            </th>
                        ))
                    }
                    </tr>
                ))
            }
            </thead>
            <tbody
            className="p-6" 
            {...getTableBodyProps()}>
            {
                rows.map((row) => {
                    prepareRow(row);

                    return (
                        <tr 
                        className="border-b-2 border-table-light-blue font-primary text-sm"
                        {...row.getRowProps()}>
                        {
                            row.cells.map((cell) => {
                                return (
                                    <td
                                    className="p-3 border-r-2 border-table-light-blue" 
                                    {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })
                        }
                        </tr>
                    )
                })
            }
            </tbody>
        </table>
    )



}
