import React, { useState } from 'react';
import Select from './forms/Select';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div 
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}>
        {
            value === index && children
        }
        </div>
    )
};

function Tabs({ tabList, rootClass, children, tabClass, tabContentClass, mobileBreakpoint }) {
    const [tabIndex, setTabIndex] = useState(0);

    const handleSelectChange = (e) => {
        let value = e.target.value;

        setTabIndex(parseInt(value));
    }

    return (
        <div className={`rounded-lg bg-white p-5 pt-3 ${rootClass}`}>
            <h3 className="font-primary font-bold text-2xl text-center mb-3 property-page-sm:hidden">More Property Info</h3>
            <Select 
            options={tabList.map((tab, idx) => { return { name: tab, value: idx } })}
            onChange={handleSelectChange}
            rootClass="property-page-sm:hidden" />
            <ul
            className="relative hidden property-page-sm:flex border-b-2 border-mid-gray m-5"
            role="tablist">
            {
                tabList.map((title, idx) => {
                    return (
                        <li 
                        id={`tab-${idx}`}
                        key={idx}
                        className={`w-full p-5 pt-3 cursor-pointer text-center font-secondary font-bold ${tabClass}`}
                        role="tab"
                        aria-selected={tabIndex === idx}
                        aria-controls={`tabpanel-${idx}`}
                        onClick={(e) => setTabIndex(idx)}
                        >{title}</li>
                    )
                })
            }
                <span 
                className="transition-all duration-300 ease-in-out absolute bottom-0 left-0 h-1 bg-primary-agency"
                style={{
                    transform: `translateX(${tabIndex * 100}%)`,
                    width: `${100 / tabList.length}%`
                }}></span>
            </ul>
            {/* <div className={`px-0 sm:p-5 pt-3 ${tabContentClass}`}> */}
            <div>
            {
                children.map((child, idx) => {
                    return <TabPanel key={idx} value={tabIndex} className={`px-0 sm:p-5 pt-3 ${tabContentClass}`} index={idx}>{child}</TabPanel>
                })
            }
            </div>
        </div>
    )
}
export default Tabs;
// export default React.memo(Tabs, (prevProps, nextProps) => {
//     return JSON.stringify(prevProps.tabList) === JSON.stringify(nextProps.tabList);
// })