import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';

import ModalLayout from '@components/ModalLayout';
import InputButton from '@components/forms/InputButton';

export default function AgentDashboardWarningModal({ mode, noImages, noDocs, open, handleClose, handleAddProperty }) {
    return (
        <ModalLayout
        open={open}
        withHeader
        icon={<WarningIcon />}
        title="No Images/Documents"
        handleClose={handleClose}
        modalBodyClass="w-5/6 md:w-1/2">
            <div className="font-secondary p-5">
                <p className="font-bold text-lg text-primary">You haven't uploaded any { noImages && 'images' }{ noImages && noDocs ? ' nor documents' : (noDocs && ' documents') }!</p>
                <p className="mt-2 font-bold">Although you can {mode} a property without them, we advise that you do. Here's why:</p>
                {
                    noImages && (
                        <p className="mt-4"><strong className="text-primary">Images:</strong> Having images will greatly increase the impressions on your property, and provide a visual indicator
                        for the bidder as to what they're bidding for. <br/><br/><strong>If you don't have any images, we will provide a placeholder image for you.</strong></p>
                    )
                }
                {
                    noDocs && (
                        <p className="mt-4"><strong className="text-primary">Documents:</strong> Having documents will ensure that bidders have a better idea and a more in-depth look at the property
                        they're bidding for.</p>
                    )
                }
                <div className="flex justify-center mt-8">
                    <InputButton 
                    submitText={`Continue ${mode === 'add' ? 'Adding' : 'Updating'} Property`}
                    type="button" 
                    onClick={(e) => 
                    {
                        handleAddProperty();
                        handleClose();
                    }} />
                    <InputButton 
                    submitText="Cancel" 
                    type="button"   
                    inputStyle="inverse"
                    rootClass="ml-5"
                    onClick={(e) => handleClose()} />
                </div>

            </div>
        </ModalLayout>
    )
}