import { useState, useEffect, useRef } from 'react';

/* 
A notification is an object that consists of 
1. headerText: A header text to be displayed initially
2. innerDescription: A JSX element to be displayed when more information is required.
*/
const DEFAULT_NOTIFICATION_DURATION = 5 * 1000; // ms

export default function useNotifications() {
    const [notifications, setNotifications] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [notificationExpand, setNotificationExpand] = useState(false);
    const notificationTimeoutRef = useRef(null);

    const addNotification = (headerText, innerDescription, duration=DEFAULT_NOTIFICATION_DURATION, endCallback) => {
        setNotifications((notifications) => [...notifications, {headerText, innerDescription, duration, endCallback}]);
    }

    useEffect(() => {
        if (notifications.length && !isActive) {
            setIsActive(true);

            notificationTimeoutRef.current = setTimeout(() => {
                setIsActive(false);
                setNotificationExpand(false);
                setNotifications(oldNotifications => {
                    let newNotifications = [...oldNotifications];

                    newNotifications.shift();
                    return newNotifications;
                });

                if (notifications[0].endCallback) {
                    notifications[0].endCallback();
                }
            }, notifications[0].duration);
        }
    }, [notifications]);

    /**
     * Skips the current notification.
     */
    const forceSkipNotification = () => {
        clearTimeout(notificationTimeoutRef.current);
        setIsActive(false);
        setNotifications(oldNotifications => {
            let newNotifications = [...oldNotifications];

            newNotifications.shift();
            return newNotifications;
        });    
    };

    return { notifications, isActive, addNotification, forceSkipNotification, notificationExpand, setNotificationExpand };
}