import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

import AuthContext from '@contexts/AuthContext';

import AgentLogin from './AgentLogin';
import AgentDashboard from './dashboard/AgentDashboard';
import ProtectedRoute from '@components/routing/ProtectedRoute';

export default function AgentSite() {
    const { userType } = useContext(AuthContext);

    return (
        <Switch>
            <Route path="/agent/login" exact component={AgentLogin} />
            <ProtectedRoute 
            path="/agent/dashboard" 
            component={AgentDashboard}
            condition={userType}
            redirectPath="/agent/login" />
        </Switch>
    )
}