import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Footer from '@components/Footer';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        backgroundColor: 'white',
        boxShadow: 'none',
        padding: '0.5rem 0',
        '&:first-child': {
            borderRadius: '0.5rem 0.5rem 0 0'
        },
        '&:not(:first-child)': {
            borderRadius: '0'
        },
        '&:last-child': {
            borderRadius: '0 0 0.5rem 0.5rem'
        },
        '&:last-child .MuiAccordionDetails-root': {
            borderRadius: '0 0 0.5rem 0.5rem'
        },
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        fontWeight: 'bold'
    }
})(MuiAccordionSummary);

export default function FAQPage() {
    /* 
        Effect:

        Allow for smooth scrolling on this page (don't forget to clear on dismount!)
    */
    useEffect(() => {
        document.documentElement.style.scrollBehavior = 'smooth';

        return () => {
            document.documentElement.style.scrollBehavior = null;
        }
    }, []);

    return (
        <div>
            <header className="py-20 px-4 bg-primary-gray text-center">
                <h1 className="font-bold text-3xl sm:text-4xl">Frequently Asked Questions</h1>
                <ul className="flex justify-center space-x-4 mt-6 text-primary-agency text-lg font-bold">
                    <li><a href="#general">General</a></li>
                    <li><a href="#buyers">Buyers</a></li>
                    <li><a href="#sellers">Sellers</a></li>
                </ul>
            </header>
            <main className="py-16 px-4 sm:px-8 max-w-lg-container mx-auto space-y-8">
                <section id="general" className="pt-28">
                    <header>
                        <h2 className="text-2xl font-bold">General</h2>
                    </header>
                    <div className="mt-6">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                What is a Starting Bid?
                            </AccordionSummary>
                            <AccordionDetails>
                                The Starting Bid is the price that the bid for each property will begin at. Starting Bids are not to be confused with the price that the property is being sold for and is only used as a point for the auctioneer to start at and be guided from. Each Starting Bid has a corresponding Reserve Price.

                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                What is a Reserve Price?
                            </AccordionSummary>
                            <AccordionDetails>
                                The Reserve Price is the minimum price that a property sells for. The Reserve Price is agreed between the seller and the auctioneer before the property is marketed and will not be disclosed to any potential bidders. The Reserve Price cannot be more than 10% higher than the property’s Guide Price, (the price at which the property is marketed). If your reserve price is not met in an auction, you are not legally obliged to sell.

                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                What is a Guide Price?
                            </AccordionSummary>
                            <AccordionDetails>
                                The Guide Price the amount given to potential buyers to give them an idea as to how much the property is expected to sell for. Please note the amount the property sells for is dependent on the amount of interest around the property and the Guide Price is not the final price.

                            </AccordionDetails>
                        </Accordion>
                    </div>

                </section>
                <section id="buyers" className="pt-28">
                    <header>
                        <h2 className="text-2xl font-bold">Buyers</h2>
                    </header>
                    <div className="mt-6">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                How long does an auction last?
                            </AccordionSummary>
                            <AccordionDetails>
                                {/* To edit, just replace this lorem ipsum text with text of your choosing. */}
                                An auction can last anywhere from days to weeks. The duration is dependent on the amount of interest generated while the property was in its marketing stage as well as the seller’s circumstances. We encourage you to register your interest in a property that you have your eye on, the Auction Specialist will know to be in touch with you to communicate any updates such as the seller ending the auction or accepting a bid prior to the end of an auction.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                How do I place bids?
                            </AccordionSummary>
                            <AccordionDetails>
                                The first step is to register an account with us, you can then sign in to your account and find the form to register your interest on the top bar of your account dashboard, we recommend filling in this form even if you are just browsing. Once you submit your form, an email will be sent to you confirming that you are now registered to place bids. We strongly encourage you to view each property and familiarise yourself with the legal information in the legal pack.

                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Can I place bids or offers at any point during the auction process?
                            </AccordionSummary>
                            <AccordionDetails>
                                Yes, you can place a bids once the property goes live. You can also make an offer at any point in the auction, including the pre-auction marketing stage. If you do this, please be reminded that the Legal Pack has not been signed off. Every offer that is made needs to be put forward to the seller by law and it is then up to the seller to decide if they would like to accept the offer, in turn removing the property from the auction before the end date. In the case of this occurring, the buyer is still accountable for the Reservation Fee and the Terms and Conditions of the auction still apply.


                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                How can I make an offer?
                            </AccordionSummary>
                            <AccordionDetails>
                                Please put any offers in writing or email them to offers@321sold.co.uk. Verbal offers will not be accepted. Every offer will be acknowledged. Please remember that if you make an offer before the auction date, the offer value need to be above the guide price to be considered.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Are bids legally binding?
                            </AccordionSummary>
                            <AccordionDetails>
                                Once you place a bid, you are then bound to pay a Reservation Fee and sign the Reservation Contract. This awards you exclusivity to the property. A bid does not mean that you are bound to buy the property however, they will be awarded the right to exchange contracts and complete on the property at the agreed price within 56 days of the buyer’s solicitor receiving the draft contracts from the seller’s solicitor. It can take about 28 for completion after exchange.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                What is the Reservation Fee?
                            </AccordionSummary>
                            <AccordionDetails>
                                The buyer pays a reservation fee which commits them to the purchase and grants them exclusivity to the buying of the property. This Fee is put towards the seller’s auction and Estate Agency fees. <br /> <br />
                                The reservation fee should be taken into account when the buyer is totalling the amount to spend on the property as the fee is additional to the purchase price and non-refundable. <br /> <br />
                                The Reservation Fee paid is 4.5% (of the purchase price) and is subject to a minimum of £6,000. The successful bidder will be automatically charged this fee at the end of the auction.

                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Will paying a Reservation Fee increase my stamp duty?
                            </AccordionSummary>
                            <AccordionDetails>
                                HMRC states that the chargeable consideration (monies or monies worth) for a land or property transaction is what has been given (either directly or indirectly) in order to acquire the subject - matter of the transaction, by the purchaser or a person connected with them. This includes fees which have to be paid in order to acquire the property. This means that the total price that is paid for a property, including the reservation fee, will determine liability for stamp duty. Please discuss any queries with your Solicitor before committing to a bid.

                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Where can I view the legal pack for a property?
                            </AccordionSummary>
                            <AccordionDetails>
                                You can view the legal pack for each property on the property’s listing page which can be found within the Documents tab. Please note you can only view them if you are logged into your account. Legal documents get uploaded as soon as they are received. Checking all legal documentation prior to bidding, for each property that is of interest is extremely important as well as reading through the Reservation Contract document contained as part of the legal documents for each property.
                                <br /> <br /> Once an auction draws to an end, a reservation agreement is produced and auctioneer can sign this on behalf of both the buyer and the seller which serves as evidence that an exclusivity agreement agreed between both parties. There is then a 56 day window where Solicitors receive draft contracts. Completion is generally expected within 28 days of exchange.
                                <br /><br /> We recommend using a solicitor or conveyancer to review the legal documents on your behalf.


                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Can I have the property surveyed before the auction?
                            </AccordionSummary>
                            <AccordionDetails>
                                Yes, we actually advise that you have the property surveyed prior to the auction but please do instruct your surveyor to contact us for access.

                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                If I do not complete the sale, are there any penalties?
                            </AccordionSummary>
                            <AccordionDetails>
                                Should the 28 day period pass and you have still not exchanged contracts, then the full £6000 will be forfeited. Further to this, the full amount of the reservation fee is payable. Moreover, the possibility remains that the seller sues you for any losses caused, as well as for the balance owed.
                                <br /><br /> You will receive a full refund of the reservation fee in the event the exchange does not occur before or on the expiry of the 28 day period, due to the seller’s actions.


                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Will I be able to get a mortgage?
                            </AccordionSummary>
                            <AccordionDetails>
                                Due to the fact that not all properties would be suitable for mortgage security,  should you require mortgage financing, we would strongly recommend that prior to bidding, buyers organise an independent survey to be carried out.


                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                I’ve won the auction, what happens next?
                            </AccordionSummary>
                            <AccordionDetails>
                                Should you be the successful bidder, we will then proceed to contact you. You will also be automatically charged the reservation fee - this payment will be charged to the payment details which were provided by you.  If there are any outstanding amounts of the reservation fee, as the successful bidder you will be required to pay these amounts once the auction has come to an end.
                                <br /><br /> Once an auction draws to an end, a Reservation Contract is produced and the auctioneer can sign this on behalf of both the buyer and the seller which serves as evidence that an exclusivity agreement was agreed between both parties.
                                <br /><br /> In the event of winning the bid, you should contact your solicitor immediately. As stated above, we will also proceed to contact you. We will also contact your solicitor.
                                <br /><br /> If the 28 day period passes and you have still not exchanged contracts, then the full £6000 will be forfeited. Further to this, the full amount of the reservation fee is payable. Additionally, the possibility remains that the seller sues you for any losses caused, as well as for the balance owed. You will receive a full refund of the reservation fee in the event the exchange does not occur before or on the expiry of the 28 day period, due to the seller’s actions.



                            </AccordionDetails>
                        </Accordion>


                    </div>
                </section>
                <section id="sellers" className="pt-28">
                    <header>
                        <h2 className="text-2xl font-bold">Sellers</h2>
                    </header>
                    <div className="mt-6">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Why are auction properties priced differently to private treaty properties?
                            </AccordionSummary>
                            <AccordionDetails>
                                An auctioneer aims to attract multiple bids from a variety of bidders. In order to do this, the auction price needs to start at a lower price point. In turn, the value of the property should increase to above market value.
                                <br /><br /> In the case that you receive a ‘guide price’ that is lower than expected, there is no need for concern as this does not represent the value of your property.

                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Will I be subject to any fees?
                            </AccordionSummary>
                            <AccordionDetails>
                                The full value of your house belongs to you, you will keep all sales proceedings. Furthermore, no commissions are charged by us.
                                <br /><br /> The fees you can expect to incur in the process are:
                                <br /><br />
                                ●	Fees charged by the solicitor in order to prepare the legal pack for your property
                                <br /> ○	These fees will commonly cost around £350 to £500.
                                <br /> ○	These fees are charged regardless of auctions.
                                <br /><br />
                                ●	Fees charged by the solicitor for the conveyancing of your property.
                                <br /> ○	These fees will commonly cost around £450 to £750.
                                <br /> ○	These fees are charged after your home is sold.


                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                How long does the auction process take?
                            </AccordionSummary>
                            <AccordionDetails>
                                To put it simply, from start to finish, the process takes up to two months. The auction process takes significantly less time than a private treaty which tends to take 6 months.
                                <br /> <br /> Phase 1: The valuation, legal pack, and pre-auction marketing period lasts 2 - 3 weeks.
                                <br /> <br /> Phase 2: The actual auction can run anywhere from 15 minutes to 30 days.
                                <br /> <br /> Phase 3: 56 days to exchange contracts and complete. This phase begins once the buyer solicitor receives a copy of the draft contracts from your solicitor.
                                <br /> <br /> Please see your Selling Guide or contact us for more details.

                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                What is the optimum duration for an online auction?
                            </AccordionSummary>
                            <AccordionDetails>
                                Completion of an auction differs according to the interest in bidding on the property during the pre-auction marketing stage. Once a Legal Pack has been prepared and finalised, we will be able to advise on your property end date. Depending on interest, the end date could take anywhere between two days to two weeks.
                                <br /> <br /> You are welcome to view the time remaining on any auction by using our search function or the property’s direct web page. The time remaining is displayed on the top right of the page.

                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Can I set a minimum price on my property?
                            </AccordionSummary>
                            <AccordionDetails>
                                Yes, you can. Properties for sale by auction can be subject to a Reserve Price, also known as a minimum price that a property cannot sell below. The reserve price would be agreed between the seller and the auctioneer before the property is marketed and will not be disclosed to any potential bidders. The Reserve Price cannot be more than 10% higher than the property’s Guide Price, (the price at which the property is marketed).

                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                What if the Reserve Price is not met?
                            </AccordionSummary>
                            <AccordionDetails>
                                If the Reserve Price is not achieved and the auction ends with the highest bid below your reserve, you will be able to consult with your Auction Specialist to decide if you would like to proceed to accept the bid.

                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Can I accept bids prior to an auction ending?
                            </AccordionSummary>
                            <AccordionDetails>
                                Yes, it is fine to accept a bid prior to the completion of the auction. We are duty bound to present any and all offers for the property to you, and it is your decision whether you would like to accept an offer and end the auction early. Your property’s buyer will still be bound by the Terms and Conditions of the auction.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Can I accept an offer from a private viewer?
                            </AccordionSummary>
                            <AccordionDetails>
                                Our sale strategy has been designed in a way that ensures security to both parties and we believe that this is why clients choose to Auction their property. All negotiations need to be executed by the Partner Agent and 123auction during the period that you are under contract. According to the terms of the contract, if you decide to accept an offer from a private viewer, you will be liable for the auction fees as opposed to the buyer. We have a shared goal in selling your property, therefore we will work with anyone who is interested but may be unsure about the auction process, to agree a sale for you.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Do I need to provide legal documents for my property?
                            </AccordionSummary>
                            <AccordionDetails>
                                Yes. In the spirit of transparency and fairness, all buyers should be able to access the same information prior to purchasing a property. It is for this reason that sellers need to provide their legal documents before an auction.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                How do I prepare a legal pack?
                            </AccordionSummary>
                            <AccordionDetails>
                                Your solicitor  will be happy to prepare the legal pack for your property. The cost of this is anywhere from £350 to £500. Once the auction is complete and your property is sold, a solicitor will need to be instructed for the conveyancing of the property, this fee will be around £450 to £750.
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Will I need to have an Earnings Performance Certificate (EPC) conducted?
                            </AccordionSummary>
                            <AccordionDetails>
                                Properties that don’t have an EPC in place before an auction will need to have one actioned. <br /> <br /> There are a few cases where one would be exempt from this: <br /> <br />
                                ●	Places of worship <br />
                                ●	Temporary buildings that will only be in use for less than 2 years <br />
                                ●	Stand-alone buildings with total useful floor space of less than 50 square metres <br />
                                ●	Industrial sites <br />
                                ●	Workshops and non-residential agricultural buildings that don’t use little energy <br />
                                ●	Buildings that are due to be demolished <br />
                                ●	Holiday accommodation that’s rented out for less than 4 months a year or is let under a licence to occupy <br />
                                ●	Listed buildings (you should get advice from your local authority conservation officer if the work would alter the building’s character) <br />
                                ●	Residential buildings intended to be used for less than 4 months a year. <br /> <br />
                                Please get in touch with your solicitor if you are unsure or would like to confirm that your property is exempt from having an EPC.

                            </AccordionDetails>
                        </Accordion>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}