import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AgentDashboardNavbarNew from './AgentDashboardNavbarNew';
import AgentDashboardHome from './AgentDashboardHome';
import AgentDashboardManageProperties from './AgentDashboardManageProperties';

export default function AgentDashboard() {
    return (
        <div className="pt-16">
            <div className="max-w-container-dashboard-2 mx-auto p-3 sm:py-10 sm:px-8">
                <AgentDashboardNavbarNew />
                <div className="mt-3 sm:mt-5">
                    <Switch>
                        <Route path="/agent/dashboard" exact render={(props) => <Redirect to="/agent/dashboard/home" {...props} />} />
                        <Route path="/agent/dashboard/home" exact component={AgentDashboardHome} />
                        <Route path="/agent/dashboard/manage" component={AgentDashboardManageProperties} />
                    </Switch>
                </div>

            </div>
        </div>
    )
}