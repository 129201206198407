import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from '@util/axios';
import ValidationTypes from '@util/ValidationTypes';

import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

import useForm from '@hooks/useForm';

import InputButton from '@components/forms/InputButton';
import FormInput from '@components/forms/FormInput';

export default function AgentLogin() {
    const history = useHistory();
    const { agencyData: { agencyId } } = useContext(AppContext);
    const { setUserType, setUserData } = useContext(AuthContext);

    const loginFormRules = {
        email: [ValidationTypes.requiredString, ValidationTypes.validEmail],
        password: [ValidationTypes.requiredString]
    };

    const { formData, handleChange, handleSubmit, errorMapping } = useForm({
        email: '',
        password: ''
    }, loginFormRules);

    const [loginError, setLoginError] = useState("");
    const [loading, setLoading] = useState(false);

    const submitSuccessHandler = () => {
        setLoading(true);
        axios.post('/api/agent/login', {...formData, agencyId} )
        .then((res) => {
            setLoading(false);
            if (!res.data.status) {
                setLoginError(res.data.message);
            }
            else {
                console.log(res.data.data);
                setUserType('agent');
                setUserData(res.data.data);
                // let { agentToken, agentSecret } = res.data.data;

                // setUserData(res.data.data);

                // localStorage.setItem("agentData", JSON.stringify({ agentToken, agentSecret }));
                
                history.push('/agent/dashboard/home');
                // if (location.state && location.state.from) {
                //     console.log("Go here!");
                //     console.log(location.state.from);
                //     history.push(location.state.from.pathname);
                // }
                // else {
                //     console.log("Go back!");
                //     history.goBack();
                // }
            }
        })
        .catch((err) => console.log(err));
    };

    return (
        <div>
            <div className="fixed register-bg bg-center bg-fixed top-0 left-0 right-0 bottom-0 -z-1">
            </div>
            <div className="w-full flex justify-center pt-24">
                <form
                onSubmit={(e) => handleSubmit(e, submitSuccessHandler)}
                className="w-1/3 min-w-mobile pb-10 rounded-lg bg-white bg-opacity-90">
                    <div className="rounded-lg rounded-b-none bg-primary-agency text-center py-5 px-20">
                        <h1 className="font-primary font-semibold text-3xl text-white">Agent Login</h1>
                        <p className="text-secondary text-sm font-secondary">
                            Login to access your dashboard and be able to manage your properties.
                        </p>
                    </div>
                    <div className="px-10 mt-5">
                        <FormInput 
                        type="text"
                        label="Email"
                        placeholder="Enter your email"
                        inputName="email"
                        value={formData.email}
                        error={errorMapping.email}
                        handleChange={handleChange}
                        />
                        <div className="mt-8">
                            <FormInput 
                            type="password"
                            label="Password"
                            placeholder="Enter your password"
                            inputName="password"
                            value={formData.password}
                            error={errorMapping.password}
                            handleChange={handleChange}
                            />
                        </div>
                        {
                            loginError &&
                            (
                                <div className="flex justify-center mt-5">
                                    <span className="font-primary font-black text-red-500">{loginError}</span>
                                </div>
                            )
                        }
                        <span className="block mt-8 font-primary font-black text-primary underline cursor-pointer text-center">Forgot my password</span>
                        <div className="flex justify-center mt-5">
                            <InputButton
                            type="submit"
                            submitText="Login"
                            loading={loading} />
                        </div>
                    </div>
                </form>
            </div>
            
        </div>
    )
}