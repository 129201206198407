import { useState } from 'react';

import axios from '@util/axios';

/**
 * React custom hook for API calls.
 * Contains common state for handling such calls, as well as 
 * entry points to hook into the API process, whether it be after the API call ends,
 * or when an error occurs. Can also use an escape hatch to make a more custom call,
 * and still reap the benefits of having localized state.
 * @state loading - Whether or not the results of the API are loading
 * @state err - API error
 * @state data - Data returned from API (in the case of POST requests)
 */
export default function useAPI({ method, endpoint, handleSuccess }) {
    const [loading, setLoading] = useState(false);
    const [err, setError] = useState(null);
    const [data, setData] = useState(null);

    const makeRequest = async (data) => {
        setLoading(true);

        try {
            const res = await axios({
                method,
                url: endpoint,
                data
            });

            if (!res.data.status) {
                throw new Error(res.data.message);
            }     
            
            setLoading(false);
            setData(res.data.data);
            
            if (handleSuccess) {
                handleSuccess();
            }
        }
        catch (err) {
            setLoading(false);

            if (err instanceof Error) {
                setError({
                    type: 'client',
                    context: err.message
                });
            }
            else {
                setError({
                    type: 'server',
                    context: err.status
                })
            }
        }

        setLoading(false);
    }

    return { loading, err, makeRequest, data };
}   