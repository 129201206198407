import React, { useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import BidderDashboardHomePreferencesForm from './BidderDashboardHomePreferencesForm';
import InputButton from '@components/forms/InputButton';

export default function BidderDashboardHomePreferencesSection({ preferences }) {
    const [isEditing, setIsEditing] = useState(false);

    return (
        <>
            <header className="flex flex-col sm-480:flex-row items-start space-y-3 sm-480:space-y-0 sm-480:items-center  justify-between">
                <h2 className="font-semibold text-2xl">My Preferences</h2>
                <InputButton 
                submitText={isEditing ? 'Cancel Edit' : 'Edit Preferences'}
                onClick={() => setIsEditing((isEditing) => !isEditing)} />
            </header>
            {
                isEditing ? (
                    <BidderDashboardHomePreferencesForm preferences={preferences} setIsEditing={setIsEditing} />
                ) : (
                    <div className="mt-5 space-y-8">
                        <div className="flex items-center space-x-2">
                        {
                            preferences.newsletter === 'yes' ? (
                                <div className="text-green-500">
                                    <CheckIcon color="inherit" />
                                </div>
                            ) : (
                                <div className="text-air-red">
                                    <CloseIcon color="inherit" />
                                </div>
                            )
                        }
                            <p className="font-semibold text-sm">I would like to receive email updates on available auction properties.</p>
                        </div>
                    </div>
                )
            }

        </>
    )
}