import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import qs from 'qs';
import LockIcon from '@material-ui/icons/Lock';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { bidder } from '@api/api';

import ValidationTypes from '@util/ValidationTypes';

import useForm from '@hooks/useForm';

import InputButton from '@components/forms/InputButton';
import FormInput from '@components/forms/FormInput';

const ResetComplete = () => {
    return (
        <div className="flex justify-center items-center min-h-screen">
            <div className="w-1/2 lg:w-1/3 min-w-mobile rounded-lg pb-5 bg-white bg-opacity-90">
                <div className="flex items-center rounded-lg rounded-b-none bg-primary text-center py-5 px-5 sm:px-10">
                    <LockIcon className="text-white" fontSize="large" />
                    <h1 className="font-primary font-semibold text-xl md:text-3xl ml-3 text-white">Reset Complete!</h1>
                </div>
                <p className="font-secondary text-base md:text-lg lg:text-xl p-5 text-center">
                    Your password is now reset. You can now login with your new password.
                </p>
                <Link to="/bidder/login">
                    <InputButton 
                    type="button"
                    submitText="Login" 
                    rootClass="mx-auto" />
                </Link>
            </div>
        </div>
    )
}

export default function BidderNewPassword() {
    const location = useLocation();
    
    const resetValidationRules = {
        email: [ValidationTypes.requiredString, ValidationTypes.validEmail],
        password: [ValidationTypes.requiredString, {
            test: (value) => value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
            errorMsg: "Invalid password format"
        }],
        password2: [ValidationTypes.requiredString, {
            test: (value, formData) => value === formData.password,
            errorMsg: "Passwords do not match"
        }]
    }
    const { formData, handleChange, errorMapping, handleSubmit } = useForm({
        email: '',
        password: '',
        password2: ''
    }, resetValidationRules);

    const [showPasswordHelper, setShowPasswordHelper] = useState(false);
    const [resetError, setResetError] = useState(null);
    const [tokenValidated, setTokenValidated] = useState(null);
    const [resetToken, setResetToken] = useState('');

    const handleSubmitSuccess = () => {
        bidder.post({
            endpoint: 'setNewPassword',
            data: {
                ...formData,
                resetToken
            },
            handleSuccess: (_) => setResetError(''),
            handleFail: (err) => setResetError(err)
        });
    }

    useEffect(() => {
        const params = qs.parse(location.search.slice(1));

        setTokenValidated(params.status === 'validated');
        setResetToken(params.resetToken);
    }, []);

    return (
        <main>
            <div className="fixed register-bg bg-center bg-fixed top-0 left-0 right-0 bottom-0 -z-1" />
            {
                resetError !== null && !resetError
                ?
                (
                    <ResetComplete />
                )
                :
                (
                    <div className="w-full flex justify-center items-center min-h-screen">
                    {
                        tokenValidated !== null ? (
                            tokenValidated ? (
                                <form
                                onSubmit={(e) => handleSubmit(e, handleSubmitSuccess)}
                                className="w-5/6 sm:w-1/2 lg:w-1/3 min-w-mobile rounded-lg bg-white bg-opacity-90">
                                    <div className="rounded-lg rounded-b-none bg-primary text-center py-5 px-5 sm:px-10">
                                        <h1 className="font-primary font-semibold text-3xl text-white">Reset Password</h1>
                                        <p className="text-secondary text-sm font-secondary">
                                            Enter the following information in order to reset your password.
                                        </p>
                                    </div>
                                    <div className="p-5">
                                        <FormInput 
                                        type="text"
                                        label="Email"
                                        placeholder="Enter your email"
                                        inputName="email"
                                        value={formData.email}
                                        error={errorMapping.email}
                                        handleChange={handleChange}
                                        />
                                        <div className="relative w-full mt-8">
                                            <FormInput 
                                            type="password"
                                            label="Password"
                                            placeholder="Enter a password"
                                            inputName="password"
                                            value={formData.password}
                                            error={errorMapping.password} 
                                            handleChange={handleChange} />
                                            {
                                                showPasswordHelper 
                                                ?
                                                (
                                                    <CancelIcon className="absolute top-0 right-0 mr-3 cursor-pointer" onClick={(e) => setShowPasswordHelper(false) }/>
                                                )
                                                :
                                                (
                                                    <HelpIcon className="absolute top-0 right-0 mr-3 cursor-pointer" onClick={(e) => setShowPasswordHelper(true) }/>
                                                )
                                            }
                                            <p className={`helper-text z-40 bottom-full right-0 mr-3 w-64 ${showPasswordHelper ? 'opacity-100' : ''} pointer-events-none`}>
                                                Password must be at least 8 characters and must contain the following:
                                                <ul className="mt-3 list-disc ml-3">
                                                    <li>At least 1 uppercase letter</li>
                                                    <li>At least 1 lowercase letter</li>
                                                    <li>At least 1 digit</li>
                                                </ul>
                                            </p>
                                        </div>
                                        <FormInput 
                                        type="password"
                                        label="Repeat Password"
                                        placeholder="Repeat your password"
                                        inputName="password2"
                                        value={formData.password2}
                                        error={errorMapping.password2} 
                                        handleChange={handleChange} 
                                        rootClass="mt-8" />
                                        {
                                            resetError &&
                                            (
                                                <p className="font-primary font-black text-red-500 text-center mt-8">{resetError}</p>
                                            )
                                        }
                                        <InputButton
                                        type="submit"
                                        submitText="Set New Password"
                                        rootClass="mx-auto mt-8" />
                                    </div>
                                </form>
                            )
                            :
                            (
                                <div className="bg-white rounded-lg shadow-lg p-5">
                                    <ErrorOutlineIcon className="text-air-red" fontSize="large" />
                                    <p className="font-secondary font-bold text-xl">There was an error with reseting your password.</p>
                                </div>
                            )
                            
                        )
                        : null
                    }
                    </div>

                    
                )
            }
            
            
        </main>
    );
};