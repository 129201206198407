import React, { useState }  from 'react';
import { CardElement } from '@stripe/react-stripe-js';

import Skeleton from '../Skeleton';

export default function CardInput({ handleCardChange, error, disabled }) {
    const [eleReady, setEleReady] = useState(false);
    return (
        <>
            <label 
            className="block font-primary font-bold text-primary text-xl my-5 sm:whitespace-no-wrap text-center sm:text-left"
            htmlFor="cardInfo">Please enter valid credit/debit card information.</label>
            <div className="relative">
                {
                    !eleReady && (
                        <div className="absolute top-0 left-0 w-full h-full flex items-center bg-background border-2 border-dark-gray py-1 px-3 rounded-lg">
                            <Skeleton height="h-10" width="w-48" />
                            <div className="flex items-center ml-auto">
                                <Skeleton height="h-10" width="w-16" />
                                <Skeleton height="h-10" width="w-10" rootClass="ml-3" />
                            </div>
                        </div>
                    )
                }
                <CardElement 
                id="cardInfo" 
                onChange={handleCardChange}
                options={{
                    hidePostalCode: true
                }}
                disabled={disabled}
                onReady={(_) => setEleReady(true)}
                 />
            </div>

            <div className="relative">
                {error && <span className="form-error-text absolute mt-2">{error}</span>}
            </div>
        </>
    )
}