import React, { useState, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { CircularProgress } from '@material-ui/core';

import ValidationTypes from '@util/ValidationTypes';
import axios from '@util/axios';

import AppContext from '@contexts/AppContext';

import useForm from '@hooks/useForm';
import useFileUpload from '@hooks/useFileUpload';

import AgentDashboardWarningModal from './AgentDashboardWarningModal';
import AgentFormGeneralProperty from './AgentFormGeneralProperty';
import AgentFormImagesDocs from './AgentFormImagesDocs';
import AgentFormAuction from './AgentFormAuction';
import InputButton from '@components/forms/InputButton';

export default function AgentDashboardPropertyForm({ mode }) {
    const history = useHistory();
    // const { productId } = useParams(); -- Save for when we have individual property API for agents. Instead of passing data via Link, get data directly from this component.
    const location = useLocation();
    const propertyInfo = useMemo(() => location.state ? location.state.propertyData : {}, [location]);
    console.log(propertyInfo);
    const { addNotification } = useContext(AppContext);
    const [loading, setLoading] = useState(false);

    const [addError, setAddError] = useState(null);
    const [sectionErrors, setSectionErrors] = useState({
        branch: false,
        property: false,
        imgs: false,
        docs: false,
        auction: false
    });
    const [hasValidationErrors, setHasValidationErrors] = useState(false);

    console.log(sectionErrors);

    const [loadingText, setLoadingText] = useState(null);
    const [warningModalOpen, setWarningModalOpen] = useState(false);
    const imgFileUpload = useFileUpload(null, ["jpeg", "jpg", "png"]);
    const docFileUpload = useFileUpload(null, ["pdf"]);

    const propertyFormRules = {
        title: [ValidationTypes.requiredString],
        details: [ValidationTypes.requiredString],
        branchId: [ValidationTypes.requiredString],
        bedRooms: [{
            test: (value, formData) => formData.assetType === "Land" || formData.assetType === "Other" || value,
            errorMsg: 'This is a required field'
        }],
        bathRooms: [{
            test: (value, formData) => formData.assetType === "Land" || formData.assetType === "Other" || value,
            errorMsg: 'This is a required field'
        }],
        address: [ValidationTypes.requiredString],
        town: [ValidationTypes.requiredString],
        county: [ValidationTypes.requiredString],
        lat: [ValidationTypes.requiredString, {
            test: (value) => {
                const val = parseFloat(value)
                return val > -90 && val < 90
            },
            errorMsg: 'Invalid coordinates'
        }],
        lng: [ValidationTypes.requiredString, {
            test: (value) => {
                const val = parseFloat(value)
                return val > -180 && val < 180
            },
            errorMsg: 'Invalid coordinates'
        }],
        postCode: [ValidationTypes.requiredString],
        assetClass: [ValidationTypes.requiredString],
        assetType: [ValidationTypes.requiredString],
        otherAssetType: [{
            test: (value, formData) => formData.assetType !== "Other" || value,
            errorMsg: "This is a required field"
        }],
        skewCode: [ValidationTypes.requiredString],
        // features: [{
        //     test: (value) => {
        //         return value.some((feature) => feature.value)
        //     },
        //     errorMsg: "Must include at least one non-empty feature!"
        // }]
    };

    // const docsFormRules = {
    //     assetImages: [{
    //         test: (value) => value.length,
    //         errorMsg: "Must include at least one image"
    //     }],
    //     assetDocuments: [{
    //         test: (value) => value.length,
    //         errorMsg: "Must include at least one document"
    //     }]
    // };

    const auctionFormRules = {
        reservePrice: [ValidationTypes.requiredString,
        {
            test: (value, formData) => !formData.bidStartPrice || parseInt(value) === 0 || parseInt(value) > parseInt(formData.bidStartPrice),
            errorMsg: "Reserve price must be greater than the bid start price" 
        }],
        bidStartPrice: [ValidationTypes.requiredString,
        {
            test: (value) => parseInt(value) > 0,
            errorMsg: "Value must be greater than 0"
        }],
        bidEndTime: [{
            test: (value, formData) => (formData.productStatus !== 'Scheduled' && formData.productStatus !== 'Live') ||  value !== null,
            errorMsg: "Must include a date and time"
        },
        {
            test: (value, formData) => (formData.productStatus !== 'Scheduled' && formData.productStatus !== 'Live') || value.getTime() > new Date().getTime(),
            errorMsg: "Date/time must be greater than the current date/time"
        },
        {
            test: (value, formData) => (formData.productStatus !== 'Scheduled' && formData.productStatus !== 'Live') || !formData.publishDate || value.getTime() > formData.publishDate.getTime(),
            errorMsg: "Date/time must be greater than the scheduled start date/time"
        }],
        publishDate: [{
            test: (value, formData) => formData.productStatus !== "Scheduled" || value !== null,
            errorMsg: "Must include a date and time"
        },
        {
            test: (value, formData) => formData.productStatus !== "Scheduled" || value.getTime() > new Date().getTime(),
            errorMsg: "Date/time must be greater than the current date/time"
        }],
        productStatus: [ValidationTypes.requiredString]
    };

    // const branchFormRules = {
    //     branchDetails: [ValidationTypes.requiredString],
    //     assetContactTelephone: [ValidationTypes.requiredString]
    // };

    // const { formData: branchFormData, handleChange: branchHandleChange, errorMapping: branchErrorMapping, handleSubmit: branchHandleSubmit } = useForm({
    //     branchDetails: propertyInfo.branchDetails || '',
    //     assetContactName: propertyInfo.assetContactName || '',
    //     assetContactTelephone: propertyInfo.assetContactTelephone || ''
    // }, branchFormRules);

    const { formData: propertyFormData, setFormData: propertySetFormData, handleChange: propertyHandleChange, errorMapping: propertyErrorMapping, handleSubmit: propertyHandleSubmit } = useForm({
        title: propertyInfo.title || '',
        branchId: propertyInfo.branchId || '',
        details: propertyInfo.details || '',
        // features: propertyInfo.features || [{id: "feature-0", value: ""}],
        bedRooms: propertyInfo.bedRooms !== undefined ? propertyInfo.bedRooms.toString() : "",
        bathRooms: propertyInfo.bathRooms !== undefined ? propertyInfo.bathRooms.toString() : "",
        address: propertyInfo.address || '',
        address2: propertyInfo.address2 || '',
        town: propertyInfo.town || '',
        county: propertyInfo.county || '',
        lat: propertyInfo.geoLocation?.[0]?.lat,
        lng: propertyInfo.geoLocation?.[0]?.lng,
        postCode: propertyInfo.postCode || '',
        assetClass: propertyInfo.assetClass || '',
        assetType: (propertyInfo.assetType && (["Terraced", "Semi-Detached", "Detached", "Bungalow", "Flat", "Land"].includes(propertyInfo.assetType) ? propertyInfo.assetType : 'Other')) || '', 
        otherAssetType: (propertyInfo.assetType && (["Terraced", "Semi-Detached", "Detached", "Bungalow", "Flat", "Land"].includes(propertyInfo.assetType) ? '' : propertyInfo.assetType)) || '',
        skewCode: propertyInfo.skewCode || ''
    }, propertyFormRules);

    const { formData: docsFormData, setFormData: docsSetFormData, errorMapping: docsErrorMapping } = useForm({
        assetImages: propertyInfo.assetImages || [],
        assetDocuments: propertyInfo.assetDocuments || []
    });

    const { formData: auctionFormData, setFormData: auctionSetFormData, handleChange: auctionHandleChange, errorMapping: auctionErrorMapping, setErrorMapping: auctionSetErrorMapping, handleSubmit: auctionHandleSubmit } = useForm({
        reservePrice: propertyInfo.reservePrice && propertyInfo.reservePrice !== -1 ? propertyInfo.reservePrice.toString() : propertyInfo.reservePrice === -1 ? '0' : '',
        bidStartPrice: propertyInfo.bidStartPrice ? propertyInfo.bidStartPrice.toString() : '',
        guidePrice: propertyInfo.guidePrice && propertyInfo.guidePrice !== -1 ? propertyInfo.guidePrice.toString() : '',
        bidEndTime: propertyInfo.bidEndTime ? parseISO(propertyInfo.bidEndTime) : (() => {
            let date = new Date();

            date.setHours(6, 0, 0);
            return date;
        })(),
        publishDate: propertyInfo.publishDate ? parseISO(propertyInfo.publishDate) : (() => {
            let date = new Date();

            date.setHours(6, 0, 0);
            return date;
        })(),
        productStatus: (propertyInfo.productStatus && (propertyInfo.productStatus === "ScheduledPreMarket" ? 'Scheduled' : propertyInfo.productStatus)) || 'Draft',
        checkPreMarket: (propertyInfo.productStatus && (propertyInfo.productStatus === "ScheduledPreMarket" || propertyInfo.productStatus === "PreMarket")) || false
    }, auctionFormRules);

    const handleAddProperty = async () => {
        try {
            let assetImages = [];
            let assetDocuments = [];
    
            setLoading(true);
            setAddError("");
            if (docsFormData.assetImages.some((img) => img.data.startsWith('data'))) {
                setLoadingText('Uploading images...');
            }
    
            /* If edit mode, filter out images that are reuploads. However, if order changes, that should be reflected. */
    
            let unuploadedImages = docsFormData.assetImages.filter((img) => img.data.startsWith('data'));
            let uploadedImages = [];
    
            for (let idx = 0; idx < unuploadedImages.length; idx++) {
                let { data: fileContents, type: fileType, name } = unuploadedImages[idx];
                try {
                    const res = await axios.post('/api/agent/upload', {
                        fileContents,
                        fileType
                    });
    
                    if (!res.data.status) {
                        throw `Error uploading image ${name}.`;
                    }
                    else {
                        uploadedImages.push(res.data.data);
                    }
                }
                catch (e) {
                    if (typeof e === 'string') {
                        throw e;
                    }
                    else {
                        throw 'Error uploading images.';
                    }
                }

            }
    
            let imgIdx = 0;
    
            assetImages = docsFormData.assetImages.map((img) => {
                return {
                    title: img.name,
                    path: img.data.startsWith('data') ? uploadedImages[imgIdx++].path : img.data
                }
            });
    
            if (docsFormData.assetDocuments.some((doc) => doc.data.startsWith('data'))) {
                setLoadingText('Uploading documents...');
            }
    
            /* If edit mode, filter out images that are reuploads. However, if order changes, that should be reflected. */
    
            let unuploadedDocs = docsFormData.assetDocuments.filter((doc) => doc.data.startsWith('data'));
            let uploadedDocs = [];
    
            for (let idx = 0; idx < unuploadedDocs.length; idx++) {
                let { data: fileContents, type: fileType, name } = unuploadedDocs[idx];
                    try {
                        const res = await axios.post('/api/agent/upload', {
                            fileContents,
                            fileType
                        });
        
                        if (!res.data.status) {
                            throw `Error uploading document ${name}.`;
                        }
                        else {
                            uploadedDocs.push(res.data.data);
                        }
                    }
                    catch (e) {
                        if (typeof e === 'string') {
                            throw e;
                        }
                        else {
                            throw 'Error uploading documents.';
                        }
                    }

            }
    
            let docIdx = 0;
            
            assetDocuments = docsFormData.assetDocuments.map((doc) => {
                return {
                    title: doc.name,
                    path: doc.data.startsWith('data') ? uploadedDocs[docIdx++].path : doc.data
                }
            });
    
            // let features = propertyFormData.features.reduce((acc, feature, idx) => acc + 
            //             (idx !== propertyFormData.features.length - 1 ? `${feature.value},` : feature.value), "");
    
            let { otherAssetType, lat, lng, ...propertyFormDataNoOther } = propertyFormData;
            let { checkPreMarket, ...auctionFormDataNoCheck } = auctionFormData;
    
            setLoadingText(mode === 'add' ? 'Adding property...' : 'Updating property...');

            try {
                const finalRes = await axios.post(`/api/agency/product/${mode === 'add' ? 'new' : 'update'}`, {
                    ...propertyFormDataNoOther,
                    assetType: propertyFormData.assetType === "Other" ? otherAssetType : propertyFormData.assetType,
                    bedRooms: propertyFormData.assetType === "Land" || propertyFormData.assetType === "Other" ? 0 : parseInt(propertyFormData.bedRooms),
                    bathRooms: propertyFormData.assetType === "Land" || propertyFormData.assetType === "Other" ? 0 : parseInt(propertyFormData.bathRooms),
                    geoLocation: [{lat, lng}],
                    // features,
                    ...auctionFormDataNoCheck,
                    reservePrice: auctionFormData.reservePrice === "0" ? "-1" : auctionFormData.reservePrice,
                    bidEndTime: auctionFormData.productStatus === "Scheduled" || auctionFormData.productStatus === "Live" ? auctionFormData.bidEndTime.toISOString() : "",
                    publishDate: auctionFormData.productStatus === "Scheduled" ? auctionFormData.publishDate.toISOString() : 
                        (auctionFormData.productStatus === "Live" ? new Date().toISOString() : ""),
                    productStatus: auctionFormData.productStatus === "Scheduled" && auctionFormData.checkPreMarket ? "ScheduledPreMarket" : auctionFormData.productStatus,
                    assetImages,
                    assetDocuments,
                    ...(mode === 'edit' && { productId: propertyInfo.productId }),
                    guidePrice: auctionFormData.guidePrice ? auctionFormData.guidePrice.toString() : "-1"
                });

                if (!finalRes.data.status) {
                    throw `Error ${mode === 'add' ? 'adding' : 'updating'} property: ${finalRes.data?.message}`;
                }
                else {
                    addNotification(mode === 'add' ? 'New property added!' : 'Property updated!');
                    history.push('/agent/dashboard/manage');
                }
            }
            catch (e) {
                if (typeof e === 'string') {
                    throw e;
                }
                else {
                    throw  `Error ${mode === 'add' ? 'adding' : 'updating'} property.`;
                }
            }  
        }
        catch (e) {
            setLoading(false);
            setLoadingText(null);
            setAddError(`${e} Please try again or contact us at email@email.com for help resolving the issue.`);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check automatic validations before manual validations
        let propertyResult = propertyHandleSubmit();
        // No check for docsFormData b/c no validation needed. Just individual sections.
        let imgResult = !(Object.keys(imgFileUpload.imgErrs).length);
        console.log(imgResult);
        let docResult = !(Object.keys(docFileUpload.imgErrs).length);
        let auctionResult = auctionHandleSubmit();

        setSectionErrors({
            property: !propertyResult,
            imgs: !imgResult,
            docs: !docResult,
            auction: !auctionResult
        });

        if (propertyResult && imgResult && docResult && auctionResult) {
            setHasValidationErrors(false);
            // Show warning modal if no images/documents, otherwise just add property.
            if (auctionFormData.productStatus !== 'Draft' && (!docsFormData.assetImages.length || !docsFormData.assetDocuments.length)) {
                setWarningModalOpen(true);
            }
            else {
                handleAddProperty();
            }
        }
        else {
            setHasValidationErrors(true);
        }

    }

    return (
        <div className="flex flex-col items-center mt-8">
            <h1 className="font-primary font-bold text-3xl sm:text-5xl">{mode === 'add' ? 'Add a Property' : 'Edit Property'}</h1>
            <form className="w-full" onSubmit={handleSubmit}>
                {/* <AgentFormBranch 
                formData={branchFormData}
                errorMapping={branchErrorMapping}
                handleChange={branchHandleChange} 
                sectionError={sectionErrors.branch} /> */}
                <AgentFormGeneralProperty 
                mode={mode}
                formData={propertyFormData}
                setFormData={propertySetFormData}
                errorMapping={propertyErrorMapping}
                handleChange={propertyHandleChange}
                sectionError={sectionErrors.property} />
                <AgentFormImagesDocs 
                formData={docsFormData}
                setFormData={docsSetFormData}
                errorMapping={docsErrorMapping}
                imgFileUpload={imgFileUpload}
                docFileUpload={docFileUpload}
                imgSectionError={sectionErrors.imgs}
                docSectionError={sectionErrors.docs} />
                <AgentFormAuction 
                formData={auctionFormData}
                setFormData={auctionSetFormData}
                errorMapping={auctionErrorMapping}
                setErrorMapping={auctionSetErrorMapping}
                handleChange={auctionHandleChange} 
                sectionError={sectionErrors.auction}
                mode={mode}
                status={propertyInfo.productStatus} />
            {
                addError &&
                (
                    <div className="p-5 mt-8 border-2 border-air-red bg-white rounded-lg shadow-lg">
                        <h2 className="font-primary font-semibold text-xl text-center mb-3">There was an error in creating this product:</h2>
                        <p className="font-secondary font-black text-lg text-primary text-center mb-3">{addError}</p>
                    </div>
                )
            }
            {
                loading &&
                (
                    <div className="flex flex-col items-center mt-10">
                        <CircularProgress size="3rem" color="secondary" />
                        {
                            loadingText && <p className="font-secondary font-bold text-3xl text-center mt-3">{loadingText}</p>
                        }
                    </div>
                )
            }
            {
                hasValidationErrors && (
                    <section className="mt-5 flex justify-center">
                        <div className="p-8 rounded-lg shadow-xl space-y-3">
                            <h3 className="font-primary font-semibold text-2xl">Your form has validation errors in these sections:</h3>
                            <ul className="list-disc ml-5">
                            {
                                Object.keys(sectionErrors).map((sectionName) => {
                                    if (sectionErrors[sectionName]) {
                                        return (
                                            <li className="font-secondary text-lg text-air-red">
                                                {sectionName[0].toUpperCase() + sectionName.slice(1)}
                                            </li>
                                        )
                                    }

                                    return null;
                                })
                            }
                            </ul>
                            <p className="font-primary font-semibold text-xl text-primary">Please fix these errors to complete {mode === 'add' ? 'adding ' : 'updating '}
                             your property!</p>
                        </div>
                    </section>

                )
            }
                <InputButton 
                type="submit"
                submitText={mode === 'add' ? 'Add Property' : 'Update Property'}
                imageTitle={mode === 'add' ? 'add' : 'pencil'}
                imagePosition="left"
                disabled={loading}
                rootClass="my-10 mx-auto"
                textClass="text-2xl" />
            </form>
            <AgentDashboardWarningModal 
            noImages={!docsFormData.assetImages.length}
            noDocs={!docsFormData.assetDocuments.length}
            open={warningModalOpen}
            mode={mode}
            handleClose={() => setWarningModalOpen(false)} 
            handleAddProperty={handleAddProperty} />
        </div>
    )
}