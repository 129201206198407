import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import BuildIcon from '@material-ui/icons/Build';

export default function AgentDashboardNavbarNew() {
    const location = useLocation();
    const navItems = {
        home: (
            <>
                <HomeIcon />
                <h2>Home</h2>
            </>
        ),
        manage: (
            <>
                <BuildIcon />
                <h2>Manage Properties</h2>
            </>
        )
    };

    return (
        <nav className="fixed left-0 z-10 bottom-0 w-full sm:w-auto sm:relative">
            <ul className="flex items-center sm:space-x-5">
            {
                Object.keys(navItems).map((path) => (
                    <li className="w-full sm:w-auto">
                        <Link 
                        to={`/agent/dashboard/${path}`} 
                        className={`flex flex-col sm:flex-row items-center sm:space-x-3 py-2 px-4 transition-colors duration-200 ease-in-out font-semibold text-xs sm-480:text-base 
                        ${location.pathname === `/agent/dashboard/${path}` ? 
                            'bg-primary-agency text-white border-2 border-primary-agency': 
                            'bg-white text-primary-agency border-2 border-primary-agency hover:bg-primary-agency hover:text-white'}`
                        }>
                            {navItems[path]}
                        </Link>
                    </li>
                ))
            }
            </ul>
        </nav>
    )
}