import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';

import useForm from '@hooks/useForm';

import InputButton from '@components/forms/InputButton';

function FilterSection({ filtered, filterValues, handleFilter, handleClose, handleClear, children }) {
    const { formData, errorMapping, handleChange, handleSubmit } = useForm(filterValues);

    return (
        <div className="fixed top-0 w-full right-0 sm:w-auto sm:right-0 sm:mr-16 mt-16 sm:mt-40 md:mt-64 sm:rounded-lg bg-white sm:shadow-lg z-40">
            <div className="sm:rounded-lg sm:rounded-b-none p-4 sm:p-6 sm:bg-primary-agency flex justify-between items-center text-black sm:text-white">
                <h2 className="inline-block sm:pb-0 border-b-4 sm:border-none border-primary-agency font-primary font-semibold text-3xl">Filters</h2>
                <button 
                className="flex items-center p-2 rounded-full hover:bg-table-light-blue sm:hover:bg-black sm:hover:bg-opacity-25 transition-colors duration-200 ease-in-out cursor-pointer"
                onClick={handleClose}>
                    <ClearIcon />
                </button>
            </div>
            <form className="flex flex-col p-4 space-y-6" onSubmit={(e) => handleSubmit(e, () => handleFilter(formData))}>
            {
                children({ formData, errorMapping, handleChange })
            }
                <div className="flex space-x-5 mx-auto">
                    <InputButton 
                    type="button"
                    submitText="Clear"
                    imageTitle="clear"
                    imagePosition="left" 
                    onClick={handleClear}
                    disabled={!filtered} />
                    <InputButton 
                    submitText="Apply Filters"
                    imageTitle="filter"
                    imagePosition="left" 
                    disabled={!Object.keys(filterValues).some((filter) => formData[filter] !== filterValues[filter])} />
                </div>
            </form>
        </div>
    )
}

export default React.memo(FilterSection);