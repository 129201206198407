import React, { useContext, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';

import { BidderRegistrationContextProvider } from '@contexts/BidderRegistrationContext';
import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

import BidderDashboardHome from './components/BidderDashboardHomeNew';
import BidderDashboardWatchedProperties from './components/BidderDashboardWatchedProperties';
import BidderDashboardBidsPage from './components/BidderDashboardBidsPage';
import BidderDashboardProfile from './components/BidderDashboardProfile';
import BidderDashboardSettings from './components/BidderDashboardSettings';
import BidderDashboardNavbarNew from './components/BidderDashboardNavbarNew';
import BidderDashboardCompleteRegistration from './components/BidderDashboardCompleteRegistration';
import Navbar from '@components/navigation/Navbar';
import ProtectedRoute from '@components/routing/ProtectedRoute';
import Footer from '@components/Footer';
import useServerTime from '@hooks/useServerTime';

const BidderDashboardCardSetup = React.lazy(() => import('./components/BidderDashboardCardSetup'));

export default function BidderDashboard() {
    const { userData } = useContext(AuthContext);
    const {
        isRegistrationComplete,
        isFullyRegistered,
        myWatchs,
        myBids,
        bidsMaxPrice,
        bidderAlias
    } = userData ?? {};

    const { stripePromise } = useContext(AppContext);

    useServerTime()

    return (
        <div className="flex flex-col">
            <main className="flex flex-col py-6 max-w-lg-container sm:w-9/10 sm:mx-auto min-h-screen">
                <BidderDashboardNavbarNew />
                <div className="p-3 py-8 sm:p-8">
                    <div className="relative flex-grow flex flex-col">
                        <Switch>
                            <Route path="/bidder/dashboard" exact render={(props) => <Redirect {...props} to="/bidder/dashboard/home" />} />
                            <Route path="/bidder/dashboard/home" component={BidderDashboardHome} />
                            <Route path="/bidder/dashboard/watched" render={(props) =>
                                <BidderDashboardWatchedProperties
                                    {...props}
                                    userData={{ myWatchs }} />} />
                            <Route path="/bidder/dashboard/bids" render={(props) =>
                                <BidderDashboardBidsPage
                                    {...props}
                                    userData={{ myBids, bidsMaxPrice, bidderAlias }} />} />
                            <Route path="/bidder/dashboard/profile" component={BidderDashboardProfile} />
                            <Route path="/bidder/dashboard/settings" component={BidderDashboardSettings} />
                            <ProtectedRoute
                                exact
                                path="/bidder/dashboard/register"
                                condition={!isRegistrationComplete}
                                redirectPath="/notfound">
                                <BidderRegistrationContextProvider>
                                    <BidderDashboardCompleteRegistration />
                                </BidderRegistrationContextProvider>
                            </ProtectedRoute>
                            <ProtectedRoute
                                path="/bidder/dashboard/card-setup"
                                condition={isFullyRegistered}
                                redirectPath="/notfound">
                                <Suspense fallback={<Navbar />}>
                                    <Elements stripe={stripePromise}>
                                        <BidderDashboardCardSetup />
                                    </Elements>
                                </Suspense>
                            </ProtectedRoute>
                            <Route path="/bidder/dashboard">
                                <Redirect to="/notfound" />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}