import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom'; 

import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

import BuyingPage from '@pages/buying/buying';
import SellingPage from '@pages/selling';
import ContactPage from '@pages/contact';
import FAQPage from '@pages/faq';
import NotFoundPage from '@pages/not-found/NotFoundPage';
import SearchResultsPage from '@pages/search-results/SearchResultsPage';
import PropertyPage from '@pages/property/PropertyPage';
import HomePage from '@pages/home/HomePage';
import VerifyEmailApiPage from '@pages/verify-email-api/VerifyEmailApiPage';
import BidderSite from '@pages/bidder/BidderSite';
import ErrorPage from '@pages/error/ErrorPage';

import Notification from '../Notification';
import ProtectedRoute from './ProtectedRoute'; 
import Navbar from '../navigation/Navbar';

import AgentSite from '@pages/agent/AgentSite';
import * as ga from '@util/ga'

export default function MainRouter() {
    const { pathname, hash, search } = useLocation();
    const { agencyData } = useContext(AppContext);
    const { userType, isUserChecked } = useContext(AuthContext);
    /*
        Effect:

        Scroll to top on URL change!
    */
    useLayoutEffect(() => {
        if (!hash) {
            window.scrollTo(0, 0);
        }
    }, [pathname, hash]);

    useEffect(() => {
        ga.pageview(pathname, search)
    }, [pathname, search])

    return (
        <>
            {
                isUserChecked && agencyData && (
                    <>
                    <Notification />
                    <Navbar />
                    <Switch>
                        <Route path="/" exact component={HomePage} />
                        <Route path="/buying" exact component={BuyingPage} />
                        <Route path="/selling" exact component={SellingPage} />
                        <Route path="/contact" exact component={ContactPage} />
                        <Route path="/faq" exact component={FAQPage} />
                        <ProtectedRoute 
                        path="/bidder"
                        component={BidderSite}
                        condition={userType !== 'agent'}
                        redirectPath="/notfound" />
                        <Route path="/search" exact component={SearchResultsPage} />
                        <Route path="/property/:productId" exact component={PropertyPage} />
                        <ProtectedRoute 
                        path="/agent"
                        component={AgentSite} 
                        condition={userType !== 'bidder'}
                        redirectPath="/notfound" />
                        <Route path="/verifyEmail" exact component={VerifyEmailApiPage} />
                        <Route path="/notfound" exact component={NotFoundPage} />
                        <Route path="/error" exact component={ErrorPage} />
                        <Route path="/" component={NotFoundPage} />
                    </Switch>
                    </>

                    )
                }
        </>
    )
}
