import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ClearIcon from '@material-ui/icons/Clear';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import FileUpload from '@components/forms/FileUpload';

export default function AgentFormsDocUpload({ formData, setFormData, errorMapping, handleSubmit, imgData, imgErrs, getFileData, removeImg }) {
    console.log(formData);
    


    const onDragEnd = (result) => {
        console.log(result);
        const { source, destination } = result;

        if (!destination || source.index === destination.index) {
            return ;
        }

        setFormData((formData) => { 
            let newDocs = [...formData.assetDocuments];
            let movedDoc = newDocs[source.index];

            newDocs.splice(source.index, 1);
            newDocs.splice(destination.index, 0, movedDoc);
            return {...formData, assetDocuments: newDocs}; 
        });
    }

    const handleNewFile = (e) => {
        if (e.target.files.length) {
            let name = e.target.files[0].name;
            let nameSplit = name.split(".");
            let type = nameSplit[nameSplit.length - 1];
    
            if (!formData.assetDocuments.some((doc) => doc.name === name)) {
                getFileData(name, e.target.files[0], (result) => {
                    setFormData((formData) => {
                        return {
                            ...formData,
                            assetDocuments: [...formData.assetDocuments, {
                                name,
                                data: result,
                                type
                            }]
                        };
                    })
                });
            }
        }
    }

    const handleDeleteFile = (e, idx) => {
        e.preventDefault();

        setFormData((formData) => {
            let newDocs = [...formData.assetDocuments];

            newDocs.splice(idx, 1);
            return {...formData, assetDocuments: newDocs};
        })

        if (!formData.assetDocuments[idx].data || formData.assetDocuments[idx].data.startsWith('data')) {
            removeImg(formData.assetDocuments[idx].name);
        }
    }

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="doc-list">
                {
                    (provided, snapshot) => {
                        return (
                            <ul
                            style={{maxHeight: "30rem"}}
                            className={`rounded-lg list-none m-0 p-3 pb-0 overflow-y-auto transition-colors duration-300 ease-in-out ${snapshot.isDraggingOver ? 'bg-table-light-blue' : ''}`}
                            ref={provided.innerRef}
                            {...provided.droppableProps}>
                            {
                                formData.assetDocuments.map((doc, idx) => {
                                    return (
                                        <Draggable 
                                        key={doc.name}
                                        draggableId={doc.name}
                                        index={idx}>
                                        {
                                            (provided) =>
                                            <li
                                            className="mb-3"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}>
                                                <div className={`flex items-center space-x-5 p-5 border-2 border-dark-gray ${imgErrs[doc.name] && 'border-air-red'} bg-white rounded-lg w-full`}>
                                                    <div {...provided.dragHandleProps}>
                                                        <DragIndicatorIcon  />
                                                    </div>
                                                    <span className="font-primary font-semibold w-full">{doc.name}</span>
                                                    <div className="flex justify-end">
                                                        <ClearIcon className="cursor-pointer" onClick={(e) => handleDeleteFile(e, idx)}/>
                                                    </div>
                                                </div>

                                                {imgErrs[doc.name] && <span className="form-error-text mt-2 leading-tight">{imgErrs[doc.name]}</span>}

                                            </li>
                                        }

                                        </Draggable>
                                    )
                                })
                            }
                            {provided.placeholder}
                            </ul>
                        )
                    }
                }
                </Droppable>
            </DragDropContext>
            <div className="flex justify-center mt-5">
                <FileUpload 
                inputId="property-doc-upload"
                accept=".pdf"
                handleChange={handleNewFile} 
                error={errorMapping.assetDocuments}
                submitText="Add a Document"
                imageTitle="add-doc"
                imagePosition="left" />
            </div>
        </div>
    )
}