import React, { useContext } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import AuthContext from '@contexts/AuthContext';

import ProtectedRoute from '@components/routing/ProtectedRoute';
import BidderDashboard from './dashboard/BidderDashboard';
import BidderRegister from './BidderRegister';
import BidderLogin from './BidderLogin';
import BidderVerifyEmail from './BidderVerifyEmail';
import BidderEmailNotVerified from './BidderEmailNotVerified';
import BidderForgotPassword from './BidderForgotPassword';
import BidderNewPassword from './BidderNewPassword';


export default function BidderSite() {
    const location = useLocation();
    const { userType, isUserChecked } = useContext(AuthContext);

    return (
        <Switch>
            <Route path="/bidder/register">
            {
                userType === null
                ? <BidderRegister/>
                : <Redirect to="/" />
            }
            </Route>
            <Route path="/bidder/login">
            {
                userType === null
                ? <BidderLogin />
                : <Redirect to ="/" />
            }
            </Route>
            <Route path="/bidder/forgot-password" component={BidderForgotPassword} />
            <Route path="/bidder/newPassword" component={BidderNewPassword} />
            <ProtectedRoute 
            path="/bidder/verify-email" 
            component={BidderVerifyEmail}
            condition={location.state?.email}
            redirectPath="/notfound" />
            <ProtectedRoute 
            path="/bidder/not-verified" 
            component={BidderEmailNotVerified}
            condition={location.state?.email}
            redirectPath="/notfound" />
            {
                isUserChecked ? (
                    <ProtectedRoute 
                    path="/bidder/dashboard" 
                    component={BidderDashboard}
                    condition={userType}
                    redirectPath="/bidder/login" />
                ) : (
                    <div className="fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center">
                        <CircularProgress size="5rem" color="secondary" />
                        <h1 className="font-primary font-bold text-3xl text-center mt-3">Checking bidder info..</h1>
                    </div>
                )
            }

        </Switch>
    )
}