import React, { useContext, useEffect, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import { bidder } from '@api/api';

import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

import useForm from '@hooks/useForm';

import CardInput from './CardInput';
import FormInput from '../forms/FormInput';
import InputButton from '../forms/InputButton';

export default function CardSetupForm({ formClass, handleSuccess }) {
    const stripe = useStripe();
    const elements = useElements();

    const { setUserData } = useContext(AuthContext);
    const { addNotification } = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState(null);
    const [cardError, setCardError] = useState("");
    const [cardComplete, setCardComplete] = useState(false);

    const cardFormRules = {
        terms: [{
            test: (value) => value,
            errorMsg: 'You must agree to these terms to continue'
        }]
    };

    const { formData, errorMapping, handleChange, handleSubmit } = useForm({
        terms: false
    }, cardFormRules);

    const handleCardChange = (e) => {
        if (e.error) {
            setCardError(e.error.message);
        }
        else if (cardError) {
            setCardError("");
        }

        if (e.complete !== cardComplete) {
            setCardComplete(e.complete);
        }
    }

    const handleSubmitStripe = async () => {
        if (!stripe || !elements || !cardComplete || !client) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        setLoading(true);    

        const result = await stripe.confirmCardSetup(client, {
            payment_method: {
                card: elements.getElement(CardElement),
            }
        });

        if (result.error) {
            console.log(result.error);
            setCardError(result.error.message);
            setLoading(false);
        // Display result.error.message in your UI.
        } 
        else {
            console.log(result.setupIntent);
            bidder.post({
                endpoint: 'SetCardStatus',
                handleSuccess: (_) => {
                    addNotification('Card successfully added. You can now bid on properties!');
                    setUserData(prevBidderData => {
                        return {...prevBidderData, isCardComplete: true};
                    });

                    if (handleSuccess) {
                        handleSuccess();
                    }
                },
                handleComplete: () => setLoading(false)
            })


        // The setup has succeeded. Display a success message and send
        // result.setupIntent.payment_method to your server to save the
        // card to a Customer
        }
    };

    useEffect(() => {
        if (stripe) {
            bidder.post({
                endpoint: 'stripeSecret',
                handleSuccess: (data) => {
                    setClient(data.client_secret);
                    stripe.retrieveSetupIntent(data.client_secret)
                    .then((res) => {
                        console.log(res);
                        if (res.setupIntent.status === "succeeded") {
                            setUserData(prevBidderData => {
                                return {...prevBidderData, isCardComplete: true};
                            });
                        }
                    })
                },
                handleFail: (err) => console.log(err)
            });
        }
    }, [stripe]);

    return (
        <form className={formClass} onSubmit={(e) => handleSubmit(e, handleSubmitStripe)}>
                <CardInput 
                error={cardError}
                handleCardChange={handleCardChange} 
                disabled={!stripe || !elements || loading} />
                <FormInput 
                type="checkbox"
                label="I authorise Jetspace to make payments onto my card on my behalf if I were to win a bid."
                inputName="terms"
                value={formData.terms}
                error={errorMapping.terms}
                handleChange={handleChange} 
                rootClass="mt-8" 
                labelClass="text-base" 
                errorClass="text-center" />
                <div className="relative flex justify-center mt-10">
                    <InputButton 
                    type="submit"
                    submitText="Submit card"
                    disabled={!stripe || !elements || !cardComplete || !client}
                    loading={loading}
                    rounded="lg"
                    />
                </div>
            </form>
    )
}