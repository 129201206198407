import React from 'react';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CircularProgress from '@material-ui/core/CircularProgress';
import SortIcon from '@material-ui/icons/Sort';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useMemo } from 'react';

const getImage = (imageTitle) => {
    switch (imageTitle) {
        case "search-white":
            return require("../../static/search-white.svg");
        case "left":
            return <KeyboardArrowLeftIcon className="mr-1" />
        case "right":
            return <KeyboardArrowRightIcon className="ml-1" />
        case "search":
            return <SearchIcon className="mr-1" />
        case "eye":
            return <VisibilityOutlinedIcon className="mr-2 ml-2" fontSize="inherit" />
        case "eyeFilled":
            return <VisibilityIcon className="mr-2 ml-2" />
        case "pencil":
            return <CreateOutlinedIcon className="mr-2" />
        case "trash":
            return <DeleteIcon className="mr-2" />
        case "add":
            return <AddCircleOutlineOutlinedIcon className="mr-2" />
        case "add-photo":
            return <AddPhotoAlternateIcon className="mr-2" />
        case "add-doc":
            return <PostAddIcon className="mr-2" />
        case "remove":
            return <RemoveCircleOutlineIcon className="mr-2" />
        case "loading":
            return <CircularProgress className="mr-2" size="1.5rem" />
        case "loading-secondary":
            return <CircularProgress className="mr-2" size="1.5rem" color="secondary" />
        case "filter":
            return <FilterListIcon className="mr-2" />
        case "sort":
            return <SortIcon className="mr-2" />
        case "clear":
            return <ClearIcon className="mr-2" />
        case "check":
            return <CheckIcon className="mr-2" />
        default:
            return null;
    }
}


export default function InputButton({imageTitle, imagePosition, submitText, disabled, disableHelper, inputStyle, inline, rounded, rootClass, textClass, loading, onClick, nonClickable, ...rest}) {
    const colorStyles = useMemo(() => {
        switch (inputStyle) {
            case 'inverse':
                return 'text-secondary-agency hover:text-white bg-white hover:bg-secondary-agency';
            case 'inverse-primary':
                return 'text-primary-agency hover:text-white bg-white hover:bg-primary-agency';
            case 'primary':
                return 'text-white bg-primary-agency';
            case 'light':
                return 'text-black bg-background';
            default:
                return 'text-white bg-secondary-agency';
        }
    }, [inputStyle]);

    const borderStyles = useMemo(() => {
        switch (inputStyle) {
            case 'light':
                return 'border-mid-gray';
            case 'primary':
                return 'border-primary-agency';
            case 'inverse-primary':
                return 'border-primary-agency';
            default:
                return 'border-secondary-agency';
        }
    }, [inputStyle]);

    return (
        <button
        className={`relative transition-colors duration-200 ease-in-out items-center text-sm sm:text-base justify-center
        ${inline ? 'inline-flex' : 'flex'} 
        ${imagePosition === "right" && "flex-row-reverse"}  
        ${!disabled && colorStyles}
        ${rounded ? `rounded-${rounded}` : 'rounded-full'}
        py-2 px-6 ${imageTitle ? (imagePosition === "left" ? "pl-3" : (imagePosition === "right" && "pr-3")) : ''} 
        ${disabled && `text-gray-700 cursor-default ${disableHelper ? '' : 'pointer-events-none'} bg-background`}
        ${(loading || nonClickable) && 'pointer-events-none'}
        ${rootClass}`}
        tabIndex={(disabled || nonClickable) && '-1'}
        onClick={(e) => !disabled && !loading && !nonClickable && onClick && onClick(e)} // Make sure to disable on clicks when disabled! If this condition isn't here, a user can set pointer-events to all to use it.
        {...rest}>
            {
                (inputStyle !== undefined || disabled) && <div className={`absolute z-0 border-2 ${disabled ? 'border-dark-gray' : borderStyles} w-full h-full left-0 ${rounded ? `rounded-${rounded}` : 'rounded-full'} pointer-events-none`}></div>
            }
            
            {disabled && <div className={`absolute top-0 left-0 w-full z-0 h-full bg-white opacity-25 ${rounded ? `rounded-${rounded}` : 'rounded-full'}`}></div>}
            {imageTitle && <div className="flex text-sm sm:text-base">{getImage(imageTitle)}</div>}
            {
                loading && (
                    <div className="absolute bg-secondary-agency w-full h-full rounded-full flex justify-center items-center">
                        <CircularProgress size="1.25rem" />
                    </div>
                )
            }
                    <span className={`font-secondary font-semibold ${loading && 'invisible'} ${textClass ?? ''}`}>{submitText}</span>
        </button>
    )
}