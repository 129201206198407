import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { useInView } from 'react-intersection-observer';
import Odometer from 'odometer';
import 'odometer/themes/odometer-theme-default.css';

import AppContext from '@contexts/AppContext';

import PropertyCard from '@components/properties/card/PropertyCard';
import HomepageSearch from './components/HomepageSearch';
import Footer from '@components/Footer';
import ValuationModal from '@components/ValuationModal';
import CallbackModal from '@components/CallbackModal';
import { getProductList } from '@services/product';
import usePropertySortFn from '@hooks/usePropertySortFn';

const MAX_PROPERTIES = 3;

export default function HomePage() {
    const { agencyData: { agencyId } } = useContext(AppContext);
    const [valuationOpen, setValuationOpen] = useState(false);
    const [callbackOpen, setCallbackOpen] = useState(false);
    const [latestProperties, setLatestProperties] = useState(null);

    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: true,
        rootMargin: '50px'
    });

    const sortFn = usePropertySortFn()

    /*
        Effect:
        Gets properties on mount.
    */
    useEffect(() => {
        async function getProperties() {
            const propertiesRes = await getProductList(agencyId, MAX_PROPERTIES)
            setLatestProperties(propertiesRes.sort(sortFn));
        }
        getProperties();
    }, []);

    useEffect(() => {
        if (inView) {
            let el = document.querySelector('.js-number');
            let od = new Odometer({ el, duration: 5000 });
            od.update(3000000);
        }
    }, [inView]);

    return (
        <>
            <ValuationModal open={valuationOpen} handleModalClose={() => setValuationOpen(false)} />
            <CallbackModal open={callbackOpen} handleModalClose={() => setCallbackOpen(false)} />
            <HomepageSearch />
            <section className="py-24 bg-gray-100">
                <div className="mx-4">
                    <div className="flex justify-center gap-32 gap-y-16 flex-wrap max-w-lg-container mx-auto">
                        <article className="flex flex-col items-center text-center font-semibold text-xl space-y-4">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="var(--color-primary-agency)"
                                viewBox="0 0 480 480">
                                <path d="M472,432h-24V280c-0.003-4.418-3.588-7.997-8.006-7.994c-2.607,0.002-5.05,1.274-6.546,3.41l-112,160
                                    c-2.532,3.621-1.649,8.609,1.972,11.14c1.343,0.939,2.941,1.443,4.58,1.444h104v24c0,4.418,3.582,8,8,8s8-3.582,8-8v-24h24
                                    c4.418,0,8-3.582,8-8S476.418,432,472,432z M432,432h-88.64L432,305.376V432z"/>
                                <path d="M328,464h-94.712l88.056-103.688c0.2-0.238,0.387-0.486,0.56-0.744c16.566-24.518,11.048-57.713-12.56-75.552
                                    c-28.705-20.625-68.695-14.074-89.319,14.631C212.204,309.532,207.998,322.597,208,336c0,4.418,3.582,8,8,8s8-3.582,8-8
                                    c-0.003-26.51,21.486-48.002,47.995-48.005c10.048-0.001,19.843,3.151,28.005,9.013c16.537,12.671,20.388,36.007,8.8,53.32
                                    l-98.896,116.496c-2.859,3.369-2.445,8.417,0.924,11.276c1.445,1.226,3.277,1.899,5.172,1.9h112c4.418,0,8-3.582,8-8
                                    S332.418,464,328,464z"/>
                                <path d="M216.176,424.152c0.167-4.415-3.278-8.129-7.693-8.296c-0.001,0-0.002,0-0.003,0
                                    C104.11,411.982,20.341,328.363,16.28,224H48c4.418,0,8-3.582,8-8s-3.582-8-8-8H16.28C20.283,103.821,103.82,20.287,208,16.288
                                    V40c0,4.418,3.582,8,8,8s8-3.582,8-8V16.288c102.754,3.974,185.686,85.34,191.616,188l-31.2-31.2
                                    c-3.178-3.07-8.242-2.982-11.312,0.196c-2.994,3.1-2.994,8.015,0,11.116l44.656,44.656c0.841,1.018,1.925,1.807,3.152,2.296
                                    c0.313,0.094,0.631,0.172,0.952,0.232c0.549,0.198,1.117,0.335,1.696,0.408c0.08,0,0.152,0,0.232,0c0.08,0,0.152,0,0.224,0
                                    c0.609-0.046,1.211-0.164,1.792-0.352c0.329-0.04,0.655-0.101,0.976-0.184c1.083-0.385,2.069-1.002,2.888-1.808l45.264-45.248
                                    c3.069-3.178,2.982-8.242-0.196-11.312c-3.1-2.994-8.015-2.994-11.116,0l-31.976,31.952
                                    C425.933,90.37,331.38,0.281,216.568,0.112C216.368,0.104,216.2,0,216,0s-0.368,0.104-0.568,0.112
                                    C96.582,0.275,0.275,96.582,0.112,215.432C0.112,215.632,0,215.8,0,216s0.104,0.368,0.112,0.568
                                    c0.199,115.917,91.939,210.97,207.776,215.28h0.296C212.483,431.847,216.013,428.448,216.176,424.152z"/>
                                <path d="M323.48,108.52c-3.124-3.123-8.188-3.123-11.312,0L226.2,194.48c-6.495-2.896-13.914-2.896-20.408,0l-40.704-40.704
                                    c-3.178-3.069-8.243-2.981-11.312,0.197c-2.994,3.1-2.994,8.015,0,11.115l40.624,40.624c-5.704,11.94-0.648,26.244,11.293,31.947
                                    c9.165,4.378,20.095,2.501,27.275-4.683c7.219-7.158,9.078-18.118,4.624-27.256l85.888-85.888
                                    C326.603,116.708,326.603,111.644,323.48,108.52z M221.658,221.654c-0.001,0.001-0.001,0.001-0.002,0.002
                                    c-3.164,3.025-8.148,3.025-11.312,0c-3.125-3.124-3.125-8.189-0.002-11.314c3.124-3.125,8.189-3.125,11.314-0.002
                                    C224.781,213.464,224.781,218.53,221.658,221.654z"/>
                            </svg>
                            <p>
                                24/7 <br />
                                bidding
                            </p>
                        </article>
                        <article className="flex flex-col items-center text-center font-semibold text-xl space-y-4">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="var(--color-primary-agency)"
                                viewBox="0 0 54 54">
                                <path d="M43,20.113V14.5C43,6.505,35.822,0,27,0S11,6.505,11,14.5v5.613c-3.401,0.586-6,3.55-6,7.117v19.542
                                    C5,50.757,8.243,54,12.229,54h29.542C45.757,54,49,50.757,49,46.771V27.229C49,23.663,46.401,20.699,43,20.113z M13,14.5
                                    C13,7.607,19.28,2,27,2s14,5.607,14,12.5V20H13V14.5z M47,46.771C47,49.654,44.654,52,41.771,52H12.229C9.346,52,7,49.654,7,46.771
                                    V27.229C7,24.346,9.346,22,12.229,22h29.542C44.654,22,47,24.346,47,27.229V46.771z"/>
                                <path d="M27,28c-2.206,0-4,1.794-4,4v6c0,2.206,1.794,4,4,4s4-1.794,4-4v-6C31,29.794,29.206,28,27,28z M29,38c0,1.103-0.897,2-2,2
                                    s-2-0.897-2-2v-6c0-1.103,0.897-2,2-2s2,0.897,2,2V38z"/>
                            </svg>
                            <p>
                                Secure <br />
                                Sale
                            </p>
                        </article>
                        <article className="flex flex-col items-center text-center font-semibold text-xl space-y-4">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="var(--color-primary-agency)"
                                viewBox="0 0 477.867 477.867">
                                <path d="M240,0C107.452,0,0,107.452,0,240s107.452,240,240,240c132.486-0.15,239.85-107.514,240-240C480,107.452,372.548,0,240,0z
                                M240,464C116.288,464,16,363.712,16,240S116.288,16,240,16c123.653,0.141,223.859,100.347,224,224
                                C464,363.712,363.712,464,240,464z"/>
                                <path d="M347.24,160.204c-3.178-3.07-8.243-2.982-11.312,0.196L205.824,290.512L143.6,228.288c-3.1-2.994-8.015-2.994-11.115,0
                               c-3.178,3.069-3.266,8.134-0.197,11.312l67.88,67.88c1.5,1.5,3.534,2.344,5.656,2.344c2.122,0,4.156-0.844,5.656-2.344
                               l135.76-135.768c0.067-0.064,0.132-0.13,0.196-0.196C350.506,168.338,350.418,163.273,347.24,160.204z"/>
                            </svg>
                            <p>
                                Transparent <br />
                                Process
                            </p>
                        </article>
                        <article className="flex flex-col items-center text-center font-semibold text-xl space-y-4">
                            <svg viewBox="0 0 480 480" xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="var(--color-primary-agency)">
                                <path d="m348 0c-43 .0664062-83.28125 21.039062-108 56.222656-24.71875-35.183594-65-56.1562498-108-56.222656-70.320312 0-132 65.425781-132 140 0 72.679688 41.039062 147.535156 118.6875 216.480469 35.976562 31.882812 75.441406 59.597656 117.640625 82.625 2.304687 1.1875 5.039063 1.1875 7.34375 0 42.183594-23.027344 81.636719-50.746094 117.601563-82.625 77.6875-68.945313 118.726562-143.800781 118.726562-216.480469 0-74.574219-61.679688-140-132-140zm-108 422.902344c-29.382812-16.214844-224-129.496094-224-282.902344 0-66.054688 54.199219-124 116-124 41.867188.074219 80.460938 22.660156 101.03125 59.128906 1.539062 2.351563 4.160156 3.765625 6.96875 3.765625s5.429688-1.414062 6.96875-3.765625c20.570312-36.46875 59.164062-59.054687 101.03125-59.128906 61.800781 0 116 57.945312 116 124 0 153.40625-194.617188 266.6875-224 282.902344zm0 0" />
                            </svg>
                            <p>
                                Personal <br />
                                Service
                            </p>
                        </article>
                    </div>
                </div>
            </section>
            <div className="mx-4">
                <section className="text-center my-16 max-w-[1150px] mx-auto">
                    <header className="space-y-6">
                        <p className="text-xl tracking-wide font-semibold">Welcome to our online auction platform</p>
                        <h2 className="tracking-wide text-3xl md:text-5xl font-bold">The <span className="text-primary-agency">new way</span> of buying property</h2>
                    </header>
                    <p className="mt-16 text-lg md:text-2xl">
                        From residential to commercial, properties of all types are now being sold all across the country through online auctions.
                    </p>
                    <p className="mt-8 text-lg md:text-2xl">
                        We offer the modern method of auction, which combines the best aspects of traditional auction and private treaty sales, making
                        it a more accessible auction method. This method takes place online, opening the market to greater levels of interest and activity.
                    </p>
                </section>
            </div>
            <section className="py-20 bg-gray-100">
                <div className="max-w-4xl mx-auto">
                    <div className="flex flex-col items-center mx-4 text-center">
                        <p className="text-5xl md:text-8xl text-primary-agency font-bold" ref={ref}>
                            <span className="js-number">0,000,000</span>+
                        </p>
                        <p className="mt-6 text-xl md:text-2xl text-primary-agency font-bold leading-6">
                            Properties Sold <br />
                            via online auctions
                        </p>
                        <p className="mt-10 font-semibold text-xl">
                            More people are choosing to buy and sell property through this method.
                        </p>
                        <button className="mt-12 py-3 px-16 bg-secondary-agency text-white text-2xl font-semibold rounded-full" onClick={() => setCallbackOpen(true)}>Sell my property</button>
                    </div>
                </div>
            </section>
            <section className="relative h-0 pb-[200%] xs:pb-[150%] lg:pb-[60%]">
                <div className="home-c-stat-section absolute inset-0 py-20 px-4 text-center">
                    <div className="absolute inset-0 bg-white bg-opacity-70"></div>
                    <div className="relative max-w-[1200px] mx-auto">
                        <header className="space-y-8">
                            <h2 className="tracking-wide font-bold text-3xl lg:text-5xl">It's as <span className="text-primary-agency">easy</span> as 321</h2>
                            <p className="text-lg lg:text-xl font-semibold tracking-wide">Whether you are buying or selling at auction, we can help you move forward.</p>
                        </header>
                        <div className="relative mt-12">
                            <div className="hidden lg:block absolute right-[-10px] top-[-20px] w-24 h-8 border-r-8 border-t-8 border-primary-agency" />
                            <div className="hidden lg:block absolute left-[5px] bottom-[-20px] w-24 h-8 border-b-8 border-l-8 border-primary-agency"></div>
                            <p className="text-2xl sm:text-3xl lg:text-5xl font-bold">9.5 out of 10 buyers and sellers have a successful moving experience using online auctions.</p>
                        </div>
                        <div className="flex flex-col lg:flex-row justify-center items-center mt-16 space-y-8 lg:space-y-0 lg:space-x-12">
                            <Link to="/buying" className="py-2 px-20 text-xl text-secondary-agency border-2 border-secondary-agency rounded-full font-semibold">Buying</Link>
                            <Link to="/selling" className="py-2 px-20 text-xl text-secondary-agency border-2 border-secondary-agency rounded-full font-semibold">Selling</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="my-24">
                <header className="text-center">
                    <h2 className="text-3xl md:text-5xl font-bold tracking-wide">Creating <span className="text-primary-agency">success</span> for you</h2>
                    <p className="mt-12 max-w-lg-container mx-auto text-lg md:text-xl text-gray-600 tracking-wide text-center">
                        From the second you choose online auctions to the moment your property is sold, <br /> we guide you every
                        step of the way to ensure a fast, easy and hassle-free moving experience.
                    </p>
                </header>
                <div className="max-w-[920px] mx-auto mt-20">
                    <div className="flex justify-center flex-wrap gap-5 my-8">
                        <article
                            data-aos="fade-up"
                            data-aos-duration="600"
                            data-aos-once="true"
                            className="min-w-[280px] h-[305px] flex flex-col items-center p-20 pt-16 border border-gray-300 rounded-md shadow-lg">
                            <div className="relative top-1/2 transform -translate-y-1/2 text-lg text-center space-y-4">
                                <p className="text-6xl font-bold text-primary-agency">56</p>
                                <p className="font-bold text-center">
                                    Day <br />
                                    Completion
                                </p>
                            </div>
                        </article>
                        <article
                            data-aos="fade-up"
                            data-aos-delay="600"
                            data-aos-duration="600"
                            data-aos-once="true"
                            className="min-w-[280px] h-[305px] flex flex-col items-center p-20 pt-16 border border-gray-300 rounded-md shadow-lg">
                            <div className="relative top-1/2 transform -translate-y-1/2 text-lg text-center space-y-4">
                                <p className="text-6xl font-bold text-primary-agency">95%</p>
                                <p className="font-bold text-center">
                                    Completion <br />
                                    Rate
                                </p>
                            </div>
                        </article>
                        <article
                            data-aos="fade-up"
                            data-aos-delay="1200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            className="min-w-[280px] h-[305px] flex flex-col items-center p-20 pt-16 border border-gray-300 rounded-md shadow-lg">
                            <div className="relative top-1/2 transform -translate-y-2/5 text-lg text-center space-y-4">
                                <p className="text-6xl font-bold text-primary-agency">4x</p>
                                <p className="font-bold text-center">
                                    Faster than <br />
                                    Private Treaty <br />
                                    Sales (average)
                                </p>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
            <div className="border-t border-b border-gray-300 px-4">
                <section className="flex flex-col lg:flex-row justify-between items-center max-w-lg-container mx-auto py-10 space-y-8 lg:space-y-0 lg:space-x-4">
                    <div className="text-center lg:text-left">
                        <header>
                            <h2 className="tracking-wide text-3xl lg:text-[2.75rem] font-bold">How much is my home worth?</h2>
                        </header>
                        <p className="mt-8 lg:mt-12 max-w-[650px] text-lg lg:text-xl text-gray-600 tracking-wide">If you're ready to sell at auction or just plain curious, the next step is to get an accurate valuation from one of our local property experts.</p>
                        <button className="mt-12 py-3 px-6 text-xl text-white bg-secondary-agency rounded-full" onClick={() => setValuationOpen(true)}>Get a free valuation</button>
                    </div>
                    <img className="lg:w-1/3 xl:w-2/5 rounded-lg" src="/images/home-worth.jpeg" alt="" />
                </section>
            </div>
            {latestProperties && latestProperties.length > 0 && (
                <div className="flex flex-col items-center pt-5 px-3 lg:px-12 pb-12 my-24">
                    <h1 className="mb-16 text-center text-3xl lg:text-5xl tracking-wide font-bold">Your local properties</h1>
                    <div className="property-grid grid max-w-lg-container w-full gap-4">
                        {latestProperties.map((property) =>
                            <PropertyCard
                                key={property.productId}
                                {...property}
                                publishDate={new Date(property.publishDate).getTime()}
                                bidEndTime={new Date(property.bidEndTime).getTime()} />)}
                    </div>
                </div>
            )}
            <Footer />
        </>
    )
}