import React from 'react';
import { Route, Redirect} from 'react-router-dom';

export default function ProtectedRoute({ condition, component: Component, componentProps, redirectPath, children, ...rest }) {
    return (
        <Route
        {...rest} 
        render={(props) => 
            condition
            ?
            (
                children ?? (
                    <Component 
                    {...props} 
                    {...componentProps} />
                )
            ) 
            : 
            (
                <Redirect to={{
                    pathname: redirectPath,
                    state: { from: props.location }
                }} />
            )
        }/>
    )
};