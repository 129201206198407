import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearIcon from '@material-ui/icons/Clear';

export default function ModalLayout({ open, icon, title, children, withHeader, handleClose, modalBodyClass }) {
    return (
        <Modal
        className="flex justify-center items-center"
        open={open}
        closeAfterTransition
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
            appear: true,
            enter: 500,
            exit: 750
        }}
        >
            <Fade in={open}>
                <div className={`relative outline-none rounded-lg shadow-lg bg-white ${modalBodyClass ?? ''}`}>
                    <button className={`absolute top-0 right-0 mt-5 mr-5 cursor-pointer rounded-lg ${withHeader ? 'hover:bg-dark-primary' : 'hover:bg-table-light-blue'} transition-colors duration-200 ease-in-out p-1 ${withHeader ? 'text-white' : ''}`}>
                        <ClearIcon color="inherit" onClick={(e) => handleClose()} />
                    </button>
                    {
                        withHeader && (
                            <header className="rounded-header text-2xl flex items-center"> 
                                {icon}
                                <span className="ml-5">{title}</span>
                            </header>
                        )
                    }
                    {children}
                </div>
            </Fade>
        </Modal>
    )
}