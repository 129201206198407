import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningIcon from '@material-ui/icons/Warning';

import { bidder } from '@api/api';

import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

import BidderRegistrationContext from '@contexts/BidderRegistrationContext';
import InputButton from '@components/forms/InputButton';

export default function BidderDashboardRequestApproval({ setActiveStep }) {
    const { userData: {
        newsletter
    }, setUserData} = useContext(AuthContext);
    const { addNotification, agencyData: { agencyId } } = useContext(AppContext);
    
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [requestText, setRequestText] = useState('');
    const [requestError, setRequestError] = useState(null);
    const [doneRequesting, setDoneRequesting] = useState(false);
    const { completeProfileData, completeIDData } = useContext(BidderRegistrationContext);

    const handleRequestApproval = (e) => {
        setLoading(true);
        setRequestText('Submitting profile data...');
        let idPath = "";
        let passportPath = "";

        bidder.post({
            endpoint: 'profileDetailsUpdate',
            data: {
                ...completeProfileData,
                newsletter
            },
            handleSuccess: (_) => {
                setRequestText('Uploading passport scan...');
                bidder.post({
                    endpoint: 'upload',
                    data: {
                        agencyId,
                        fileContents: completeIDData.imgData.passportScan.data,
                        fileType: completeIDData.imgData.passportScan.type,
                    },
                    handleSuccess: (res) => {
                        passportPath = res.path;
                        setRequestText('Uploading ID scan...');
                        bidder.post({
                            endpoint: 'upload',
                            data: {
                                agencyId,
                                fileContents: completeIDData.imgData.IDScan.data,
                                fileType: completeIDData.imgData.IDScan.type,
                            },
                            handleSuccess: (res) => {
                                idPath = res.path;
                                setRequestText('Submitting images...');
                                bidder.post({
                                    endpoint: 'profileAML',
                                    data: {
                                        passportScan: passportPath,
                                        IDScan: idPath,
                                        terms: completeIDData.terms
                                    },
                                    handleSuccess: (_) => {
                                        setRequestText('Requesting approval...');
                                        bidder.post({
                                            endpoint: 'profileApproval',
                                            data: {
                                            },
                                            handleSuccess: (_) => {
                                                setLoading(false);
                                                setRequestText(null);
                                                setUserData((oldUserData) => {
                                                    return {...oldUserData, bidderStatus: "Pending", isCardComplete: false}
                                                })
                                                addNotification('You have requested approval for registration');
                                                setDoneRequesting(true);
                                            }
                                        })
                                    },
                                    handleFail: (err) => setRequestError(err)
                                })
                            },
                            handleFail: (err) => setRequestError(err)
                        })
                    },
                    handleFail: (err) => setRequestError(err)
                })
            },
            handleFail: (err) => setRequestError(err)
        });
    };

    return (
        <div className="flex flex-col items-center">
            <h1 className="dashboard-header mb-1 text-4xl text-center">Request Approval</h1>
            <h2 className="max-w-container-dashboard mb-5 font-secondary font-bold text-primary-agency text-center">You may now request approval. An email will be sent to you when you get approved.
            This process takes 1-2 days (placeholder for now!). After approval, you will need to enter your card details prior to bidding. </h2> 
            {/* TODO Confirm how long the process should actually take (remove placeholder!) */}
            {
                loading && (
                    <div className="flex flex-col items-center mt-5">
                    {
                        requestError 
                        ?
                        (
                            <div className="text-6xl">
                                <WarningIcon className="text-air-red shadow-lg" fontSize="inherit" />
                            </div>
                        )
                        :
                        (
                            <CircularProgress size="3rem" color="inherit" className="text-primary-agency" />
                        )
                    }
                    <h3 className="font-secondary font-bold text-3xl text-center mt-4">{ requestText }</h3>
                    {
                        requestError && (
                            <p className="font-secondary font-bold text-2xl text-center mt-3 text-air-red">{ requestError }</p>
                        )
                    }
                    </div>
                )
            }

            <div className="flex justify-center mt-10">
            {
                doneRequesting ? (
                    <section className="flex flex-col justify-center max-w-md p-3 rounded-lg bg-table-light-blue text-primary font-bold">
                        <p className="text-center">Thank you for your submission. We will approve your account as soon as possible. Please check back later.</p>
                        <InputButton 
                        submitText="Back to Dashboard"
                        rounded="lg"
                        rootClass="mt-8"
                        onClick={(e) => history.push('/bidder/dashboard')} />
                    </section>
                ) : (
                    <>
                        <InputButton 
                        type="button"
                        submitText="Back (Upload ID)" 
                        inputStyle="inverse"
                        onClick={(e) => setActiveStep(1)}
                        disabled={loading} />
                        <InputButton 
                        type="button"
                        submitText="Request Approval"
                        onClick={handleRequestApproval} 
                        disabled={loading}
                        rootClass={`ml-5 ${loading ? '' : 'animate-pulse-button'}`} />
                    </>
                )
            }
            </div>

        </div>
    )
}